import { GET_TEXT_TRANSLATION_SUCCESS_VERBATIM, GET_TEXT_TRANSLATION_VERBATIM ,GET_TEXT_TRANSLATION_FAIL_VERBATIM, CLEAR_TRANSLATION_VERBATIM, GET_VERBATIM_DATA, GET_VERBATIM_DATA_SUCCESS, GET_VERBATIM_DATA_FAIL, COLLAPSED_SIDE_BAR} from "./type";

export const getTransationTextVerbatim = (groupText: any, id: any) => 
{

 return (
  {
    type: GET_TEXT_TRANSLATION_VERBATIM,
    groupText,
    id,
  })}
  
  export const getTransationTextVerbatimSuccess = (texttranslated: any, id: any,) => {
    const payload = { texttranslated, id};
    return {
      type: GET_TEXT_TRANSLATION_SUCCESS_VERBATIM,
      payload,
    };
  };
  
  export const getTransationTextVerbatimFail = (payload: any) => ({
    type: GET_TEXT_TRANSLATION_FAIL_VERBATIM,
    payload,
  });
  export const clearTranslationVerbatim=(id:string)=>{
    return  (
       { type:CLEAR_TRANSLATION_VERBATIM,
       id
     }
     )
   }
   export const getVerbatim = (
    filter: any,
    settings: any,
    id: number,
    endPoint: string,
    series?: any,
    source?: any,
    sectionIds?: any,
    questionWithAnswerIds?: any,
   deleteaction?:any,
   checked?:boolean,
   notifText?:any
  ) => {
    return {
      type: GET_VERBATIM_DATA,
      filter,
      settings,
      id,
      endPoint,
      series,
      source,
      sectionIds,
      questionWithAnswerIds,
      deleteaction,
      checked,
      notifText
    };
  };
  export const getVerbatimSuccess = (payload: any) => ({
    type: GET_VERBATIM_DATA_SUCCESS,
    payload,
  });
  export const getVerbatimFail = (payload: any) => ({
    type: GET_VERBATIM_DATA_FAIL,
    payload,
  });
  export const collapsedSideBar=(collapsed:boolean)=>{
  return{
    type:COLLAPSED_SIDE_BAR,
    collapsed
  }}