import { ActionTypes } from './constantes';

export const addGenericLoader = (action: string = '') => ({
  type: ActionTypes.ADD_GENERIC_LOADER,
  action,
});

export const removeGenericLoader = (action: string = '') => ({
  type: ActionTypes.REMOVE_GENERIC_LOADER,
  action,
});

export const addDownloadingLoader = (action: string = '',reportType:any='dd') => ({
  type: ActionTypes.ADD_DOWNLOADING_LOADER,
  action,
  reportType
});


export const removeDownloadingLoader = (action: string = '',reportType:any='dd') => ({
  type: ActionTypes.REMOVE_DOWNLOADING_LOADER,
  action,
  reportType
});
