/* LANGUAGE */

export const REQUEST_LABELS_TRANSLATIONS = 'REQUEST_LABELS_TRANSLATIONS';
export const GET_REPORTING_LANGUAGE = 'GET_REPORTING_LANGUAGE';
export const GET_REPORTING_LANGUAGE_SUCCESS = 'GET_REPORTING_LANGUAGE_SUCCESS';
export const GET_DYNAMIC_REPORTS = 'GET_DYNAMIC_REPORTS';
export const GET_DYNAMIC_REPORTS_SUCCESS = 'GET_DYNAMIC_REPORTS_SUCCESS';
export const REQUEST_USER_ROLES = 'REQUEST_USER_ROLES';
export const REQUEST_USER_ROLES_SUCCESS = 'REQUEST_USER_ROLES_SUCCESS';
export const REQUEST_APP_VERSION = 'REQUEST_APP_VERSION';
export const REQUEST_APP_VERSION_SUCCESS = 'REQUEST_APP_VERSION_SUCCESS';
export const REQUEST_CONTACT_EMAIL = 'REQUEST_CONTACT_EMAIL';
export const REQUEST_CONTACT_EMAIL_SUCCESS = 'REQUEST_CONTACT_EMAIL_SUCCESS';
export const POST_EMAIL = 'POST_EMAIL';
export const POST_EMAIL_SUCCESS = 'POST_EMAIL_SUCCESS';
export const INIT_NAVIGATION = 'INIT_NAVIGATION';
export const GET_ALL_LANGUAGE = 'GET_ALL_LANGUAGE';
export const GET_ALL_LANGUAGE_SUCCESS = 'GET_ALL_LANGUAGE_SUCCESS';
/*****toast message */
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const SHOW_MESSAGE_SUCCESS = 'SHOW_MESSAGE_SUCCESS';
export const CLOSE_MESSAGE = 'CLOSE_MESSAGE';
export const CLOSE_MESSAGE_SUCCESS = 'CLOSE_MESSAGE_SUCCESS';
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const START_STORE_LOADING = 'START_STORE_LOADING';
export const STOP_STORE_LOADING = 'STOP_STORE_LOADING';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS';

export const GET_REPORTING_PAGES = 'GET_REPORTING_PAGES';
export const CLEAN_REPORTING_PAGES = 'CLEAN_REPORTING_PAGES';
export const GET_REPORTING_PAGES_SUCCESS = 'GET_REPORTING_PAGES_SUCCESS';
export const START_GLOBAL_LOADING = 'START_GLOBAL_LOADING';
export const STOP_GLOBAL_LOADING = 'STOP_GLOBAL_LOADING';
export const INIT_APP = 'INIT_APP';
export const INTI_HOME = 'INTI_HOME';
export const INIT_CLIENTS = 'INIT_CLIENTS';
export const INIT_CLIENTS_SUCCESS = 'INIT_CLIENTS_SUCCESS';
