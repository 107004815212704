import { GetWidget, getLangId, translateComment } from 'apis/url';
import { AxiosResponse } from 'axios';
import { call, put, takeEvery, fork, all, select, takeLatest } from 'redux-saga/effects';
import {  GET_TEXT_TRANSLATION_VERBATIM, GET_VERBATIM_DATA } from './type';
import { getClientId } from 'helpers/authUtils';
import { getTransationTextVerbatimFail, getTransationTextVerbatimSuccess, getVerbatim, getVerbatimFail, getVerbatimSuccess } from './action';
import { openNotification } from './notif';
import { openGlobalNotification } from 'pages/components/notifications';

export function* handleGetTranslationText(payload: any): Generator<any, void, AxiosResponse<any, any>> {
    const { groupText, id} = payload;
  const  reportingLanguage:any= yield select((s:any)=>s.Layout.reportingLanguage)
  const langcode=reportingLanguage.find((item:any) => item.value === getLangId())?.code?.toLowerCase();  
    try {
      const translations: any = yield all(groupText.map((text: any) => call(translateComment, text,langcode)));

      const texttranslated = yield translations &&
        translations.map((t: any,index:any) => {
          return {comment:t.data[0].translations[0].text,key:groupText[index].key};
        });
     
      yield put(getTransationTextVerbatimSuccess(texttranslated, id));
    } catch (e) {
  //console.log("eeeeee",e)
      yield put(getTransationTextVerbatimFail(e));
    }
  }
  export function* handelGetWidgets(payload: any): Generator<any, void, AxiosResponse<any, any>> {
    const { settings, filter, id, endPoint, series, source, sectionIds, questionWithAnswerIds,deleteaction ,checked,notifText} = payload;
    const lastFilter = yield select((s: any) => s.Dashboard.lastUpdateFilter);
    const clientId = getClientId();
    try {
       const response: any = yield lastFilter&&call(
        GetWidget,
        lastFilter,
        settings,
        clientId,
        endPoint,
        series,
        source,
        sectionIds,
        questionWithAnswerIds
      );
      const res = response?.data;
      const data = { res, id };
      yield put(getVerbatimSuccess(data));
    if(deleteaction===true&&checked===true)   openGlobalNotification({status : "success",message :  notifText});
    } catch (error: any) {
      yield put(getVerbatimFail(error.response.data.message));
    }
  }
 
  export function* watchHandelGetTranslationText(): any {
    yield takeEvery(GET_TEXT_TRANSLATION_VERBATIM, handleGetTranslationText);
  }
  export function* watchHandelGetVerbatim(): any {
    yield takeEvery(GET_VERBATIM_DATA, handelGetWidgets);
  }
  export default function* translationVerbatimSaga(): any {
    yield all([
      fork(watchHandelGetTranslationText),
      fork(watchHandelGetVerbatim)
    ]);
  }