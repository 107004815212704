import {PrintPdf} from "./Type";

export const pdfPrint = (elementId = null,name,generated,exportedby,exportdate,widget) => ({
    type: PrintPdf,
    elementId,
    name,
    generated,
    exportedby,
    exportdate,
    widget
  });