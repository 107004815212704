import { GET_QUESTION_LEADER_BOARD, GET_QUESTION_LEADER_BOARD_SUCCESS,GET_QUESTION_LEADER_BOARD_FAILED,GET_QUESTION_HIGHEST_ADVOCAY,GET_QUESTION_HIGHEST_ADVOCAY_SUCCESS,GET_QUESTION_HIGHEST_ADVOCAY_FAILED} from './type';
  
  export const requestQuestionsLeaderboard = (params:any) => (

    {
      type: GET_QUESTION_LEADER_BOARD,
      params,
  });
  
  export const requestAQuestionsLeaderboardSucess = (payload:any) => (

      {
      type: GET_QUESTION_LEADER_BOARD_SUCCESS,
      payload,
  });
  export const requestAQuestionsLeaderboardFailed = (payload:any) => ({
      type: GET_QUESTION_LEADER_BOARD_FAILED,
      payload,
  });

  // question Highest advocay  widget
  
  export const requestQuestionsHighestAdvocay = (params:any) => (

    {
      type: GET_QUESTION_HIGHEST_ADVOCAY,
      params,
  });
  
  export const requestQuestionsHighestAdvocaySucess = (payload:any) => (
      {
      type: GET_QUESTION_HIGHEST_ADVOCAY_SUCCESS,
      payload,
  });
  export const requestQuestionsHighestAdvocayFailed = (payload:any) => ({
      type: GET_QUESTION_HIGHEST_ADVOCAY_FAILED,
      payload,
  });