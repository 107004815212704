import React, { CSSProperties } from 'react';
import { Checkbox, List, Skeleton } from 'antd';

type ListLoadingProps = {
  size: 'small' | 'large' | 'default';
};

const listData = Array.from({ length: 3 }).map((_, i) => ({
  title: `ant design part ${i + 1}`,
}));

const sizeStyle: Record<
  'default' | 'small' | 'large',
  {
    list?: CSSProperties;
    input?: CSSProperties;
    inputContainer?: CSSProperties;
    item?: CSSProperties;
  }
> = {
  default: {
    input: {},
    inputContainer: {},
    item: {},
  },
  small: {
    list: { paddingInline: 0, alignItems: 'center', width: '100%' },
    item: { alignItems: 'center', paddingInline: 0, marginRight: 5, width: '100%', marginLeft: 'auto', display: 'flex' },
    inputContainer: { width: '100%', display: 'flex', flexDirection: 'row', marginLeft: '9%', alignItems: 'center', flex: 1 },
    input: { width: '100%', marginLeft: '10px', flex: 1 },
  },
  large: {
    list: { paddingInline: 0, alignItems: 'center', width: '265px' },
    item: { alignItems: 'center', paddingInline: 0, margin: 0, width: 255, marginLeft: 'auto' },
    inputContainer: { width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', flex: 1 },
    input: { width: '245px', marginLeft: '10px', flex: 1 },
  },
};
const App: React.FC<ListLoadingProps> = ({ size }) => {
  return (
    <>
      <List
        style={sizeStyle?.[size]?.list}
        itemLayout="vertical"
        size={size}
        dataSource={listData}
        renderItem={() => (
          <List.Item style={sizeStyle?.[size]?.item}>
            <div style={sizeStyle?.[size]?.inputContainer} className="list-item__container">
              <Checkbox disabled />
              <Skeleton.Input style={sizeStyle?.[size]?.input} active />
            </div>
          </List.Item>
        )}
      />
    </>
  );
};

export default App;
