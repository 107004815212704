import { ActionTypes } from './constantes';

type InitialState = { isLoading: number ,isDownloadLoading:number ,reportType:any};

function loaderReducer(state: InitialState = { isLoading: 0 ,isDownloadLoading:0,reportType:[]}, action: any) {
  switch (action.type) {
    case ActionTypes.ADD_GENERIC_LOADER:
      return {
        ...state,
        isLoading: state.isLoading + 1,
      };
    case ActionTypes.REMOVE_GENERIC_LOADER:
      return {
        ...state,
        isLoading: state.isLoading - 1,
      };
    case ActionTypes.ADD_DOWNLOADING_LOADER:
      return {
        ...state,
        isDownloadLoading: state.isDownloadLoading + 1,
        reportType:[...state.reportType, action.reportType]
      };
    case ActionTypes.REMOVE_DOWNLOADING_LOADER:
      return {
        ...state,
        isDownloadLoading: state.isDownloadLoading - 1,
        reportType: state.reportType.filter((type:any) => type !== action.reportType)
      };
    default:
      return state;
  }
}

export default loaderReducer;
