import { GET_ALLQUESTION_DISTRIBUTION_SUCCESS } from "./type";

export const defaultState = {
  allQuestionDistribution: [],
  hasData: false,
};

function AllQuestionDistribution(state = defaultState, action: any) {
  switch (action.type) {
    case GET_ALLQUESTION_DISTRIBUTION_SUCCESS:
      const { oldData } = action.payload;
      const {widgetId}=action.payload

      return {
        ...state,
        [widgetId]:{
          hasData: true,
          allQuestionDistribution: action.payload.data,
          oldData: oldData,
          pageParameters: action.payload.pageParameters,
        }
        
      };

    default:
      return state;
  }
}

export default AllQuestionDistribution;
