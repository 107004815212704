/* eslint-disable eqeqeq */

import {
  GET_FILTER_TEMPLATE_SUCCESS,
  RESET_GLOBAL_FILTER,
  GET_TOTAL_SURVEY_NUMBER_SUCCESS,
  REQUEST_FILTER_DATA_SUCCESS,
  ON_CHANGE_FILTER_MODEL,
  CHANGE_FILTER_DATA,
  INIT_FITLER_LOADING,
  CLEAR_GLOBAL_FILTER,
  SET_ACTIVE_FILTER,
  GET_HEADER_INFO_SUCCESS,
} from './types';

type Props = {
  template: any;
  totalSurveyNumber: number | undefined;
  storesCount: number | undefined;
  lastUpdatedDate: number | undefined;
  filtersData: any;
  filtersDataInstance: any;
  filterModel: any;
  generalTemplateSettings: any;
  loadingFilters: any;
  filterTemplateDefintion: any[];
  activeFilter: string | null | undefined;
};
const INIT_STATE: Props = {
  template: null,
  totalSurveyNumber: undefined,
  storesCount : undefined,
  lastUpdatedDate : undefined,
  generalTemplateSettings: { listFilters: [] },
  filtersData: {},
  filtersDataInstance: {},
  filterModel: { period: {} },
  loadingFilters: {},
  filterTemplateDefintion: [],
  activeFilter: null,
  

};

const Filter = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case GET_FILTER_TEMPLATE_SUCCESS:
      return {
        ...state,
        template: action.payload,
        filterTemplateDefintion: action.filterTemplateDefintion,
        filterModel: { ...state.filterModel, ...action.initialModel },
        generalTemplateSettings: {
          ...state.generalTemplateSettings,
          displayTotalNumberOfSurveysStoreLevel: action.payload.displayTotalNumberOfSurveysStoreLevel,
          displayTotalNumberOfSurveys: action.payload.displayTotalNumberOfSurveys,
          defaultPeriodOptions: action.payload.defaultPeriodOptions,
          clientApplication: 1,
          listFilters: action.initialModel?.dynamicFilterProperties,
        },
      };
    case REQUEST_FILTER_DATA_SUCCESS:
      return {
        ...state,
        filtersData: { ...state.filtersData, ...(action.initialData ? { [action.id]: action.initialData } : {}) },
        filtersDataInstance: { ...state.filtersDataInstance, [action.id]: action.formatedData },
        generalTemplateSettings: {
          ...state.generalTemplateSettings,
          ...(action.initialModel ? { listFilters: [...(state.generalTemplateSettings.listFilters || []), action.initialModel] } : {}),
        },
      };
    case CHANGE_FILTER_DATA:
      return {
        ...state,
        filtersDataInstance: { ...state.filtersDataInstance, [action.id]: action.data },
      };
    case ON_CHANGE_FILTER_MODEL:
      return {
        ...state,
        filterModel: {
          ...state.filterModel,
          [action.key]: action.value,
          ...(action.source ? { dynamicFilterProperties: action.source } : {}),
        },
        generalTemplateSettings: {
          ...state.generalTemplateSettings,
          ...(action.source ? { listFilters: action.source || [] } : {}),
        },
      };
    case GET_TOTAL_SURVEY_NUMBER_SUCCESS:
      return {
        ...state,
        totalSurveyNumber: action.payload,
      };
      case GET_HEADER_INFO_SUCCESS:
        console.log(action.payload.LastUpdatedDate,action.payload.StoresCount)
        return {
          ...state,
          lastUpdatedDate: action.payload.LastUpdatedDate,
          storesCount : action.payload.StoresCount,

        };
    case RESET_GLOBAL_FILTER:
      return {
        ...INIT_STATE,
      };
    case INIT_FITLER_LOADING:
      return {
        ...state,
        loadingFilters: {
          ...state.loadingFilters,
          [action.target]: { status: action.value, loadingRequestType: action.loadingRequestType },
        },
      };
    case SET_ACTIVE_FILTER:
      return { ...state, activeFilter: action.payload };
    case CLEAR_GLOBAL_FILTER:
      return {
        ...state,
        ...INIT_STATE,
      };

    default:
      return { ...state };
  }
};
export default Filter;
