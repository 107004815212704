export const USER_RIGHTS = {
    EVERYTHING: 'EVERYTHING',
  
    PLATFORM_ADMIN: 'PLATFORM_ADMIN',
    PLATFORM_EVALUATORS: 'PLATFORM_EVALUATORS',
    PLATFORM_CLIENTS: 'PLATFORM_CLIENTS',
    PLATFORM_REPORTING: 'PLATFORM_REPORTING',
    API_ACCESS_MANAGEMENT: 'API_ACCESS_MANAGEMENT',
    API_QUESTIONARY: 'API_QUESTIONARY',
    API_QUESTION_LIBRARY: 'API_QUESTION_LIBRARY',
    API_NOMENCLATURES: 'API_NOMENCLATURES',
    API_CLIENTS: 'API_CLIENTS',
    API_Logs_Manager: 'API_Logs_Manager',
    API_VERSION_ACCESS: 'API_CXGCONNECT_VERSION',
    API_PROJECTS: 'API_PROJECTS',
    API_MISSIONS: 'API_MISSIONS',
    API_WAVES: 'API_WAVES',
    API_REPORT_DEFINITIONS: 'API_REPORT_DEFINITIONS',
    API_USERS: 'API_USERS',
    API_EVALUATORS: 'API_EVALUATORS',
    API_PERMISSIONS: 'API_PERMISSIONS',
    API_REPORTING: 'API_REPORTING',
    API_PODCAST: 'API_PODCAST',
    API_COACHING: 'API_COACHING',
    API_SECTION: 'API_SECTION',
    API_COACHING_LIBRARY: 'API_COACHING_LIBRARY',
    API_COACHING_TEMPLATES: 'API_COACHING_TEMPLATES',
    INTERNAL_REPORTING: 'INTERNAL_REPORTING',
    API_APP_NOTIFICATIONS: 'API_APP_NOTIFICATIONS',
    API_TRANSLATION: 'API_TRANSLATION',
    API_IOF_MANAGEMENT: 'API_IOF_MANAGEMENT',
    API_EXPERIENCE_REPORT_MANAGEMENT: 'API_EXPERIENCE_REPORT_MANAGEMENT',
  };
  
  export const ACCESS_LEVELS = {
    FULL: 'FULL',
    OPEN: 'OPEN',
    VIEW: 'VIEW',
    EDIT: 'EDIT',
    APPLY: 'APPLY',
  };
  
  export const userAccess = (type:any, value:any) => ({ type, value });
  