export const GET_AG_GRID_Data = "GET_AG_GRID_Data";
export const GET_AG_GRID_Data_SUCCESS = "GET_AG_GRID_Data_SUCCESS";
export const GET_AG_GRID_Data_FAILED = "GET_AG_GRID_Data_FAILED";
export const DownloadMissionsPDFs = "DownloadMissionsPDFs";
export const RequestExperinceReportDocumentViewer =
  "RequestExperinceReportDocumentViewer";
export const RequestExperinceReportDocumentViewerSuccess =
  "RequestExperinceReportDocumentViewerSuccess";
export const RequestExperinceReportDocumentDownloadAsPdf =
  "RequestExperinceReportDocumentDownloadAsPdf";
export const handleRequestChangeMissionStatus =
  "handleRequestChangeMissionStatus";
export const RequestMissionDetails = "REQUEST_MISSION_DETAILS";
export const RequestMissionDetailsSuccess = "RequestMissionDetailsSuccess";
export const RequestClientDesign = "REQUEST_CLIENT_DESIGN";
export const RequestClientDesignSuccess = "REQUEST_CLIENT_DESIGN_SUCCESS";
export const CleanMissionDetails = "CleanMissionDetails";
export const CleanClientDesign = " CLEAN_CLIENT_DESIGN";
export const PrintPage = "cxg/pages/reporting/PRINT"

export const ERT_HEAD =
  '<style type="text/css">html {position: relative;  min-height: 100%;text-align: left;  }*,*::after,*::before {box-sizing: border-box }  body {font-size: 14px;line-height: 1.5;color: #575757;background-color: #fff;margin: 0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing: grayscale}td,th{padding: 0;}  p {margin-bottom: 1rem; margin-top: 0px;} p + p {margin-top: 8px;}</style>';
