export const ActionTypes = {
  RequestIOFSAccess: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOFS_ACCESS',
  RequestIOFSAccessSuccess: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOFS_ACCESS_SUCCESS',
  RequestLoginToIOFSSuccess: 'cxg/pages/administration/notifications-management-sytem/REQUEST_LOGIN_TO_IOFS_SUCCESS',
  RequestIOFSListData: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOFS_LIST_DATA',
  RequestIOFSListDataSuccess: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOFS_LIST_DATA_SUCCESS',
  SaveCurrentIofItem: 'cxg/pages/administration/notifications-management-sytem/SAVE_CURRENT_ITEM',
  requestIofItemDetails: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOF_ITEM_DETAILS',
  // iofs fiels work
  requestFieldWork: 'cxg/pages/administration/notifications-management-sytem/REQUEST_FIELD_WORK',
  requestFieldWorkSuccess: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOF_ITEM_DETAILS_SUCCESS',
  // general infos
  requestIofInfosSuccess: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOF_INFORMATION_SUCCESS',
  requestIofInfos: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOF_INFORMATION',
  // pos
  requestIofPosSuccess: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOF_POS_SUCCESS',
  requestIofPos: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOF_POS',
  // evaluator
  requestIofEvaluatorSuccess: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOF_EVALUATOR_SUCCESS',
  requestIofEvaluator: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOF_EVALUATOR',
  // pm&report
  requestIofPmSuccess: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOF_PM_SUCCESS',
  requestIofPm: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOF_PM',
  // analysis
  requestIofAnalysisSuccess: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOF_ANALYSIS_SUCCESS',
  requestIofAnalysis: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOF_ANALYSIS',
  // scenarios
  requestIofScenarioSuccess: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOF_SENARIO_SUCCESS',
  requestIofScenario: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOF_SCENARIO',
  // scenarios
  requestIofScenarioByIdSuccess: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOF_SENARIO_BY_ID_SUCCESS',
  requestIofScenarioById: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOF_SCENARIO_BY_ID',
  // pos by id
  requestIofPosByIdSuccess: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOF_POS_BY_ID_SUCCESS',
  requestIofPosById: 'cxg/pages/administration/notifications-management-sytem/REQUEST_IOF_POS_BY_ID',
  // iofs attachment
  requestAttachmentSuccess: 'cxg/pages/administration/notifications-management-sytem/REQUEST_ATTACHMENT_SUCCESS',
  requestAttachment: 'cxg/pages/administration/notifications-management-sytem/REQUEST_ATTACHMENT',
  // pos attachment
  requestPosAttachmentSuccess: 'cxg/pages/administration/notifications-management-sytem/REQUEST_POS_ATTACHMENT_SUCCESS',
  requestPosAttachment: 'cxg/pages/administration/notifications-management-sytem/REQUEST_POS_ATTACHMENT',
  // scenario attachments
  requestScenarioAttachmentSuccess: 'cxg/pages/administration/notifications-management-sytem/REQUEST_SCENARIO_ATTACHMENT_SUCCESS',
  requestScenarioAttachment: 'cxg/pages/administration/notifications-management-sytem/REQUEST_SCENARIO_ATTACHMENT',
};

export const IOFSListSortFields = {
  id: 'id',
};
export const IOFSListSearchFields = {
  ...IOFSListSortFields,
};
export const IOFSTATUS = {
  Pending: 'Pending',
  Completed: 'Complete',
  OnHold: 'On Hold',
};
