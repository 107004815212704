/** */
import {
  LOGIN_USER,
  LOGOUT_USER,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  SET_SHOW_LP_USER,
  SAVE_CONTACT,
  REQUEST_CHECK_SSO_SESSION,
  LOGIN_USER_BY_SSO,
  LOGIN_USER_FROM_SSO,
  GET_SESSION_ID,
  GET_ACTIVE_COMPAIGN,
  GET_ACTIVE_COMPAIGN_SUCCESS,
  GET_COMPAIGN_WITH_PATH,
} from './constants';
import { Cookies } from 'react-cookie';
import {
  UserLogin,
  requestForgetPassword,
  requestResetPassword,
  getLandingPage,
  SetdisplayLpUser,
  saveContact,
  requestSSOLogin,
  requestLoginToken,
  requestSessionDetailsData,
  requestLogout,
  requestChangeLanguage,
  requestActiveCompaign,
  requestCompaignWithPath,
  requestDashboardClients,
} from '../../apis/url';
import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  loginUserSuccess,
  loginUserFailed,
  forgetPasswordSuccess,
  forgetPasswordFailed,
  resetPasswordSuccess,
  resetPasswordFailed,
  getLandingPageSuccess,
  AuthAction,
  savecontactSuccess,
  savecontactFail,
  SSORedirectSuccess,
  oldConnectRedicredSuccess,
  getActiveCampaignSuccess,
  getCampaignWithPathSuccess,
} from './actions';
import { TOKEN_COOKIE_NAME, CLIENT_ID_COOKIE, CLIENT_LOGO_COOKIE, LANGUAGE_COOKIE_NAME, CHANGELANG_COOKIE_NAME, CLIENT_FROM_COOKIE } from '../../constants/cookie-names';
import Auth from 'types/auth.models';
import { getChangeLangCookies, getLangFromCookies, getLoggedInUser } from 'helpers/authUtils';
import { NavigateFunction } from 'react-router-dom';
import handleSagaErrors from 'pages/commons/handel-saga-errors';
import { USER_TYPES } from 'constants/common';
import { changeLanguageSuccess } from 'Layout/action';
import { openGlobalNotification } from 'pages/components/notifications';

export const setSession = (data: any) => {
  const cookies = new Cookies();
  if (data) {
    const { token } = data;
    if (token) {
      cookies.set(TOKEN_COOKIE_NAME, `${token}`, {
        path: '/',
        secure: true,
        // httpOnly: true
      });
    }
  } else {
    cookies.remove(TOKEN_COOKIE_NAME, { path: '/' });
  }
};
export const setClientLogo = (clientLogo: any) => {
  const cookies = new Cookies();
  cookies.set(CLIENT_LOGO_COOKIE, `${clientLogo}`, {
    path: '/',
    secure: true,
    // httpOnly: true
  });
};
export const setClientId = (clientId: any, path: string = '/') => {
  const cookies = new Cookies();
  cookies.set(CLIENT_ID_COOKIE, clientId, { path });
};

export const setClientComeFrom = (connectFrom?: string,path : string = "/") => {
  const cookies = new Cookies();
  console.log("cookies...",connectFrom);
  if(connectFrom === undefined) {
    cookies.remove(CLIENT_FROM_COOKIE);
  } else {
    cookies.set(CLIENT_FROM_COOKIE, connectFrom, { path });
  }
  
  

  console.log(cookies.getAll());
}

export const getClientComeFrom = () => {
  const cookies = new Cookies();
  const ClientComeFrom = cookies.get(CLIENT_FROM_COOKIE);
  return ClientComeFrom ?? "";
}

function* Login(action: AuthAction): Generator<any, void, AxiosResponse<any, any>> {
  try {
    const { payload } = action;
    const { username, password, navigate,path } = payload;
    const response: any = yield call(UserLogin, { username, password },{lightVersion:true});
    setSession({ ...response });
    yield put(loginUserSuccess(response));

    const { showLandingPage, clientId } = response;
    if (getLangFromCookies() && getChangeLangCookies() && clientId)
    {console.log('okkkkkkkkk')
    const oldlang=getLangFromCookies() 
    const response1: any = yield call(requestChangeLanguage, { id: clientId },getLangFromCookies());
    yield put(changeLanguageSuccess(oldlang));}
    

    const { userType }: any = getLoggedInUser();

    if (userType === USER_TYPES.CLIENT) {
     const { data } = yield call(requestDashboardClients);
      setClientId(clientId); 
      setClientLogo(data.at(0).logo);

      let landingPageFiles = null;
      if (showLandingPage) {
        const { data } = yield call(getLandingPage, clientId);
        if (data) {
          landingPageFiles = data;
          localStorage.setItem('landing', JSON.stringify(landingPageFiles));
          yield put(getLandingPageSuccess(landingPageFiles));
        }
      }

      if (showLandingPage && landingPageFiles?.displayForClient) {
        yield call(() => {
          navigate('/landing-page');
        });
      } else {
        yield yield call(() => {
          navigate('/dashboard');
        });
      }
    } else if (userType === USER_TYPES.USER || userType === USER_TYPES.ADMIN) {
      yield yield call(() => {
        // navigate('/clients');
        navigate('/home-admin/clients');
      });
    }
  } catch (e: any) {
    yield put(loginUserFailed(e.response.data.message));
  }
}

function* handleLoginFromSSO(action: {
  type: string;
  payload: Auth.SSOLogin.LoginBySSO;
}): Generator<any, void, AxiosResponse<any, any>> {
  try {
    const { username, ssid, navigate,path } = action?.payload;

    const response: any = yield call(requestLoginToken, { username, sessionId: ssid },{lightVersion:true});
    setSession({ ...response });
    const { showLandingPage, clientId } = response;
    const { userType }: any = getLoggedInUser();

    if (userType === USER_TYPES.CLIENT) {
      const { data } = yield call(requestDashboardClients);
      setClientId(clientId); 
      setClientLogo(data.at(0).logo);

      let landingPageFiles = null;
      if (showLandingPage) {
        const { data } = yield call(getLandingPage, clientId);
        if (data) {
          landingPageFiles = data;
          localStorage.setItem('landing', JSON.stringify(landingPageFiles));
          yield put(getLandingPageSuccess(landingPageFiles));
        }
      }
      if (showLandingPage && landingPageFiles?.displayForClient) {
        yield call(() => {
          navigate && navigate('/landing-page');
        });
      } else {
        yield yield call(() => {
          navigate && navigate('/dashboard');
        });
      }
    } else if (userType === USER_TYPES.USER || userType === USER_TYPES.ADMIN) {
      yield yield call(() => {
        // navigate && navigate('/clients');
        navigate && navigate('/home-admin/clients');
      });
    }
    yield put(SSORedirectSuccess());
    localStorage.setItem('isSSO', 'true');
    localStorage.setItem('SSID', ssid);
    sessionStorage.removeItem('pathname');
  } catch (error) {
    throw error;
  }
}

/**
 * When user updates his own profile, the cookies shoould be refreshed
 * @param {*} payload - username and password
 */

// function* updateUserSession(user: any) {
//   setSession(user);
// }
export function* forgetPassword(email: any,WebHost:any): Generator<any, void, AxiosResponse<any, any>> {

  try {
    const response: any = yield call(requestForgetPassword, email);
    yield put(forgetPasswordSuccess(response.data));
  } catch (error: any) {
    yield put(forgetPasswordFailed(error.response.data.message));
  }
}
function* resetPassword(payload: any): Generator<any, void, AxiosResponse<any, any>> {
  
  try {
    const response: any = yield call(requestResetPassword, payload);
    yield put(resetPasswordSuccess(response));

    openGlobalNotification({status : "success",message :  payload.payload.successSend});
   payload.payload.navigate('/login');
  } catch (e: any) {

    yield put(resetPasswordFailed(e.response.data.message));
  }
}
export function* handelsetLPUser(payload: any) {
  yield call(SetdisplayLpUser, payload.userId.sub, payload.showAgain);
}
export function* handelSaveContact(payload: any): Generator<any, void, AxiosResponse<any, any>> {
  try {
    const response: any = yield call(saveContact, payload);
    yield put(savecontactSuccess(response.data));
  } catch (e: any) {
    yield put(savecontactFail(e));
  }
}
export function* handleActiveCampaign():  Generator<any, void, AxiosResponse<any, any>> {
    try {
      const {data}: any = yield call(requestActiveCompaign);
      yield put(getActiveCampaignSuccess(data))
    } catch (e:any) {
      
    }

}
export function* handleCampaignById({path,navigate} : {path : string,navigate : Function}):  Generator<any, void, AxiosResponse<any, any>> {
  console.log("handleCampaignById",path);
  try {
    const {data}: any = yield call(requestCompaignWithPath,path);
    yield put(getCampaignWithPathSuccess(data))
  } catch (e:any) {
    let status = e.response.status ;
    if(status === 404) {
      navigate("/login");
    }
    
  }

}


function* handleRequestSessionDetails(action: {
  type: string;
  sessionId: string;
  clientId: string;
  navigate: NavigateFunction;
  path ?: string;
}): Generator<any, void, AxiosResponse<{ sessionId: string }, any>> {
  const { clientId, navigate, sessionId,path } = action;
  const response: any = yield call(requestSessionDetailsData, { sessionId });
  if (response.status === 200) {
    yield call(() => {
      setClientId(clientId);
      setSession({ ...response?.data });
      setClientLogo(response?.cxgDesign?.clientLogo);
    });
    yield call(() => {
      navigate && navigate('/dashboard');
    });
    yield put(oldConnectRedicredSuccess());
  } else {
    yield call(() => {
      const clientPath = getClientComeFrom();
      navigate && navigate(`login/${clientPath}`);
    });
  }
}

function* logout({ payload }: any): Generator<any, void, AxiosResponse<Auth.SSOLogin.IUserLogout, any>> {
  const cookies = new Cookies();
  const { navigate } = payload;

  const { url }: any = yield call(requestLogout);
  sessionStorage.clear();
  localStorage.clear();
  let comeFrom = getClientComeFrom();
  yield call(() => {
    cookies.remove(TOKEN_COOKIE_NAME, { path: '/' });
  });
  yield call(() => {
    cookies.remove(CLIENT_LOGO_COOKIE, { path: '/' });
  });
  yield call(() => {
    cookies.remove(CLIENT_ID_COOKIE, { path: '/' });
  });
  yield call(() => {
    cookies.remove(LANGUAGE_COOKIE_NAME, { path: '/' });
  });
  yield call (()=>{
    cookies.remove(CHANGELANG_COOKIE_NAME,{path:'/'});
  })

  if (url) {
    window.location.assign(url);
  } else {
    yield call(() => {
      navigate && navigate(`/login/${comeFrom}`) ;
      window.location.reload()
    });
  }
}
function* handleLoginBySSO() {
  const { data } = yield call(requestSSOLogin, { returnUrl: window.location.origin });
  yield call(() => {
    window.location.assign(`https://${data?.url}`);
  });
}

function* handleCheckSSOSession() {
  const { data } = yield call(requestSSOLogin, { silent: true, returnUrl: window.location.origin });

  yield call(() => {
    window.location.assign(`https://${data?.url}`);
  });
}
export function* watchLoginUser(): any {
  yield takeLatest(LOGIN_USER, handleSagaErrors(Login));
}
export function* watchForgetPassword(): any {
  yield takeEvery(FORGET_PASSWORD, handleSagaErrors(forgetPassword));
}
// export function* watchUpdateUserSession(): any {
//   yield takeEvery(UPDATE_USER_SESSION, updateUserSession);
// }
export function* watchResetPassword(): any {
  yield takeEvery(RESET_PASSWORD, handleSagaErrors(resetPassword));
}

export function* watchSetLPUser() {
  yield takeEvery(SET_SHOW_LP_USER, handelsetLPUser);
}
export function* watchSaveContact() {
  yield takeEvery(SAVE_CONTACT, handelSaveContact);
}

export function* checkSSOSession() {
  yield takeLatest(REQUEST_CHECK_SSO_SESSION, handleCheckSSOSession);
}

export function* loginFromSSo(): any {
  yield takeLatest(LOGIN_USER_FROM_SSO, handleLoginFromSSO);
}

export function* requestSessionDetails(): any {
  yield takeLatest(GET_SESSION_ID, handleSagaErrors(handleRequestSessionDetails));
}

export function* loginBySSO() {
  yield takeLatest(LOGIN_USER_BY_SSO, handleLoginBySSO);
}
/**


export function* refreshToken() {
  const response = yield call(requestLoginToken, payload);
  try {
    const result = yield call(requestRefreshTokens, {
      accessToken: response.token,
      refreshToken: response.refreshToken,
    });
    response.token = result.token;
    response.refreshToken = result.refreshToken;
    setSession(response);
  } catch (err) {
    return false;
  }
  return true;
}
*/

export function* watchLogoutUser(): any {
  yield takeLatest(LOGOUT_USER, handleSagaErrors(logout));
}

export function* watchactiveCompaign() {
  yield takeEvery(GET_ACTIVE_COMPAIGN, handleSagaErrors(handleActiveCampaign));
}

export function* watchactiveCompaignByPath() {
  yield takeEvery(GET_COMPAIGN_WITH_PATH, handleSagaErrors(handleCampaignById));
}

/** 

export function* watchUpdateUserSession(): any {
  yield takeEvery(UPDATE_USER_SESSION, updateUserSession);
}


export function* watchRegisterUser(): any {
  yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword(): any {
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
}



export function* watchRefreshToken(): any {
  yield takeEvery(REFRESH_TOKEN, refreshToken);
}
export function* handelShowLPforUser() {

}

*/
function* AuthSaga(): any {
  yield all([
    fork(watchLoginUser),
    fork(watchForgetPassword),
    fork(watchResetPassword),
    fork(watchSetLPUser),
    fork(watchSaveContact),
    fork(checkSSOSession),
    fork(loginFromSSo),
    fork(loginBySSO),
    fork(watchLogoutUser),
    fork(requestSessionDetails),
    fork(watchactiveCompaign),
    fork(watchactiveCompaignByPath),
    
  ]);
}

export default AuthSaga;
