import { call, put } from 'redux-saga/effects';

import { addGenericNotificationFromError } from './notifications/action';
import { addGenericLoader, removeGenericLoader } from 'features/Loader/actions';
//import * as LOADER_ACTIONS from './loader/actions';

function handleSagaErrors(saga, showLoadingIndicator = true) {
  return function* callSaga(action) {
    try {
      if (showLoadingIndicator) {
        yield put(addGenericLoader(action.type));
      }
      yield call(saga, action);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(error);
      }

      yield put(addGenericNotificationFromError(error));
    } finally {
      if (showLoadingIndicator) {
        yield put(removeGenericLoader(action.type));
      }
    }
  };
}

export default handleSagaErrors;
