/* eslint-disable eqeqeq */
import { call, put, all, select, takeLatest } from 'redux-saga/effects';
import {
  requestFilterTemplate,
  requestFilterProperties,
  requestTotalNumberOfSurveys,
  getSystLastUsed,
  savefilterProfile,
  requestHeaderInfo,
} from 'apis/url';
import {
  getFilterTemplateSuccess,
  getTotalNumberOfSurveysSuccess,
  getLastfilterSuccess,
  requestFilterDataSuccess,
  initFiltersLoading,
  clearGlobalFilter,
  setActiveFilter,
  getHeaderInfoSuccess,
} from 'redux/actions';

import {
  INIT_GLOBAL_FILTER,
  APPLY_GLOBAL_FILTER,
  REQUEST_FILTER_DATA,
  REQUEST_FILTER_DATA_TYPE,
  APPLY_FILTER,
  RequestFilterDataModel,
} from './types';
import { filterTemplateDefinition, mapLastUsedWithTemplate, buildFilterProfile } from './utils';
import { AxiosResponse } from 'axios';
import handleSagaErrors from 'pages/commons/handel-saga-errors';
import { getClientId } from 'helpers/authUtils';
import { findBy, hasKeys } from 'helpers/index';
import { SYS_LAST_USED } from 'constants/common';
import { addGenericLoader, removeGenericLoader } from 'features/Loader/actions';

/*********** */

function* handleInitGlobalFilter(action: any): Generator<any, void, AxiosResponse<any, any>> {
  const { payload } = action;
  const { page: pageItem, withRefreshDashboard } = payload;

  yield put(addGenericLoader(action.type));
  const [{ data: filterTemplate }, { data: lastUsedFilter }]: any = yield all([
    call(requestFilterTemplate, { id: pageItem?.page?.id }),
    call(getSystLastUsed, getClientId()),
  ]);
  yield put(removeGenericLoader(action.type));
  if (!withRefreshDashboard) {
    yield put(clearGlobalFilter());
  }

  if (hasKeys(filterTemplate) && hasKeys(lastUsedFilter)) {
    const model = !withRefreshDashboard ? {} : lastUsedFilter;
    const mappedFilterModel = mapLastUsedWithTemplate(model, filterTemplate);
    yield put(getFilterTemplateSuccess(filterTemplate, filterTemplateDefinition(filterTemplate), mappedFilterModel));
    if (withRefreshDashboard) {
      yield put(getLastfilterSuccess(mappedFilterModel));
    }
    const sources = Object.keys(pageItem?.page?.settings?.sources);

    const params: any = {
      queryParams: {
        sources,
        from: mappedFilterModel.period.fromDate || null,
        to: mappedFilterModel.period.toDate || null,
      },
      body: {
        displayTotalNumberOfSurveysStoreLevel: filterTemplate.displayTotalNumberOfSurveysStoreLevel,
        displayTotalNumberOfSurveys: filterTemplate.displayTotalNumberOfSurveys,
        defaultPeriodOptions: filterTemplate.defaultPeriodOptions,
        clientApplication: 1,
        listFilters: mappedFilterModel.dynamicFilterProperties,
      },
    };

    const [response, reponseHeaderInfo]: any = yield all([
      call(requestTotalNumberOfSurveys, { ...params, templateId: filterTemplate.id }),
      call(requestHeaderInfo, { ...params, pageId: pageItem?.page?.id }),
    ]);
    yield put(getTotalNumberOfSurveysSuccess(response.data));
    yield put(getHeaderInfoSuccess(reponseHeaderInfo.data));
  }
}

function* handleRequestFilterData({
  filter,
  template,
  sources,
  requestType,
  config,
  body,
}: RequestFilterDataModel): Generator<any, void, AxiosResponse<any, any>> {
  const { id } = filter;
  yield put(initFiltersLoading(id, true, requestType));
  const state: any = yield select();
  const list = Object.keys(sources);
  const filterModel = state.Filter.filterModel;
  const filtersDataInstance = state.Filter.filtersDataInstance[filter?.id];
  const filterProperties = findBy(filterModel?.dynamicFilterProperties, 'filterId', id, 'filterProperties', []);
  const { endPoint, method, queryParams } = config;
  const { data } = yield call(requestFilterProperties, {
    templateId: template?.id,
    filterId: id,
    queryParams: {
      ...(hasKeys(queryParams) ? queryParams : {}),
      sources: list,
      from: filterModel.period.fromDate || null,
      to: filterModel.period.toDate || null,
    },
    body,
    endPoint,
  });

  const result = findBy(data?.listFilters, 'filterId', id, null, data);

  if (requestType === REQUEST_FILTER_DATA_TYPE.INITIAL_REQUEST) {
    const formattedResult = method ? method(result, filterProperties) : result;
    yield put(requestFilterDataSuccess(id, formattedResult, result));
  }
  if (requestType === REQUEST_FILTER_DATA_TYPE.SEARCH_TERM) {
    const formattedResult = method ? method(result, filterProperties, !queryParams.onlyCategories) : result;
    yield put(requestFilterDataSuccess(id, formattedResult));
  }
  if (requestType === REQUEST_FILTER_DATA_TYPE.EXPAND_NODE) {
    const formattedResult = method
      ? method(result, [...(filtersDataInstance || []), ...filterProperties], false, filtersDataInstance)
      : result;
    yield put(requestFilterDataSuccess(id, formattedResult));
  }
  yield put(initFiltersLoading(id, false, requestType));
}

function* handleApplyFilter({ payload }: { payload: any }): Generator<any, void, AxiosResponse<any, any>> {
  yield put(setActiveFilter(null));
  const state: any = yield select();
  const filterModel = state.Filter.filterModel;
  const template = state.Filter.template;
  const body = state.Filter.generalTemplateSettings;
  const sources = Object.keys(payload?.page?.settings?.sources);
  const queryParams = {
    sources,
    from: filterModel.period.fromDate || null,
    to: filterModel.period.toDate || null,
  };

  const [response, reponseHeaderInfo]: any = yield all([
    call(requestTotalNumberOfSurveys, { templateId: template.id, queryParams, body }),
    call(requestHeaderInfo, { queryParams, body, pageId: payload?.page?.id }),
  ]);

  yield put(getTotalNumberOfSurveysSuccess(response.data));
  yield put(getHeaderInfoSuccess(reponseHeaderInfo.data));
}

function* handleApplyGlobalFilter({ callback }: { callback: any }): Generator<any, void, AxiosResponse<any, any>> {
  callback && callback();
  const state: any = yield select();
  const filterModel = state.Filter.filterModel;
  const filterProfile = buildFilterProfile(filterModel);
  yield put(getLastfilterSuccess({ ...filterModel, ...filterProfile }));
  yield call(savefilterProfile, { name: SYS_LAST_USED, ...filterProfile });
}
function* FilterSaga(): any {
  yield takeLatest(INIT_GLOBAL_FILTER, handleSagaErrors(handleInitGlobalFilter, false));
  yield takeLatest(REQUEST_FILTER_DATA, handleSagaErrors(handleRequestFilterData, false));
  yield takeLatest(APPLY_FILTER, handleSagaErrors(handleApplyFilter));
  yield takeLatest(APPLY_GLOBAL_FILTER, handleSagaErrors(handleApplyGlobalFilter));
}
export default FilterSaga;
