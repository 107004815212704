/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { onChangeFilterModel, changeFilterData, requestFilterData } from 'redux/actions';
import TreeLocation from './locationsTreeList';
import { findBy, hasValues, reduceByRecursive, upsertBy } from 'helpers/index';
import { mergeStoresWithTree, handleCheckAll, handleCheckTree, formatListLocation, mergeTreeWithModel } from '../utils';
import { REQUEST_FILTER_DATA_TYPE, SELECT_STATUS } from '../types';

type Props = {
  filterSettings: any;
  selectedPage: any;
  checkAllRef: React.MutableRefObject<boolean>;
};
const LocationFilter: React.FC<Props> = ({ filterSettings, selectedPage, checkAllRef }) => {
  const dispatch = useDispatch();

  const { id, key } = filterSettings;

  const filterData = useSelector((state: any) => state.Filter.filtersDataInstance[id]);
  const filterTemplate = useSelector((state: any) => state.Filter.template);
  const filterTemplateDefintion = useSelector((state: any) => state.Filter.filterTemplateDefintion);
  const generalTemplateSettings = useSelector((state: any) => state.Filter.generalTemplateSettings);
  const activeFilter = useSelector((state: any) => state.Filter.activeFilter);
  const initialData = useSelector((state: any) => state.Filter.filtersData[id]);

  const [searchText, SetSearchText] = useState<string | null>(null);
  const [typingTimer, setTypingTimer] = useState<any>(null);

  const sources = selectedPage?.page?.settings?.sources;
  const listFilters = generalTemplateSettings?.listFilters;
  const filterModel = findBy(listFilters, 'filterId', id, 'filterProperties', []);
  const hasOnlyStores =
    hasValues(filterSettings?.options) && filterSettings?.options?.length === 1 && filterSettings?.options?.includes('Stores');

  if (!activeFilter && searchText) {
    SetSearchText(null);
    dispatch(changeFilterData(id, formatListLocation(initialData, filterModel)));
  }

  const onSelect = (node: any, checked = true) => {
    let tree = filterData;
    if (!checked && checkAllRef.current) {
      tree = handleCheckAll(filterData, true);
    }
    const updatedTree = handleCheckTree(tree, checked, node);
    if (updatedTree?.every((el: any) => el.status == SELECT_STATUS.SELECTED && !searchText)) {
      onSelectAll();
    } else {
      dispatch(changeFilterData(id, updatedTree));
      let mergedTree = mergeStoresWithTree(filterModel, updatedTree);
      if (searchText) {
        mergedTree = mergeTreeWithModel(updatedTree, filterModel);
      }
      const selectedItems = reduceByRecursive(mergedTree, 'status', SELECT_STATUS.SELECTED);
      const payload = upsertBy(listFilters, { ...filterSettings, filterProperties: selectedItems }, 'filterId');
      const filtredData = payload.filter((d: any) => hasValues(d.filterProperties));
      dispatch(onChangeFilterModel(key, selectedItems, filtredData));
      checkAllRef.current = false;
    }
  };

  const onSelectAll = () => {
    checkAllRef.current = !checkAllRef.current;
    if (searchText) {
      const filterProperties = mergeTreeWithModel(handleCheckAll(filterData, checkAllRef.current), filterModel);
      const payload = upsertBy(listFilters, { ...filterSettings, filterProperties }, 'filterId');
      dispatch(onChangeFilterModel(key, filterProperties, payload));
    } else {
      dispatch(changeFilterData(id, handleCheckAll(filterData, false)));
      const filtredlistFilters = generalTemplateSettings?.listFilters.filter((el: any) => el.filterId !== id);
      dispatch(onChangeFilterModel(key, [], filtredlistFilters));
    }
  };

  const waitTime = 1000;

  useEffect(() => {
    clearTimeout(typingTimer);

    if (searchText !== null) {
      const newTimer = setTimeout(() => {
        const config = findBy(filterTemplateDefintion, 'id', filterSettings.id, 'config', null);
        dispatch(
          requestFilterData(
            filterSettings,
            filterTemplate,
            sources,
            REQUEST_FILTER_DATA_TYPE.SEARCH_TERM,
            {
              ...config,
              queryParams: {
                ...config.queryParams,
                onlyCategories: searchText || hasOnlyStores ? false : true,
                ...(searchText ? { searchCriteria: searchText } : {}),
              },
            },
            {
              ...generalTemplateSettings,
              ...(hasValues(generalTemplateSettings.listFilters)
                ? { listFilters: generalTemplateSettings.listFilters.filter((f: any) => f.filterId != id) }
                : {
                    listFilters: [],
                  }),
            }
          )
        );
      }, waitTime);
      setTypingTimer(newTimer);
    }
  }, [searchText]);

  const onSearch = (e: any) => {
    const value = e.target.value;
    SetSearchText(value);
  };
  
  return (
    <TreeLocation
      item={filterSettings}
      filterTemplate={filterTemplate}
      filterTemplateDefintion={filterTemplateDefintion}
      generalTemplateSettings={generalTemplateSettings}
      selectedPage={selectedPage}
      data={filterData}
      listSelected={null}
      searchText={searchText}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onSearch={onSearch}
      checkAllRef={checkAllRef}
    />
  );
};

export default LocationFilter;
