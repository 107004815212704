import {
    GET_LOCATION_RANKING_DATA_SUCCESS,
    GET_LOCATION_RANKING_DATA__BY_CATEGORY_ID_SUCCESS,
    START_AGGRID_LOADING
  } from "./type";
  
  export const defaultState = {
    isAgGridLoading: false, 
   };
  
  function AgGridGlobalReducer(state = defaultState, action: any) {
    
    switch (action.type) {
      case  START_AGGRID_LOADING:
        return {
          ...state,
          isAgGridLoading: true
        };     
       case  GET_LOCATION_RANKING_DATA_SUCCESS :
        return {
            ...state,
            isAgGridLoading: false
          };
          case  GET_LOCATION_RANKING_DATA__BY_CATEGORY_ID_SUCCESS :
        return {
            ...state,
            isAgGridLoading: false
          };
     
        default:
        return state;
    }
  
   }
  
  export default AgGridGlobalReducer;
  