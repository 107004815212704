// @flow
import { all } from 'redux-saga/effects';
import layoutSaga from '../Layout/saga';
import AuthSaga from '../pages/Auth/saga';
import DashboardSaga from '../pages/dashboard/saga';
import FilterSaga from '../pages/components/filter/saga'
import AgGridSaga from '../pages/dashboard/widgets/SurveyExplorerTable/sagaSe';
import LocationRankingSaga from '../pages/dashboard/widgets/LocationRanking/saga';
import AllQuestionDistributionSaga from '../pages/dashboard/widgets/AllQuestionDataGrid/saga';
import translationCommentSaga from 'pages/dashboard/widgets/Comments/saga';
import translationVerbatimSaga from 'pages/dashboard/widgets/VerbatimAnalysis/saga';
import IOFSManagementSaga from 'pages/admin/iof-management/redux/saga';
import reportingSaga from 'pages/dashboard/pdfDownLoad/saga';
import AgGridSagaEVOC from 'pages/dashboard/widgets/SurveyExplorerTableExternalVOC/saga';
import DynamicReportsSaga from 'pages/dashboard/reportsDownload/saga';
export default function* rootSaga() {
  yield all([
    layoutSaga(),
    AuthSaga(),
    DashboardSaga(),
    AgGridSaga(),
    AllQuestionDistributionSaga(),
    LocationRankingSaga(),
    FilterSaga(),
    translationCommentSaga(),
    translationVerbatimSaga(),
    IOFSManagementSaga(),
    reportingSaga(),
    AgGridSagaEVOC(),
    DynamicReportsSaga()
  ]);
}
