import {
  CLEAR_DASHBOARD,
  GET_CAOCHING_SECTIONS,
  GET_CAOCHING_SECTIONS_FAIL,
  GET_CAOCHING_SECTIONS_SUCCESS,
  GET_COLOR,
  GET_COLOR_FAIL,
  GET_COLOR_SUCCESS,
  GET_DASHBOARD,
  GET_DASHBOARD_FAIL,
  GET_DASHBOARD_SUCCESS,
  GET_ICONS,
  GET_ICONS_FAIL,
  GET_ICONS_SUCCESS,
  GET_LAST_USED,
  GET_LAST_USED_FAIL,
  GET_LAST_USED_SUCCESS,
  GET_NOM_FILTRED,
  GET_NOM_FILTRED_FAIL,
  GET_NOM_FILTRED_SUCCESS,
  GET_QBT,
  GET_QBT_FAIL,
  GET_QBT_SUCCESS,
  GET_WIDGET,
  GET_WIDGET_FAIL,
  GET_WIDGET_SUCCESS,
} from './type';

const INIT_STATE = {
  loading: false,
  isLoginError: false,
  error: null,
  dashboard: null,
  widgetdata: [],
  colors: [],
  lastUpdateFilter: null,
  section: null,
  translatedText: [],
  nomonclatureFilted: [],
  qbt: null,
  baseQuestionnaireId:3210,
  ids:[],
  icons:[],
  QuestionnaireList:[
    {
        "name": "WW-COSMETICS-DIPTYQUE-RETAIL-R-2023-03",
        "id": 3209,
        "waveName": "2023-03 (March|Mar)",
        "scenarioName": "2023-03 (March_Mar)-27133",
        "scenarioId": 3430
    },
    {
        "name": "WW-COSMETICS-DIPTYQUE-CUSTOMER SERVICE-R-2023-03",
        "id": 3210,
        "waveName": "2023-03 (March|Mar)",
        "scenarioName": "2023-03 (March_Mar)-27178",
        "scenarioId": 3431
    },
    {
        "name": "WW-COSMETICS-DIPTYQUE-RETAIL-R-2023-04",
        "id": 3211,
        "waveName": "2023-04 (April|Apr)",
        "scenarioName": "2023-04 (April_Apr)-27265",
        "scenarioId": 3432
    }
]
};

const Dashboard = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case GET_DASHBOARD:
      return { ...state, loading: true };
    case GET_DASHBOARD_SUCCESS:
      return { ...state, dashboard: action.payload, loading: false, error: null, isLoginError: false };
    case GET_DASHBOARD_FAIL:
      return { ...state, loading: false, isLoginError: true, error: action.payload, dashboard: null };
    case GET_WIDGET:
      return { ...state, loading: true ,ids:[...state.ids,action.id]};
    case GET_WIDGET_SUCCESS:
      const acerankingdata = () => {
        let index = state.widgetdata.findIndex((obj: any) => obj.id === action.payload.id);
        if (index >= 0) {
          (state.widgetdata[index] as any) = action.payload as any;
        } else {
          (state.widgetdata as any).push(action.payload as any);
        }
        return state.widgetdata;
      };  
 
      return { ...state, widgetdata: acerankingdata(), error: null, isLoginError: false,loading:false ,ids:state.ids&&state.ids.filter((id) => id !== action.payload.id)};
    case GET_WIDGET_FAIL:
     
      return { ...state, isLoginError: true, error: action.payload,loading:false,ids:state.ids&&state.ids.filter((id) => id !== action.id) };
    case GET_COLOR:
      return { ...state, loading: true, isLoginError: false, error: null, colors: null };
    case GET_COLOR_SUCCESS:
      return { ...state, loading: false, isLoginError: false, error: null, colors: action.payload };
    case GET_COLOR_FAIL:
      return { ...state, loading: false, isLoginError: true, error: action.payload, colors: null };
    case GET_LAST_USED:
      return { ...state, loading: true, isLoginError: false, error: null, lastUpdateFilter: null };
    case GET_LAST_USED_SUCCESS:
      return { ...state, loading: false, isLoginError: false, error: null, lastUpdateFilter: action.payload };
    case GET_LAST_USED_FAIL:
      return { ...state, loading: false, isLoginError: true, error: action.payload, lastUpdateFilter: null };
    case GET_CAOCHING_SECTIONS:
      return { ...state, loading: true };
    case GET_CAOCHING_SECTIONS_SUCCESS:
      return { ...state, loading: false, isLoginError: false, error: null, section: action.payload };
    case GET_CAOCHING_SECTIONS_FAIL:
      return { ...state, loading: false, isLoginError: true, error: action.payload, section: null };
    case GET_NOM_FILTRED:
      return { ...state, loading: true, isLoginError: false, error: null, nomonclatureFilted: null };
    case GET_NOM_FILTRED_SUCCESS:
      return { ...state, loading: false, isLoginError: false, error: null, nomonclatureFilted: action.payload };
    case GET_NOM_FILTRED_FAIL:
      return { ...state, loading: false, isLoginError: true, error: action.payload, nomonclatureFilted: null };
    case GET_QBT:
      return { ...state, loading: true, isLoginError: false, error: null, qbt: null };
    case GET_QBT_SUCCESS:
      return { ...state, loading: false, isLoginError: false, error: null, qbt: action.payload };
    case GET_QBT_FAIL:
      return { ...state, loading: false, isLoginError: true, error: action.payload, qbt: null };
      case GET_ICONS:
      return { ...state, loading: true, isLoginError: false, error: null,  };
    case GET_ICONS_SUCCESS:
      const saveicons = () => {
        let index = state.icons.findIndex((obj: any) => obj.id === action.payload.id);
        if (index >= 0) {
          (state.icons[index] as any) = action.payload as any;
        } else {
          (state.icons as any).push(action.payload as any);
        }
        return state.icons;
      };  
      return { ...state, loading: false, isLoginError: false, error: null, icons: saveicons() };
    case GET_ICONS_FAIL:
      return { ...state, loading: false, isLoginError: true, error: action.payload, };
    case CLEAR_DASHBOARD:
      return {
        ...state,
        loading: false,
        isLoginError: false,
        error: null,
        dashboard: null,
        widgetdata: [],
        lastUpdateFilter: null,
        nomonclatureFilted: [],
        ids:[],
        icons:[],
        
      };
    default:
      return { ...state };
  }
};
export default Dashboard;
