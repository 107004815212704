import React from 'react'
import { ReactComponent as Logo } from 'assets/images/connectLogo.svg';
import {getUserSetting} from 'helpers/authUtils'
import { Layout, Menu, Avatar, Image, Space, Button } from 'antd';
import { useLocation } from 'react-router-dom';
import {useSelector} from 'react-redux';
import DashboardName from './DashboardName';
export default function HeaderPdf(props) {
  const clientLogo=getUserSetting();
  return (
    <div>
        <div style={{display:"flex",justifyContent:'space-between',alignItems:"center",backgroundColor:'white',height:50,alignItems:'center',width:'100%'}}>
 <div style={{display:"flex",justifyContent:'space-between',alignItems:"center",backgroundColor:'#003D4C',height:50,alignItems:'center',width:'93%',borderBottomRightRadius:30}}>
 <Logo style={{marginLeft:10}} />
 </div>   
 <div> 
 <img src={`${clientLogo}`} style={{ height: '30px',paddingInline:35,width:'auto'}} />
 </div>
        </div>
        <div style={{marginTop:50}}>
        <DashboardName name={props.name}/>
        </div>
        </div>
  )
}
