/* eslint-disable  */

import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
// import handleSagaErrors from 'src/common/handle-saga-errors';
import {
  GetAgGridDataEVOC,
  requestDashboard,
  downloadAsPdfData,
  changeMissionListStatus,
  requestMissionDetails,
  requestDesign,
  requestExperinceReportDocumentViewerVOCData,
  changeMissionListEVOCStatus,
} from "../../../../apis/url";
import handleSagaErrors from "pages/commons/handel-saga-errors";
import JSZip from 'jszip';
import {
  GET_AG_GRID_EVOC_Data,
  handleRequestChangeMissionStatus_EVOC_,
  DownloadMissions_EVOC_PDFs,
  RequestExperinceReportDocumentViewer_EVOC,
  RequestExperinceReportDocumentDownloadAsPdf_EVOC,
  RequestMissionDetails_EVOC,
  RequestClientDesign_EVOC,
  ERT_HEAD,
} from "./type";
import {
  GET_QUESTION_LEADER_BOARD,
  GET_QUESTION_HIGHEST_ADVOCAY,
} from "../QuestionsLeaderboard/type";
import {
  requestAQuestionsLeaderboardSucess,
  requestQuestionsHighestAdvocaySucess,
} from "../QuestionsLeaderboard/actions";
import {
  requestAgGridTableDataSuccess,
  requestAgGridTableData,
  requestExperinceReportEVOCDocumentViewerSuccess,
  requestMissionDetailsSuccess,
  requestClientDesignSuccess,
  requestAgGridTableDataEVOCSuccess,
  requestMissionDetailsEVOCSuccess,
  requestClientDesignEVOCSuccess,
  onChangeCurrentPage,
} from "./action";
import { getClientId } from "helpers/authUtils";
import {
  getMissionExportFileName,
  openBlobDataLink,
  parseJSONObject,
} from "./utilis";
import {RequestExperinceReportDocumentViewerSuccess} from "../SurveyExplorerTable/type";
import {openGlobalNotification} from "pages/components/notifications";
import handleSagaDownloadErrors from "pages/commons/handle-saga-download-errors";
import { TranslateToStringWithLabels } from "pages/commons/Translate";
function* handleRequestAgGridData({ params }) {
  const agGridData=yield select(s=>s.surveyExplorerEVOCReducer.agGridData)
  const currentPage=yield select(s=>s.surveyExplorerEVOCReducer.currentPage)
  const { startRow, endRow, filterModel, sortModel } = params.params.request;
  const getPagination=()=>{

    let PaginationParams = {
      SurveyId: "",
      SortedValue : "",
      Direction: 0
  }
    if(params.params.api.paginationGetCurrentPage()==0){
    return PaginationParams = {
     SurveyId: "",
     SortedValue : "",
     Direction: 0
}}
else if(currentPage<=params.params.api.paginationGetCurrentPage()&&params.params.api.paginationGetTotalPages()!=params.params.api.paginationGetCurrentPage()+1){
  if( sortModel.length>0&&sortModel[0].colId==="qbt_ACE")
  {
return PaginationParams = {
  SurveyId:agGridData[agGridData.length-1].surveyId,
  SortedValue : agGridData[agGridData.length-1].qbt_AceOrder,
  Direction: 1
}
  }
  else if( sortModel.length>0&&sortModel[0].colId!="qbt_ACE")
  {
return PaginationParams = {
  SurveyId:agGridData[agGridData.length-1].surveyId,
  SortedValue : agGridData[agGridData.length-1][sortModel[0].colId],
  Direction: 1
}
  }
else return PaginationParams = {
    SurveyId:agGridData[agGridData.length-1].surveyId,
    SortedValue : agGridData[agGridData.length-1].surveyId,
    Direction: 1
}
}
else if(currentPage>params.params.api.paginationGetCurrentPage()){
 if( sortModel.length>0&&sortModel[0].colId==="qbt_ACE"){
  return PaginationParams = {
    SurveyId:agGridData[0].surveyId,
    SortedValue : agGridData[0].qbt_AceOrder,
    Direction: 2
}
 }
 else if( sortModel.length>0&&sortModel[0].colId!="qbt_ACE"){
  return PaginationParams = {
    SurveyId:agGridData[0].surveyId,
    SortedValue : agGridData[0][sortModel[0].colId],
    Direction: 2
}
 }
 
 else return PaginationParams = {
     SurveyId:agGridData[0].surveyId,
     SortedValue : agGridData[0].surveyId,
     Direction: 2
 }
 }
 else if(params.params.api.paginationGetTotalPages()==params.params.api.paginationGetCurrentPage()+1){
  return PaginationParams = {
     SurveyId:"",
     SortedValue : "",
     Direction: 3
 }
 }

    }
 const PaginationParams=getPagination()
  const { endPoint, widgetSetting, lastFilter} = params;
  const clientId = getClientId();
  let orderBy = "surveyId desc";
  let searchTerm = "";

  if (sortModel.length)
    orderBy =
      (
      sortModel[0].colId
      ).replace(/\s/g, "") +
      " " +
      sortModel[0].sort;
  
  const pageSize = endRow - startRow;
  const pageNumber = Math.round(startRow / pageSize) + 1;
  const response = yield call(
    GetAgGridDataEVOC,
    widgetSetting,
    clientId,
    endPoint,
    params.searchItem,
    pageSize !== null ? pageSize : undefined,
    pageNumber,
    orderBy,
    lastFilter,
    PaginationParams
  );
  const queryParamsObject = {
    pageNumber: Math.round(startRow / pageSize) + 1,

    pageSize: endRow - startRow,

    orderBy,

    filterBy: "",

    searchTerm,
    totalCount: response.data.totalCount,
  };
  let formattedData = [];

  if (response.data.externalVoCSurveys && response.data.externalVoCSurveys !== "") {
    formattedData = response.data.externalVoCSurveys.map((survey) => {
      if (survey.qbt_ACE && survey.qbt_ACE != "")
      survey.qbt_ACE = TranslateToStringWithLabels({
          LabelsTranslations: params.LabelsTranslations,
          id: `generic.survey.ace.${survey.qbt_ACE}`, defaultMessage: survey.qbt_ACE
      });
      const formattedEntry = {
        ...survey,
        // Add more fields here
      };
      const dataArray = Object.entries(survey.customProperties).map(([name, value]) => ({ name, value }));
     dataArray.forEach((property) => {
        formattedEntry[property.name] = property.value;
      });

      return formattedEntry;
    });

  }
 
  params.params.successCallback(
    formattedData,
    response.status == 204
    ? 0
    : response.data.totalCount
  );
  yield put(onChangeCurrentPage(params.params.api.paginationGetCurrentPage()))
  yield put(
    requestAgGridTableDataEVOCSuccess({
      agGridData: formattedData,
      pageParameters: queryParamsObject,
    })
  );
}
async function extractAndCreateFinalZip(zipBlobs) {
  try {
      const finalZip = new JSZip();

      for (let indexblob = 0; indexblob < zipBlobs.length; indexblob++) {
          const blob = zipBlobs[indexblob];
          const zip = await JSZip.loadAsync(blob);
          console.log(zip, indexblob);

          const fileNames = Object.keys(zip.files);
          for (let indexfile = 0; indexfile < fileNames.length; indexfile++) {
              const fileName = fileNames[indexfile];
              const fileData = await zip.files[fileName].async('blob');
              finalZip.file(fileName, fileData);
              console.log(fileName, fileData, indexfile, indexblob);

              if (indexblob === zipBlobs.length - 1 && indexfile === fileNames.length - 1) {
                  const downloadLink = document.createElement('a');
                  downloadLink.href = URL.createObjectURL(await finalZip.generateAsync({ type: 'blob' }));
                  downloadLink.download = 'missions-export.zip';
                  downloadLink.click();
              }
          }
      }
  } catch (error) {
      console.error('Error extracting files:', error);
      throw error; 
  }
}
function* handleDownloadMissionsPdf({
  clientId,
  clientName,
  missions,
  widgetSettings,
  languageId = 4,
  widgetId,
}) {
  try {
      if (missions.length > 1) {
          const batchSize = 10;
          const numBatches = Math.ceil(missions.length / batchSize);
          const responses = [];
          for (let i = 0; i < numBatches; i++) {
              const start = i * batchSize;
              const end = Math.min(start + batchSize, missions.length);
              const batch = missions.slice(start, end);

              const response = yield call(
                  requestDashboard,
                  clientId,
                  "DownloadExternalVoCSurveysPdf",
                  {
                    externalVoCSurveys: batch.map((x) => {
                      return ({id:Number(x.id),locationName:x.locationName,aceAnswer:x.qbt_ACE})
                    }),
                    clientId,
                    filters: { translate: widgetSettings.translate },
                    footer: widgetSettings.footer,
                    footerHeight: widgetSettings.footerHeight,
                    languageId,
                    widgetId,
                  }
              );
              responses.push(response.data);
          }
          yield extractAndCreateFinalZip(responses);
          openGlobalNotification({ status: "success", message: 'File Downloaded successfully!', placement: 'bottomRight' });

      }

      else{
          const response = yield call(
              requestDashboard,
              clientId,
              "DownloadExternalVoCSurveysPdf",
              {
                externalVoCSurveys: missions.map((x) => {
                  return ({id:Number(x.id),locationName:x.locationName,aceAnswer:x.qbt_ACE})
                }),
                clientId,
                filters: { translate: widgetSettings.translate },
                footer: widgetSettings.footer,
                footerHeight: widgetSettings.footerHeight,
                languageId,
                widgetId,
              }
          );
          if (response)
              openGlobalNotification({ status: "success", message: 'File Downloaded successfully!', placement: 'bottomRight' });
      
          if (widgetSettings && widgetSettings.preview) {
              const { callback } = widgetSettings;
              const objectURL = window.URL.createObjectURL(response.data);
              callback && callback(objectURL);
              return;
          }
          const [mission] = missions;
          const { id, qbt_ACE, locationName } = mission;
          const exportFileName = getMissionExportFileName(id, qbt_ACE,locationName);
          openBlobDataLink(response, true, `${exportFileName}.pdf`);

      }

  } catch (error) {
      console.error('Error handling download:', error);
      openGlobalNotification({ status: "error", message: 'Failed to download files!', placement: 'bottomRight' });
  }
}

function* handleDownloadMissionsPdf1({
  clientId,
  clientName,
  missions,
  widgetSettings,
  languageId = 4,
  widgetId,
}) {
  const response = yield call(
    requestDashboard,
    clientId,
    "DownloadExternalVoCSurveysPdf",
    {
      externalVoCSurveys: missions.map((x) => {
        return ({id:Number(x.id),locationName:x.locationName,aceAnswer:x.qbt_ACE})
      }),
      clientId,
      filters: { translate: widgetSettings.translate },
      footer: widgetSettings.footer,
      footerHeight: widgetSettings.footerHeight,
      languageId,
      widgetId,
    }
  );
  if (missions.length > 1) {
    openBlobDataLink(response, true, "missions-export.zip");
    return;
  }
  if (widgetSettings && widgetSettings.preview) {
    const { callback } = widgetSettings;
    const objectURL = window.URL.createObjectURL(response.data);
    callback && callback(objectURL);
    return;
  }
  const [mission] = missions;
  const { id, qbt_ACE,locationName } = mission;
  const exportFileName = getMissionExportFileName(id, qbt_ACE,locationName);

  openBlobDataLink(response, true, `${exportFileName}.pdf`);
}

function* handleRequestExperinceReportDocumentViewer({
  langId,
  clientId,
  missionId,
  translate,
  callback,
}) {
  const response = yield call(
    requestExperinceReportDocumentViewerVOCData,
    langId,
    clientId,
    missionId,
    translate
  );
  if (response.status == 200 && callback) {
    callback();
  }
  yield put(requestExperinceReportEVOCDocumentViewerSuccess(response));
}
function* handleRequestExperinceReportDocumentDownloadAsPdf({
  id,
  clientId,
  element,
  locationId,
  projectName,
  ace
}) {
  const htmlElement = document.getElementById(element);

  const formData = new FormData();
  formData.append("head", ERT_HEAD);
  formData.append("body", htmlElement.innerHTML);
  formData.append("pageSize", "A5");

  const response = yield call(downloadAsPdfData, clientId, formData);
  if (ace !="undefined")
  openBlobDataLink(
    response,
    true,
    `${id}_${locationId}_${
      new Date(Date.now()).toISOString().split("T")[0]
    }.pdf`
  );
  else
  openBlobDataLink(
    response,
    true,
    `${id}_${locationId}_${
      new Date(Date.now()).toISOString().split("T")[0]
    }.pdf`
  );
}

function* handleRequestChangeMissionListStatus({
  clientId,
  userId,
  status,
  missionList,
  callback,
}) {
  const source=1
  const response = yield call(
    changeMissionListEVOCStatus,
    clientId,
    userId,
    status,
    missionList,
    source
  );
  if (response.status == 200 && callback) {
    callback();
  }

  return response;
}
function* handleRequestMissionDetails({ clientId, missionId, settings }) {
  let missionDetails = null;

  missionDetails = yield call(
    requestMissionDetails,
    clientId,
    missionId,
    settings
  );

  // missionDetails.surveyItems = preformatTreeData(missionDetails.surveyItems);
  yield put(requestMissionDetailsEVOCSuccess(missionDetails));
}
function* handleRequestClientDesign({ clientId }) {
  const clientDesign = yield call(requestDesign, clientId);
  yield put(requestClientDesignEVOCSuccess(clientDesign));
}

// QuestionsLeaderBoard //
function* handleRequestLeaderQuestion({ params }) {
  const { startRow, endRow, filterModel, sortModel } = params.params.request;
  const { endPoint, widgetSetting, lastFilter } = params;

  const clientId = getClientId();

  let orderBy = "Id desc";

  let searchTerm = "";

  if (sortModel.length) orderBy = `${sortModel[0].colId} ${sortModel[0].sort}`;

  // const pageNumber = params.pageNumber

  const response = yield call(
    GetAgGridDataEVOC,
    widgetSetting,
    clientId,
    endPoint,
    undefined,
    undefined,
    undefined,
    undefined,
    lastFilter,
    undefined
  );
  // const pageParams = JSON.parse(response.headers["x-pagination"]);
  const queryParamsObject = {
    pageNumber: Math.round(startRow / endRow) + 1,

    pageSize: endRow - startRow,

    orderBy,

    filterBy: "",

    searchTerm,
    // totalCount: pageParams && pageParams.totalCount,
  };

  params.params.successCallback(
    response.status === 204 ? [] : response.data.questions,
    response.status === 204 ? 0 : response.data.questions.length
  );
  yield put(
    requestAQuestionsLeaderboardSucess({
      questionLeaderBoard:
        response.status === 204 ? [] : response.data.questions,
      // pageParameters: queryParamsObject,
    })
  );
}
// question highest influence
function* handleRequestQuestionHihghestInfluence({ params }) {
  const { startRow, endRow, filterModel, sortModel } = params.params.request;
  const { endPoint, widgetSetting, lastFilter } = params;

  const clientId = getClientId();

  let orderBy = "Id desc";

  let searchTerm = "";

  if (sortModel.length) orderBy = `${sortModel[0].colId} ${sortModel[0].sort}`;

  // const pageNumber = params.pageNumber

  const response = yield call(
    GetAgGridDataEVOC,
    widgetSetting,
    clientId,
    endPoint,
    undefined,
    undefined,
    undefined,
    undefined,
    lastFilter,
    undefined
  );

  params.params.successCallback(
    response.status === 204 ? [] : response.data.questionsTop,
    response.status === 204 ? 0 : response.data.questionsTop.length
  );
  yield put(
    requestQuestionsHighestAdvocaySucess({
      highestInfluenceQuestion:
        response.status === 204 ? [] : response.data.questionsTop,
      // pageParameters: queryParamsObject,
    })
  );
}
export default function* AgGridSaga() {
  yield takeLatest(GET_AG_GRID_EVOC_Data, handleSagaErrors(handleRequestAgGridData));
  yield takeLatest(
    DownloadMissions_EVOC_PDFs,
    handleSagaDownloadErrors(handleDownloadMissionsPdf)
  );
  yield takeLatest(
    RequestExperinceReportDocumentViewer_EVOC,
    handleSagaErrors(handleRequestExperinceReportDocumentViewer)
  );
  yield takeLatest(
    RequestExperinceReportDocumentDownloadAsPdf_EVOC,
    handleSagaErrors(handleRequestExperinceReportDocumentDownloadAsPdf)
  );
  yield takeLatest(
    handleRequestChangeMissionStatus_EVOC_,
    handleSagaErrors(handleRequestChangeMissionListStatus)
  );
  yield takeLatest(RequestMissionDetails_EVOC, handleRequestMissionDetails);
  yield takeLatest(RequestClientDesign_EVOC, handleRequestClientDesign);
  yield takeEvery(GET_QUESTION_LEADER_BOARD, handleRequestLeaderQuestion);
  yield takeLatest(
    GET_QUESTION_HIGHEST_ADVOCAY,
    handleRequestQuestionHihghestInfluence
  );
}
