import { GET_AG_GRID_EVOC_Data,RequestMissionDetails_EVOC,CleanMissionDetails_EVOC,
  RequestClientDesign_EVOC_Success,RequestClientDesign_EVOC,RequestMissionDetails_EVOC_Success,CleanClientDesign_EVOC,
  handleRequestChangeMissionStatus_EVOC_,GET_AG_GRID_EVOC_Data_SUCCESS,GET_AG_GRID__EVOC_Data_FAILED,DownloadMissions_EVOC_PDFs,PrintPage_EVOC,
  RequestExperinceReportDocumentDownloadAsPdf_EVOC,RequestExperinceReportDocumentViewer_EVOC,RequestExperinceReportDocumentViewer_EVOC_Success, CURRENT_PAGE } from './type';

export const requestAgGridTableDataEVOC = (params:any) => ({
    type: GET_AG_GRID_EVOC_Data,
    params,
});

export const requestAgGridTableDataEVOCSuccess = (payload:any) => (
    {
    type: GET_AG_GRID_EVOC_Data_SUCCESS,
    payload,
});
export const requestAgGridTableDataEVOCFailed = (payload:any) => ({
    type: GET_AG_GRID__EVOC_Data_FAILED,
    payload,
});
export const onChangeCurrentPage = (payload:any) => ({
  type: CURRENT_PAGE,
  payload,
});
export const downloadMissionsEVOCPdfs = (clientId:Number, clientName:any, missions:any, widgetSettings:any, languageId:Number,widgetId:Number) => ({
    type: DownloadMissions_EVOC_PDFs,
    clientId,
    clientName,
    missions,
    widgetSettings,
    languageId,
    widgetId
  });
  export const requestExperinceReportEVOCDocumentViewer = (langId:Number, clientId:Number, missionId:any, translate = false,callback:any) => ({
    type: RequestExperinceReportDocumentViewer_EVOC,
    langId,
    clientId,
    missionId,
    translate,
    callback
  });
  
  export const requestExperinceReportEVOCDocumentViewerSuccess = (payload:any) => ({
    type: RequestExperinceReportDocumentViewer_EVOC_Success,
    payload,
  });
  
  export const downloadAsPdfEVOC = (id:Number, clientId:Number, element:any, locationId:any,projectName:any,ace:any) => ({
    type: RequestExperinceReportDocumentDownloadAsPdf_EVOC,
    id,
    clientId,
    element,
    locationId,
    projectName,
    ace
  });
  export const printPageEVOC = (elementId = null, fileName = 'dashboard-export') => ({
    type: PrintPage_EVOC,
    elementId,
    fileName,
  });
  export const requestChangeMissionStatusEVOC = ( clientId:Number, userId:string, status:Number,missionList:any,callback:any) => ({
    type: handleRequestChangeMissionStatus_EVOC_,
    clientId,
    userId,
    status,
    missionList,
    callback
  });
  export const requestMissionDetailsEVOC = (clientId:Number, missionId:any,  settings:any) => ({
    type: RequestMissionDetails_EVOC,
    clientId,
    missionId,
    settings,
  });
  export const requestMissionDetailsEVOCSuccess = (payload:any) => ({
    type: RequestMissionDetails_EVOC_Success,
    payload,
  });
  export const requestClientDesignEVOC = (clientId:Number) => ({
    type: RequestClientDesign_EVOC,
    clientId,
  });
  export const requestClientDesignEVOCSuccess = (payload:any) => ({
    type: RequestClientDesign_EVOC_Success,
    payload,
  });
  export const cleanMissionDetailsEVOC = () => ({
    type: CleanMissionDetails_EVOC,
  });
  export const cleanClientDesignEVOC = () => ({
    type: CleanClientDesign_EVOC,
  });