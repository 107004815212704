/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { Segmented } from 'antd';
import Daily from './daily';
import YearlyCalendar from './YearlyCalendar';
import MonthlyCalendar from './MonthlyCalendar';
import QuarterSelect from './QuarterCalendar';
import { useSelector, useDispatch } from 'react-redux';
import { onChangeFilterModel } from 'redux/actions';
import { COMPARISON_TYPE_ENUM, PERIOD_COMPARISON_TYPES, PERIOD_TYPE_ENUM, TRANSLATIONS_KEYS_PREFIX } from '../../types';
import { findBy, removeSpecialCharacters } from 'helpers/index';

import '../../styles.scss';
import { TranslateToString } from 'pages/commons/Translate';

const Demo: React.FC<any> = (props) => {
  const dispatch = useDispatch();

  const filterModel = useSelector((state: any) => state.Filter.filterModel['period']);
  const comparisonTypeOptions = PERIOD_COMPARISON_TYPES?.map((el: any) => ({
    ...el,
    label: TranslateToString({
      id: `${TRANSLATIONS_KEYS_PREFIX.COMPARISON_TYPE}.${removeSpecialCharacters(el?.label?.toLowerCase())}`,
      defaultMessage: el?.label,
    }),
  }));
  const [options, setOptions] = useState(comparisonTypeOptions);

  useEffect(() => {
    if (props.periodType == PERIOD_TYPE_ENUM.Year) {
      setOptions(comparisonTypeOptions.filter((el: any) => el?.value !== 2));
    } else if (props.periodType == PERIOD_TYPE_ENUM.YearToDate) {
      setOptions(comparisonTypeOptions.filter((el: any) => el?.value !== 3));
    } else {
      setOptions(comparisonTypeOptions);
    }
  }, [props.periodType, comparisonTypeOptions]);

  const changeComparedPeriod = (item: any) => {
    dispatch(onChangeFilterModel('period', { ...filterModel, comparisonType: item }));
  };

  return (
    <>
      <Segmented
        className={'cxg-text'}
        options={options}
        onChange={changeComparedPeriod}
        value={findBy(options, 'value', filterModel.comparisonType, 'value')}
        style={{ fontSize: '12px', marginBottom: '15px' }}
      />
      {props.children(filterModel?.comparisonType)}
    </>
  );
};

type Props = { periodType: number };
const ComparedPeriodComponent: React.FC<Props> = ({ periodType }) => {
  const dispatch = useDispatch();
  const filterModel = useSelector((state: any) => state.Filter.filterModel['period']);

  const handleOnChange = (customFromDate: number | null, customToDate: number | null) => {
    dispatch(onChangeFilterModel('period', { ...filterModel, customFromDate, customToDate }));
  };

  return (
    <Demo periodType={periodType}>
      {(comparedPeriod: any) => {
        if (comparedPeriod == COMPARISON_TYPE_ENUM.previousYear) {
          return;
        } else if (comparedPeriod == COMPARISON_TYPE_ENUM.previousPeriod) {
          return;
        } else {
          return (
            <div>
              {periodType == PERIOD_TYPE_ENUM.Day && (
                <Daily start={filterModel.customFromDate} end={filterModel.customToDate} handleChange={handleOnChange} />
              )}
              {periodType == PERIOD_TYPE_ENUM.Month && (
                <MonthlyCalendar start={filterModel.customFromDate} end={filterModel.customToDate} handleChange={handleOnChange} />
              )}
              {periodType == PERIOD_TYPE_ENUM.Quarter && (
                <QuarterSelect start={filterModel.customFromDate} end={filterModel.customToDate} handleChange={handleOnChange} />
              )}
              {periodType == PERIOD_TYPE_ENUM.Year && (
                <YearlyCalendar start={filterModel.customFromDate} end={filterModel.customToDate} handleChange={handleOnChange} />
              )}
            </div>
          );
        }
      }}
    </Demo>
  );
};
export default ComparedPeriodComponent;
