/* eslint-disable eqeqeq */

import { PERIODS_PREFIX } from 'constants/common';
import { TranslateToString } from 'pages/commons/Translate';

export const isText = (val: any) => !!val && typeof val === 'string' && val.trim() !== '';

export const hasValues = (x: any): boolean => {
  if (!Array.isArray(x) || x?.length === 0) {
    return false;
  }
  return true;
};

export const getList = (x: any): any[] => {
  if (!Array.isArray(x) || x?.length === 0) {
    return [];
  }
  return x;
};

export const hasKeys = (x: any): boolean => {
  return !!x && x.constructor === Object && Object.keys(x).length !== 0;
};
export const isEmptyArray = (x: any) => Array.isArray(x) && x.length === 0;

export const findBy = (array: any, key = 'id', searchValue: any, property?: any, defaultReturn?: any) => {
  if (!hasValues(array)) return defaultReturn;
  const value = array.find((item: any) => item[key] == searchValue);
  if (!value) return defaultReturn;
  if (!property) {
    return value;
  }
  return value[property] || defaultReturn;
};

export const upsertBy = (source: any, data: any, key: string) => {
  if (!hasValues(source)) {
    return [data];
  }
  if (findBy(source, key, data[key])) {
    const result = source.map((item: any) => {
      if (item[key] == data[key]) {
        return data;
      }
      return item;
    });
    return result;
  }
  return [...source, data];
};

export const mapBy = (data: any, key = 'id') => {
  if (!hasValues(data)) return [];
  return data.map((d: any) => (hasKeys(d) ? d[key] : d));
};

export const updateTreeProperty = (array: any, property = 'id', newValue: any) => {
  if (!hasValues(array)) {
    return [];
  }
  return array.reduce((acc: any, curr: any) => {
    if (hasValues(curr.children)) {
      acc = acc.concat({
        ...curr,
        [property]: newValue,
        children: updateTreeProperty(curr.children, property, newValue),
      });
    } else {
      acc = acc.concat({ ...curr, [property]: newValue });
    }
    return acc;
  }, []);
};

export const findByRecursive = (array: any, key = 'id', searchValue: any, property?: any, defaultReturn?: any): any => {
  if (!hasValues(array)) return defaultReturn;

  for (const item of array) {
    if (item[key] === searchValue) {
      if (!property) {
        return item;
      } else {
        return item[property] || defaultReturn;
      }
    } else if (hasValues(item.children)) {
      const result = findByRecursive(item.children, key, searchValue, property, defaultReturn);
      if (result !== defaultReturn) {
        return result;
      }
    }
  }

  return defaultReturn;
};

export const reduceByRecursive = (array: any, key = 'id', searchValue: any, property?: any, defaultReturn?: any): any => {
  if (!hasValues(array)) return [];

  const result = array.reduce((acc: any, curr: any) => {
    if (curr[key] == searchValue) {
      return acc.concat(curr);
    }
    if (curr[key] != searchValue && hasValues(curr.children)) {
      return acc.concat(reduceByRecursive(curr.children, key, searchValue, property, defaultReturn));
    }
    return acc;
  }, []);

  return result;
};

export function reduceObjectByKey(obj: any, key: string) {
  if (!hasKeys(obj)) return {};
  return Object.keys(obj).reduce((acc, curr) => {
    return Object.assign(acc, ...(hasValues(mapBy(obj[curr], key)) ? [{ [curr]: mapBy(obj[curr], key) }] : [{}]));
  }, {});
}

export function reduceNestedObject(obj: any, keys: string[]) {
  if (!hasKeys(obj)) return {};
  return Object.keys(obj).reduce((acc, curr) => {
    return Object.assign(acc, ...(hasValues(deepMapBy(obj[curr], keys)) ? [{ [curr]: deepMapBy(obj[curr], keys) }] : [{}]));
  }, {});
}

export const deepMapBy = (data: any, keys: string[]) => {
  if (!hasValues(data)) return [];
  return data.map((d: any) => (hasKeys(d) ? getTreeKeysValue(d, keys) : d));
};

export const getTreeKeysValue = (obj: any, keys: string[], defaultReturn: any = undefined): string => {
  if (!hasKeys(obj) || !hasValues(keys)) return defaultReturn;
  let result = obj;
  for (const key of keys) {
    if (key in result) {
      result = result[key];
    } else {
      result = defaultReturn;
      break;
    }
  }
  return result;
};

export const groupBy = (list: any, ref: string[], property: string) => {
  if (!hasValues(list) || !hasValues(ref)) return {};
  return ref.reduce((acc, curr) => {
    return Object.assign(acc, { [curr]: list.filter((el: any) => el[property] == curr) });
  }, {});
};

export const removeDuplicatesBy = (list: any, key: string) => {
  if (!hasValues(list)) {
    return [];
  }
  return list.reduce((acc: any, curr: any) => {
    if (!findBy(acc, key, curr[key])) {
      return acc.concat(curr);
    }
    return acc;
  }, []);
};

export const reduceTreeBy = (tree: any[], key = 'id', value: any, children = 'children') => {
  if (!hasValues(tree)) {
    return [];
  }
  const result: any[] = tree.reduce((acc: any, curr: any) => {
    if (curr[key] === value) {
      return acc.concat(curr);
    }
    return acc.concat(reduceTreeBy(curr[children], key, value, children));
  }, []);

  return result;
};

export const sortBy = (source: any, ref: string | null, asc = true) => {
  if (!hasValues(source)) return [];
  return source.sort((a: any, b: any) => {
    let fa = a;
    let fb = b;
    if (ref) {
      fa = typeof a[ref] == 'string' ? a[ref].toLowerCase() : a[ref];
      fb = typeof b[ref] == 'string' ? b[ref].toLowerCase() : b[ref];
    }
    if (fa < fb) {
      return asc ? -1 : 1;
    }
    if (fa > fb) {
      return asc ? 1 : -1;
    }
    return 0;
  });
};

export const removeSpecialCharacters = (value: any) => (isText(value) ? value.replace(/[^a-zA-Z0-9]/g, '') : value);

export function tanslatePeriodPrefix(inputString: string, keyPrefix = 'generic.months'): string {
  if (!isText(inputString)) {
    return inputString;
  }
  const monthsToTranslate = getMonthsToTranslate(inputString);
  return translateMonthsInString(inputString, monthsToTranslate, keyPrefix);
}
function getMonthsToTranslate(inputString: string): string[] {
  const targetMonths = PERIODS_PREFIX.filter((prefix: string) => inputString.includes(prefix));
  return sortBy(targetMonths, null, false);
}

function translateMonthsInString(inputString: string, months: string[], keyPrefix: string): string {
  return months.reduce((result, month: any) => {
    const regex = new RegExp(month, 'g');
    const translatedMonth = TranslateToString({
      id: `${keyPrefix}.${month.toLowerCase()}`,
      defaultMessage: month,
    });
    return result.replace(regex, translatedMonth);
  }, inputString);
}

export const isInteger = (value: any) => {
  return Number.isInteger(value);
};

/**
 * Renames properties in an array of objects.
 *
 * @param array - The array of objects.
 * @param properties - The properties to rename.
 * @param newNames - The new names for the properties.
 * @returns The array of objects with renamed properties.
 */
export const renameProperties = <T extends Record<string, any>>(
  array: T[] = [],
  properties: string[] = ['id', 'name'],
  newNames: string[] = ['value', 'label']
): T[] => {
  if (!hasValues(array)) return [];
  const copy = array.filter((e) => hasKeys(e)).slice();
  let source = properties || [];
  let target = newNames || [];

  if (!hasValues(source) || !hasValues(target) || source.length !== target.length) {
    return copy;
  }
  return copy.reduce(
    (acc, curr) =>
      acc.concat(
        Object.keys(curr).reduce((s: any, e: any) => {
          const pos = source.indexOf(e);
          if (pos >= 0) {
            return Object.assign(s, { [target[pos]]: curr[e] });
          }
          return Object.assign(s, { [e]: curr[e] });
        }, {})
      ),
    []
  );
};

/**
 * Filters an array based on a given condition.
 *
 * @param {T[]} array - The array to filter.
 * @param {(element: T) => boolean} func - The condition to filter by.
 * @return {T[]} The filtered array.
 */
export const filterBy = <T>(array: T[], func: (element: T) => boolean): T[] => {
  if (!hasValues(array)) {
    return [];
  }
  if (typeof func !== 'function') {
    return array;
  }
  return array.filter((e) => func(e));
};

export function getKeyValueOrDefault(obj: any, key: string, defaultReturn: any = null): any {
  if (!hasKeys(obj)) {
    return defaultReturn;
  }
  return obj.hasOwnProperty(key) ? obj[key] : defaultReturn;
}

export function mergeObjectsWithValues(obj1: any, obj2: any) {
  if (!hasKeys(obj1) && !hasKeys(obj2)) {
    return {};
  }
  if (!hasKeys(obj1) && hasKeys(obj2)) {
    return obj2;
  }
  if (!hasKeys(obj2) && hasKeys(obj1)) {
    return obj1;
  }
  const allKeys = Array.from(new Set([...Object.keys(obj1), ...Object.keys(obj2)]));
  const mergedObject: { [key: string]: any } = {};
  allKeys.forEach((key) => {
    if (hasValues(obj1[key]) && hasValues(obj2[key])) {
      mergedObject[key] = Array.from(new Set([...obj1[key].concat(obj2[key])]));
    } else if (obj1[key]) {
      mergedObject[key] = obj1[key];
    } else if (obj2[key]) {
      mergedObject[key] = obj2[key];
    }
  });
  return mergedObject;
}

/**
 * Removes specified keys from an object.
 *
 * @param {Record<string, any>} object - The object from which to remove keys.
 * @param {string[]} [keys=[]] - The keys to remove from the object. Defaults to an empty array.
 * @return {Record<string, any>} - A new object with the specified keys removed.
 */
export const removeByKeys = (object: Record<string, any>, keys = []) => {
  const copy = { ...object };
  if (!hasKeys(copy)) return {};
  if (!hasValues(keys)) return copy;
  keys.forEach((key) => {
    if (hasKey(copy, key)) {
      delete copy[key];
    }
  });
  return copy;
};

/**
 * Filters an object by keeping only the properties specified in the given array of keys.
 *
 * @param {Record<string, any>} object - The object to filter.
 * @param {string[]} keys - The array of keys to keep in the filtered object.
 * @return {Record<string, any>} - The filtered object.
 */
export const filterObjectByKeys = (object: Record<string, any>, keys: string[]): Record<string, any> => {
  const copy: Record<string, any> = { ...object };
  if (!hasKeys(copy)) return {};
  if (!hasValues(keys)) return copy;
  Object.keys(copy).forEach((key: string) => {
    if (!keys.includes(key)) {
      delete copy[key];
    }
  });
  return copy;
};

/**
+ * Checks if an object has a specified key.
+ *
+ * @param {any} object - The object to check for the key.
+ * @param {string} [key='id'] - The key to check for. Defaults to 'id'.
+ * @return {boolean} - Returns true if the object has the specified key, otherwise false.
+ */
export const hasKey = (object: any, key = 'id') => hasKeys(object) && object.hasOwnProperty(key);
