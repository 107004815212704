import React from 'react';
import { LicenseManager } from 'ag-grid-enterprise';
import Routing from 'routes/Routing';
import './global.scss';
import Loader from 'features/Loader/Loader';
import Notifications from 'pages/commons/notifications/notifications';

const App: React.FC = () => {
  const CXG_ENV_AGGRID_KEY =
    'CompanyName=Customer Experience Group Limited,LicensedApplication=Customer Experience Group Limited,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-026311,ExpiryDate=14_March_2023_[v2]_MTY3ODc1MjAwMDAwMA==a74bb6ea98bd64737e81c1e644a7b448';
  LicenseManager.setLicenseKey(CXG_ENV_AGGRID_KEY);

  return (
    <>
     <Notifications/>
      <Loader />
      <Routing />
    </>
  );
};

export default App;
