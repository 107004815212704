import moment from "moment";
import { TranslateToString } from 'pages/commons/Translate';
import CustomHeaderComponent from './customHeaderComponent'
import Ellipse from "../../../../assets/images/Ellipse.png";

 export const getGridTaskOptions = (
  customDefaultColDefOptions = {},
  pageSize,
  setting,
  customPropertiesColumns,
  readAllMissions,
  checkAllMissions,
  readUnreadBtnText,
  checkUncheckBtnText,
  ClickedRow
) => {
 const customeproperties= customPropertiesColumns &&
  customPropertiesColumns.map((c) => ({
    headerName: TranslateToString({id:`generic.components.charts.dynamic.${c.field.replace(/\s+/g, ".").toLowerCase()}`,defaultMessage:c.field}),
    field:c.field,
    wrapText: true, 
  }))
  const checkedrependedId=()=>{
    const isfind=  setting.respondentProperties.find(el=> el == 0)
  if(isfind!==undefined)
  return true
  else return false
    }
    const isrependedid=checkedrependedId()
  return (
  
   {
  defaultColDef: {
    ...customDefaultColDefOptions,
  
    cellStyle: (params) => {
      if (params.data.isRead === false && ClickedRow.indexOf(params.data.id) <0) {
        //mark cell style dynamic
        return {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#003D4C",
          fontSize: "12px",
          borderRightColor: "#BB6125",
          fontWeight: "bold",
          lineHeight: "15px",
          whiteSpace:'normal'
        };
      } else {
        return {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#003D4C",
          fontSize: "12px",
          borderRightColor: "#BB6125",
          fontWeight: "400",
          lineHeight: "15px",
          whiteSpace:'normal'

        };
      }
    },

    resizable: true,
    flex: 1,
    filter: true,
    sortable: true,
    unSortIcon:true,
    menuTabs: [],
    cellClass: "cell-wrap-text",
    autoHeight: true,
    rowData: null,
    rowHeight: 50,
    domLayout: 'autoHeight',
 
  },
  columnDefs: [
    {
      headerName: TranslateToString({
        id: 'generic.survey',
      }),
      field: "survey",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      enableRowGroup: true,
      wrapText: true,
      minWidth: 400,
      maxWidth: 600,
      // cellRenderer: "SurveyStatusCellRenderer",
      cellRenderer: (params) => {
        if (params.data.isRead === false && ClickedRow.indexOf(params.data.id) <0) {
          return <div className="surveyStatus">
            {params.data.isRead === false ? <img src={Ellipse} /> : <div></div>}
            {params.data.survey ? params.data.survey : "--"}
          </div>;
        } else {
          return <div className="surveyStatus">
            {params.data.survey ? params.data.survey : "--"}
          </div>;
        }
      },
      // cellRendererParams: {
      //   checkbox: true,
      // },

      cellStyle: (params) => {
        if (params.data.isRead === false && ClickedRow.indexOf(params.data.id) <0) {
          //mark cell style dynamic
          return {
            display: "flex",
            // alignItems: "center",
            // justifyContent: "flex-start",
            gap: "20px",
            color: "#003D4C",
            fontSize: "12px",
            borderRightColor: "#BB6125",
            fontWeight: "bold",
            lineHeight: "15px",
            whiteSpace:'normal'

          };
        } else {
          return {
            display: "flex",
            // alignItems: "center",
            // justifyContent: "flex-start",
            color: "#003D4C",
            fontSize: "12px",
            borderRightColor: "#BB6125",
            fontWeight: "400",
            lineHeight: "15px",
            whiteSpace:'normal'

            
          };
        }
      },
        headerComponentFramework: (params) => (
        <CustomHeaderComponent {...params} 
        // api={params.api} 
        readAllMissions={readAllMissions} checkAllMissions={checkAllMissions} readUnreadBtnText={readUnreadBtnText}
        checkUncheckBtnText={checkUncheckBtnText}
          />
      ),
    },
   
    {
      headerName: TranslateToString({
        id: 'generic.missionid',
      }),
      field: "id",
      hide: setting.surveyDetails.find(el=> el == 1) ? false : true,
      wrapText: true,
    },
    {
      headerName: TranslateToString({
        id: 'generic.location.name',
      }),
      field: "locationName",
      wrapText: true,
      hide: setting.surveyDetails.find(el=> el == 2) ? false : true,

    },
    {
      headerName: TranslateToString({
        id: 'generic.locationaddress',
      }),
      field: "locationAddress",
      hide: setting.surveyDetails.find(el=> el == 3) ? false : true,
      wrapText: true,
    },
    {
      headerName: TranslateToString({
        id: 'generic.assigneddate',
      }),
      field: "assignedDate",
      cellRenderer: "AssignedDateCellRenderer",
      hide: setting.surveyDetails.find(el=> el == 4) ? false : true,
      wrapText: true,
     
    },
    {
      headerName: TranslateToString({
        id: 'generic.visit.date',
      }),
      field: "visitDate",
      wrapText: true,
      cellRenderer: "SurveyVisitDateCellRenderer",  
      hide: setting.surveyDetails.find(el=> el == 5) ? false : true,
    },
  
    {
      headerName: TranslateToString({
        id: 'generic.ace',
      }),
      field: "aceAnswer",
      hide: setting.businessTypeAnswers.find(el=> el == 1) ? false : true,
      wrapText: true,

      
    },
    {
      headerName: TranslateToString({
        id: 'generic.question.business.types.purchase',
      }),
      field: "purchaseAnswer",
      hide: setting.businessTypeAnswers.find(el=> el == 6) ? false : true,
      wrapText: true,


    },
    {
      headerName: TranslateToString({
        id: 'generic.question.business.types.businessaxis',
      }),
      field: "businessAxisAnswer",
      hide: setting.businessTypeAnswers.find(el=> el == 7) ? false : true,
      wrapText: true,


    },
    {
      headerName: TranslateToString({
        id: 'generic.question.business.types.traffic',
      }),
      field: "trafficAnswer",
      hide: setting.businessTypeAnswers.find(el=> el == 8) ? false : true,
      wrapText: true,


    },
    {
      headerName: TranslateToString({
        id: 'generic.customproperties.brand',
      }),
      field: "Brand",
      hide: setting.businessTypeAnswers.find(el=> el == 2) ? false : true,
      wrapText: true,


    },
    {
      headerName: TranslateToString({
        id: 'generic.question.business.types.benchmark',
      }),
      field: "benchmarkAnswer",
      hide: setting.businessTypeAnswers.find(el=> el == 3) ? false : true,
      wrapText: true,


    },
    {
      headerName: TranslateToString({
        id: 'generic.question.business.types.audit',
      }),
      field: "auditAnswer",
      hide: setting.businessTypeAnswers.find(el=> el == 11) ? false : true,
      wrapText: true,


    },
    {
      headerName: TranslateToString({
        id: 'pages.projects.scenario.form.nationality',
      }),
      field: "nationalityAnswer",
      hide: setting.businessTypeAnswers.find(el=> el == 9) ? false : true,
      wrapText: true,


    }, {
      headerName: TranslateToString({
        id: 'pages.reporting.voclt.title.age',
      }),
      field: "ageAnswer",
      hide: setting.businessTypeAnswers.find(el=> el == 10) ? false : true,
      wrapText: true,


    }, {
      headerName: TranslateToString({
        id: 'pages.reporting.vocqr.title.store',
      }),
      field: "storeQuestionAnswer",
      hide: setting.businessTypeAnswers.find(el=> el == 12) ? false : true,
      wrapText: true,


    }, {
      headerName: TranslateToString({
        id: 'generic.question.business.types.gender',
      }),
      field: "genderAnswer",
      hide: setting.businessTypeAnswers.find(el=> el == 13) ? false : true,
      wrapText: true,


    },
    {
      headerName: TranslateToString({
        id: 'generic.components.charts.label.nps',
      }),
      field: "npsAnswer",
      hide: setting.businessTypeAnswers.find(el=> el == 14) ? false : true,
      wrapText: true,


    },{
      headerName: TranslateToString({
        id: 'generic.be.host',
      }),
      field: "beHostAnswer",
      hide: setting.businessTypeAnswers.find(el=> el == 15) ? false : true,
      wrapText: true,

    },{
      headerName: TranslateToString({
        id: 'generic.reveal',
      }),
      field: "revealAnswer",
      hide: setting.businessTypeAnswers.find(el=> el == 16) ? false : true,
      wrapText: true,

    },
    {
      headerName: TranslateToString({
        id: 'generic.build.connection',
      }),
      field: "buildConnectionAnswer",
      hide: setting.businessTypeAnswers.find(el=> el == 17) ? false : true,
      wrapText: true,

    },{
      headerName: TranslateToString({
        id: 'generic.long.term',
      }),
      field: "longTermAnswer",
      hide: setting.businessTypeAnswers.find(el=> el == 18) ? false : true,
      wrapText: true,

    },{
      headerName:  TranslateToString({
        id: 'generic.inspire',
      }),
      field: "inspireAnswer",
      hide: setting.businessTypeAnswers.find(el=> el == 19) ? false : true,
      wrapText: true,

    },
    
    {
      headerName: TranslateToString({
        id: 'generic.surveyScore',
      }),
      field: "surveyScore",
      

      hide: setting.showSurveyScore == true ? false : true,
      cellStyle: (params) => {
        if (params.data.isRead === false && ClickedRow.indexOf(params.data.id) <0) {
          //mark cell style dynamic
          return {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#003D4C",
            fontSize: "12px",
            borderRightColor: "#BB6125",
            fontWeight: "bold",
          };
        } else {
          return {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#003D4C",
            fontSize: "12px",
            borderRightColor: "#BB6125",
            fontWeight: "400",
          };
        }
      },
    },
   /**  {
      headerName: TranslateToString({
        id: 'generic.area',
      }),
      field: "Area",
      wrapText: true,
    },
    {
      headerName: TranslateToString({
        id: 'generic.country.bis',
      }),
      field: "Country bis",
      wrapText: true,
    },
    {
      headerName: TranslateToString({
        id: 'generic.pos.type',
      }),
      field: "POS Type",
      wrapText: true,
    },
    {
      headerName: TranslateToString({
        id: 'generic.city.bis',
      }),
      field: "City bis",
      wrapText: true,
    },
    {
      headerName: TranslateToString({
        id: 'generic.boutique.universes',
      }),
      field: "Boutique Universes",
      wrapText: true,
    },
    {
      headerName: TranslateToString({
        id: 'generic.cluster',
      }),
      field: "Cluster",
      wrapText: true,
    },*/
...customeproperties,
{ 
  headerName:  TranslateToString({
     id: 'generic.respondentId',
   }),
   hide: isrependedid==true?false:true,
   field: "respondentId",
   wrapText: true,
   },
   { 
    headerName:  TranslateToString({
       id: 'generic.respondentage',
     }),
     hide: setting.respondentProperties.find(el=> el == 1) ? false : true,
     field: "respondentAge",
     wrapText: true,
     },
     { 
      headerName:  TranslateToString({
         id: 'generic.respondentgender',
       }),
       hide: setting.respondentProperties.find(el=> el == 2) ? false : true,
       field: "respondentGender",
       wrapText: true,
       },
       { 
        headerName:  TranslateToString({
           id: 'generic.respondentnationality',
         }),
         hide: setting.respondentProperties.find(el=> el == 3) ? false : true,
         field: "respondentNationality",
         wrapText: true,
         },
  ],
})};

export const PERIODS = {
    DAY: 'day',
    MONTH: 'month',
    YEAR: 'year',
    YEAR_TO_DATE: 'yearToDate',
  };
export const formatDate = (dateObject, periodType) => {
    const date = moment(dateObject).format('DD');
    const month = moment(dateObject).format('MM');
    const year = moment(dateObject).format('YYYY');
  
    switch (periodType) {
      case PERIODS.DAY: {
        return `${year}-${month.padStart(2, '0')}-${date.padStart(2, '0')}`;
      }
      case PERIODS.MONTH: {
        return `${year}-${month.padStart(2, '0')}`;
      }
      case PERIODS.YEAR: {
        return `${year}`;
      }
      case PERIODS.YEAR_TO_DATE: {
        return `${year}-${month.padStart(2, '0')}-${date.padStart(2, '0')}`;
      }
      default: {
        return `${year}-${month.padStart(2, '0')}-${date.padStart(2, '0')}`;
      }
    }
  };
export const getMissionExportFileName = (missionId, surveyName, aceAnswer,locationName) => {
     const dateStamp = formatDate(new Date());
     if (aceAnswer)
    //  return `SurveyExplorer_${surveyName}_${missionId}_${aceAnswer}_${dateStamp}`;
     return `${surveyName}_${locationName}_${missionId}_${aceAnswer}_${dateStamp}`;
     else 
     return `${surveyName}_${locationName}_${missionId}_${dateStamp}`;

    };
  export const openBlobDataLink = async(response, download = false, documentName = '') => {
  
    //  const file = await new Blob([response.data], {type: 'application/pdf'});
    const blobURL = await window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
  
    link.style.display = 'none';
    link.href = blobURL;
    if (download) {
      link.setAttribute('download', documentName);
    }
    document.body.appendChild(link);
    link.click();
  
    setTimeout(() => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobURL);
    }, 100);
  };

  export const MISSION_PREVIEW_TYPES = {
    AUTOMATED: 1,
    SIMPLE: 2,
    EXPERIENCE_REPORT: 3,
  };
  export const getMarkdownText = (markdown) => {
    const rawMarkup = markdown; // marked(markdown, { sanitize: true, breaks: true });
    return { __html: rawMarkup };
  };
  export const  getAceQuestionBackgroundColor=(ace)=> {
    switch (ace) {
      case 1:
        return 'ambassador-background-color';
      case 2:
        return 'advocateds-background-color';
      case 3:
        return 'unimpressed-background-color';
      case 4:
        return 'disappointed-background-color';
      case 5:
        return 'illmouther-background-color';
      default:
        return 'default-background-color';
    }
  }
  export const SURVEY_ITEM_TYPES = {
    SECTION: 'section',
    QUESTION: 'question',
    COACHING_QUESTION: 'coaching question'
  };
  export const parseJSONObject = (jsonObject) => {

    try {
        let parsedObject = JSON.parse(jsonObject)
        return parsedObject
    } catch (e) {
        return undefined
    }
}
export const QUESTION_BUSSINES_TYPES = [
  {
    "value": 0,
    "label": "Regular",
    "stringValue": "Regular"
  },
  {
    "value": 1,
    "label": "ACE",
    "stringValue": "ACE"
  },
  {
    "value": 2,
    "label": "Brand",
    "stringValue": "Brand"
  },
  {
    "value": 3,
    "label": "Benchmark",
    "stringValue": "Benchmark"
  },
  {
    "value": 4,
    "label": "Associate Attribute",
    "stringValue": "AssociateAttribute"
  },
  {
    "value": 5,
    "label": "Customer Satisfaction",
    "stringValue": "CustomerSatisfaction"
  },
  {
    "value": 6,
    "label": "Purchase",
    "stringValue": "Purchase"
  },
  {
    "value": 7,
    "label": "Business Axis",
    "stringValue": "BusinessAxis"
  },
  {
    "value": 8,
    "label": "Traffic",
    "stringValue": "Traffic"
  },
  {
    "value": 9,
    "label": "Nationality",
    "stringValue": "Nationality"
  },
  {
    "value": 10,
    "label": "Age",
    "stringValue": "Age"
  },
  {
    "value": 11,
    "label": "Audit",
    "stringValue": "Audit"
  },
  {
    "value": 12,
    "label": "Store Question",
    "stringValue": "StoreQuestion"
  },
  {
    "value": 13,
    "label": "Gender",
    "stringValue": "Gender"
  },
  {
    "value": 14,
    "label": "NPS",
    "stringValue": "NPS"
  },
  {
    "value": 15,
    "label": "Be a host",
    "stringValue": "BeHost"
  },
  {
    "value": 16,
    "label": "Reveal",
    "stringValue": "Reveal"
  },
  {
    "value": 17,
    "label": "Build Connection",
    "stringValue": "BuildConnection"
  },
  {
    "value": 18,
    "label": "Long Term",
    "stringValue": "LongTerm"
  },
  {
    "value": 19,
    "label": "Inspire",
    "stringValue": "Inspire"
  }
]
export const SurveyDetail = [
  [
    {
      "value": 1,
      "label": "Mission Id",
      "stringValue": "id"
    },
    {
      "value": 2,
      "label": "Location ID",
      "stringValue": "locationId"
    },
    {
      "value": 3,
      "label": "Location Address",
      "stringValue": "locationAddress"
    },
    {
      "value": 4,
      "label": "Assigned Date",
      "stringValue": "assignedDate"
    },
    {
      "value": 5,
      "label": "Visit Date",
      "stringValue": "visitDate"
    }
  ]
]