import { GET_ALLQUESTION_DISTRIBUTION,
    GET_ALLQUESTION_DISTRIBUTION_SUCCESS,
    GET_ALLQUESTION_DISTRIBUTION_FAILED,
    GET_ANSWER_DISTRIBUTION,
    GET_ANSWER_DISTRIBUTION_SUCCESS,
    GET_ANSWER_DISTRIBUTION_FAILED
} from './type';
export const requestAllQuestionDistribution = (params:any) => ({
    type: GET_ALLQUESTION_DISTRIBUTION,
    payload: params,
});

export const requestAllQuestionDistributionDataSuccess = (payload:any) => (
    {
    type: GET_ALLQUESTION_DISTRIBUTION_SUCCESS,
    payload,
});
export const requestAllQuestionDistributionDataFailed = (payload:any) => ({
    type: GET_ALLQUESTION_DISTRIBUTION_FAILED,
    payload,
});
export const requestAnswerDistribution = (params:any) => (

    {
    type: GET_ANSWER_DISTRIBUTION,
    payload: params,
});

export const requestAnswerDistributionDataSuccess = (payload:any) => (

    {
    type: GET_ANSWER_DISTRIBUTION_SUCCESS,
    payload,
});
export const requestAnswerDistributionDataFailed = (payload:any) => ({
    type: GET_ANSWER_DISTRIBUTION_FAILED,
    payload,
});