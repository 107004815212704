import React, { useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Segmented, Space, ConfigProvider } from 'antd';
import moment from 'moment';
import { onChangeFilterModel } from 'redux/actions';
import { convertToTimestamp, getPeriodOptionType, periodOptions } from '../../utils';
import {
  FILTER_BY_ENUM,
  FILTER_BY_TYPES,
  GLOBAL_FILTER_TRANSLATIONS_PREFIX,
  PERIOD_TYPE_ENUM,
  TRANSLATIONS_KEYS_PREFIX,
} from '../../types';
import { findBy } from 'helpers/index';
import '../../styles.scss';
import { TranslateToString } from 'pages/commons/Translate';

const Demo: React.FC<any> = ({ filterSettings, ...props }) => {
  const dispatch = useDispatch();

  const periodModel = useSelector((state: any) => state.Filter.filterModel['period']);

  const { filterBy, hasYTDOption, hasPeriodOptions, type } = periodModel || {};
  const { options: periodFilterOptions } = filterSettings;
  const previousPeriodTypeRef = useRef(
    type === PERIOD_TYPE_ENUM.YearToDate ? findBy(periodOptions, 'value', periodFilterOptions?.[0], 'type', 0) : type
  );

  const changeType = (item: any) => {
    if (item === FILTER_BY_ENUM.YTD) {
      previousPeriodTypeRef.current = type;
    }
    dispatch(
      onChangeFilterModel('period', {
        ...periodModel,
        filterBy: findBy(FILTER_BY_TYPES, 'value', item, 'value'),
        ...(item === FILTER_BY_ENUM.YTD
          ? {
              fromDate: convertToTimestamp(moment().startOf('year')),
              toDate: convertToTimestamp(moment()),
              type: PERIOD_TYPE_ENUM.YearToDate,
            }
          : {
              type: previousPeriodTypeRef.current,
            }),
      })
    );
  };

  const changeFilterByPeriodType = (item: any) => {
    dispatch(
      onChangeFilterModel('period', {
        ...periodModel,
        type: getPeriodOptionType(item),
      })
    );
  };

  const SegmentOptions: any[] = useMemo(() => {
    let tab: any[] = [];
    if (hasPeriodOptions) {
      tab = [
        ...tab,
        {
          ...findBy(FILTER_BY_TYPES, 'value', 1),
          label: TranslateToString({ id: `${GLOBAL_FILTER_TRANSLATIONS_PREFIX}.filterby.period` }),
        },
      ];
    }
    if (hasYTDOption) {
      return [
        ...tab,
        { ...findBy(FILTER_BY_TYPES, 'value', 2), label: TranslateToString({ id: `${GLOBAL_FILTER_TRANSLATIONS_PREFIX}.filterby.ytd` }) },
      ];
    }
    return tab;
  }, [hasPeriodOptions, hasYTDOption]);

  const options = periodOptions
    .filter((p: any) => periodFilterOptions.includes(p.value))
    ?.map((el: any) => ({
      ...el,
      label: TranslateToString({ id: `${TRANSLATIONS_KEYS_PREFIX.PERIOD_OPTIONS}.${el.label?.toLowerCase()}`, defaultMessage: el?.label }),
    }));

  return (
    <div className="date-filter">
      <Space size={20} direction="vertical" style={{ display: 'flex' }}>
        {hasYTDOption && (
          <div className="type">
            <ConfigProvider
              theme={{
                components: {
                  Segmented: {
                    itemSelectedBg: '#9DD3C9',
                  },
                },
              }}>
              <Segmented className={'type cxg-text'} options={SegmentOptions} onChange={changeType} value={filterBy} key={filterBy} />
            </ConfigProvider>
          </div>
        )}
        {filterBy !== FILTER_BY_ENUM.YTD && hasPeriodOptions && (
          <Segmented
            className={'cxg-text'}
            options={options}
            onChange={changeFilterByPeriodType}
            key={findBy(periodOptions, 'type', type, 'value', '')}
            value={findBy(periodOptions, 'type', type, 'value', '')}
          />
        )}
        {props.children(filterBy, type)}
      </Space>
    </div>
  );
};

export default Demo;
