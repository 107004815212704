export const GET_AG_GRID_EVOC_Data = "GET_AG_GRID_EVOC_Data";
export const GET_AG_GRID_EVOC_Data_SUCCESS = "GET_AG_GRID_EVOC_Data_SUCCESS";
export const GET_AG_GRID__EVOC_Data_FAILED = "GET_AG_GRID__EVOC_Data_FAILED";
export const DownloadMissions_EVOC_PDFs = "DownloadMissions_EVOC_PDFs";
export const CURRENT_PAGE="CURRENT_PAGE"
export const RequestExperinceReportDocumentViewer_EVOC =
  "RequestExperinceReportDocumentViewer_EVOC";
export const RequestExperinceReportDocumentViewer_EVOC_Success =
  "RequestExperinceReportDocumentViewer_EVOC_Success";
export const RequestExperinceReportDocumentDownloadAsPdf_EVOC =
  "RequestExperinceReportDocumentDownloadAsPdf_EVOC";
export const handleRequestChangeMissionStatus_EVOC_ =
  "handleRequestChangeMissionStatus_EVOC_";
export const RequestMissionDetails_EVOC= "RequestMissionDetails_EVOC";
export const RequestMissionDetails_EVOC_Success = "RequestMissionDetails_EVOC_Success";
export const RequestClientDesign_EVOC= "RequestClientDesign_EVOC";
export const RequestClientDesign_EVOC_Success = "RequestClientDesign_EVOC_Success";
export const CleanMissionDetails_EVOC = "CleanMissionDetails_EVOC";
export const CleanClientDesign_EVOC = " CleanClientDesign_EVOC";
export const PrintPage_EVOC = "cxg/pages/reporting/PRINTEVOC"

export const ERT_HEAD =
  '<style type="text/css">html {position: relative;  min-height: 100%;text-align: left;  }*,*::after,*::before {box-sizing: border-box }  body {font-size: 14px;line-height: 1.5;color: #575757;background-color: #fff;margin: 0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing: grayscale}td,th{padding: 0;}  p {margin-bottom: 1rem; margin-top: 0px;} p + p {margin-top: 8px;}</style>';
