import { ActionTypes } from './types';

export const defaultState = {
  isAuth: false,
  IOFSList: [],
  attachmentsList: [],
  posAttachments: [],
  scenarioAttachments: [],
  currentIof: {},
  workField: {},
  generalInfos: {},
  posInfos: [],
  pmInfos: [],
  analysisInfos: {},
  evaluatorInfos: {},
  scenarios: [],
  selectedScenarios: {},
  selectedPos: {},
};
function IOFSManagementReducer(state = defaultState, action:any) {
  switch (action.type) {
    case ActionTypes.RequestIOFSAccessSuccess:
      return {
        ...state,
        isAuth: true,
        user: { ...action.payload },
      };
    case ActionTypes.RequestIOFSListDataSuccess:
      return {
        ...state,
        IOFSList: action.payload,
      };
    case ActionTypes.requestAttachmentSuccess:
      return {
        ...state,
        attachmentsList: action.payload,
      };
    case ActionTypes.requestFieldWorkSuccess:
      return {
        ...state,
        workField: action.payload,
      };
    case ActionTypes.requestIofInfosSuccess:
      return {
        ...state,
        currentIof: action.payload,
      };
    case ActionTypes.requestIofPosSuccess:
      return {
        ...state,
        posInfos: action.payload,
      };
    case ActionTypes.requestIofEvaluatorSuccess:
      return {
        ...state,
        evaluatorInfos: action.payload,
      };
    case ActionTypes.requestIofAnalysisSuccess:
      return {
        ...state,
        analysisInfos: action.payload,
      };
    case ActionTypes.requestIofScenarioSuccess:
      return {
        ...state,
        scenarios: action.payload,
      };
    case ActionTypes.requestIofScenarioByIdSuccess:
      return {
        ...state,
        selectedScenarios: action.payload,
      };
    case ActionTypes.requestIofPmSuccess:
      return {
        ...state,
        pmInfos: action.payload,
      };
    case ActionTypes.requestIofPosByIdSuccess:
      return {
        ...state,
        selectedPos: action.payload,
      };
    case ActionTypes.requestScenarioAttachmentSuccess:
      return {
        ...state,
        scenarioAttachments: action.payload,
      };
    case ActionTypes.requestPosAttachmentSuccess:
      return {
        ...state,
        posAttachments: action.payload,
      };
    default:
      return state;
  }
}

export default IOFSManagementReducer;
