type Props={list:any[],path:string, keys:any[]}
export function findFirstPageItem({list,path, keys}:Props){
let page:any=list[0]
let newPath=path==""?`${page.name}`:`${path}/${page.name}`
let keyList=[`${page.id}`,...keys]
let rest:any={}
if(page.children.lenth>0){

   rest =findFirstPageItem({list:page.children, path:newPath,keys:keyList})
}
return {page:page, path:newPath,keys:keyList}
}



