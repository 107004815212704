import { Navigate, useLocation } from 'react-router-dom';
import { isUserAuthenticated } from 'helpers/authUtils';
import { getClientComeFrom } from 'pages/Auth/saga';

interface PrivacyHandlerProps {
  Component: React.FC<any>;
}

function PrivacyHandler({ Component }: PrivacyHandlerProps) {
  const location = useLocation();
  const isAuthenticated: boolean = isUserAuthenticated();
  const path = getClientComeFrom(); 
  console.log("redirect from here ?", `/login/${path}` );
  if (!isAuthenticated) {
    return <Navigate to={`/login/${path}`} state={{ from: location }} replace />;
  }

  return <Component />;
}

export default PrivacyHandler;
