import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { notification as antdNotification, Button } from "antd";
import {ReactComponent as Success} from "assets/images/successNotif.svg";
import {ReactComponent as Close} from "assets/images/close.svg";
import {ReactComponent as Fail} from "assets/images/fail_status.svg";
import {ReactComponent as Info} from "assets/images/information_status.svg";

import { removeGenericNotification } from "./action";
import { NOTIFICATION_DELAY } from "./types";

const Notification = ({ notification }: any) => {
  const dispatch = useDispatch();
  let timeout: any;
  useEffect(() => {
    timeout = setTimeout(() => {
      removeNotification();
    }, NOTIFICATION_DELAY);

    return () => {
      removeNotification();
    };
  }, []);

  const removeNotification = () => {

    if (timeout === undefined) {
      return;
    }

    clearTimeout(timeout);
    timeout = undefined;
    dispatch(removeGenericNotification(notification.notification.id));
  };



  const IconNotif =

    notification.notification.errorStatus==204?
    <Info/>
    :notification.notification.errorStatus==201?
    <Success/>
    :<Fail />


    const notifContent =
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{color:'#FFFFFF',fontFamily:'FS Siena',fontSize:15,margin:"0px 10px"}}> {notification.notification.message}</span>
       
      </div>

  const showAntdNotification = () => {
    antdNotification.open({
      key: notification.notification.id,
      className: 'custom-class-notifications-global',
      style: {borderRadius : "15px"},
      message: notifContent,
      duration: NOTIFICATION_DELAY,
      closeIcon:   <div className="notification-container"><div className="notification-separator"></div><Close /></div> ,
      icon:IconNotif
    });


  };


  useEffect(() => {
    showAntdNotification();
  }, []);

  return null;
};

export default Notification;