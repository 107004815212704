import {
  GET_AG_GRID_Data_SUCCESS,
  CleanMissionDetails,
  RequestClientDesignSuccess,
  RequestExperinceReportDocumentViewerSuccess,
  RequestMissionDetailsSuccess,
  CleanClientDesign
} from "./type";
import {GET_QUESTION_LEADER_BOARD_SUCCESS,GET_QUESTION_HIGHEST_ADVOCAY_SUCCESS} from "../QuestionsLeaderboard/type"
export const defaultState = {
  agGridData: [],
  loading: false,
  documentLoading: false,
  documentHtmlViewer: "",
  missionDetails:null ,
  questionLeaderBoard:[],
  highestInfluenceQuestion:[]
};

function surveyExplorerReducer(state = defaultState, action: any) {

  switch (action.type) {
    case  GET_AG_GRID_Data_SUCCESS:
      return {
        ...state,
        loading: true,
        agGridData: action.payload.agGridData,
        pageParameters: action.payload.pageParameters,
        documentLoading: false,
      };
      case  GET_QUESTION_LEADER_BOARD_SUCCESS:
        return {
          ...state,
          loading: true,
          questionLeaderBoard: action.payload.questionLeaderBoard,
          pageParameters: action.payload.pageParameters,
         };
         case  GET_QUESTION_HIGHEST_ADVOCAY_SUCCESS:
          return {
            ...state,
            loading: true,
            highestInfluenceQuestion: action.payload.highestInfluenceQuestion,
           };
    case  RequestExperinceReportDocumentViewerSuccess:
      return {
        ...state,
        documentHtmlViewer: action.payload.data,
        documentLoading: true,
      };
    case  RequestMissionDetailsSuccess: {
      return {
        ...state,
        missionDetails: action.payload.data,
      };
    }
    case  RequestClientDesignSuccess:
      return {
        ...state,
        clientDesign: action.payload.data,
      };
    case  CleanMissionDetails:
      return {
        ...state,
        missionDetails: null,
      };
      case CleanClientDesign:
      return {
        ...state,
        clientDesign: null,
      };
      default:
      return state;
  }

 }

export default surveyExplorerReducer;
