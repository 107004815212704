import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import { CheckAll, ReadAll } from 'assets/images';

interface CustomHeaderProps {
    column: any;
    //   api: any;
    readAllMissions: any;
    readUnreadBtnText: any;
    checkAllMissions: any;
    checkUncheckBtnText:any
}

const CustomHeaderComponent: React.FC<CustomHeaderProps> = ({ column, readAllMissions, checkAllMissions, readUnreadBtnText,checkUncheckBtnText }) => {
    const [isExpanded, setIsExpanded] = useState(false);


    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip
                    placement="topLeft"
                    arrow={false}
                    color="#FFFFFF"
                    title={
                        <div style={{ color: "#003D4C", backgroundColor: "white", border: "none", }}>
                            {checkUncheckBtnText()}
                        </div>
                    }>
                    <Button style={{ background: 'transparent', borderColor: 'transparent', height: '100%', paddingLeft: "0px", paddingTop: "8px", boxShadow: '0 0 0 0' }}
                        onClick={checkAllMissions}>
                        <CheckAll />
                    </Button>
                </Tooltip>
                <Tooltip
                    placement="topLeft"
                    arrow={false}
                    color="#FFFFFF"
                    title={
                        <div style={{ color: "#003D4C", backgroundColor: "white", border: "none", }}>
                            {readUnreadBtnText()}
                        </div>
                    }>
                    <Button style={{ background: 'transparent', borderColor: 'transparent', height: '100%', paddingInline: "10px", paddingTop: "8px", boxShadow: '0 0 0 0' }}
                        onClick={readAllMissions} >
                        <ReadAll />
                    </Button>
                </Tooltip>

            </div>
        

        </div>
    );
};

export default CustomHeaderComponent;