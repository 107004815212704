import {
    GET_LOCATION_RANKING_DATA_SUCCESS,GET_LOCATION_RANKING_DATA,
    GET_LOCATION_RANKING_DATA__BY_CATEGORY_ID_SUCCESS,GET_FORMATED_DATA, GET_LOCATION_RANKING_DATA_BY_CATEGORY_ID,
    GET_AG_GRID_Data__BY_CATEGORY_ID_FAILED
  } from "./type";
  
  export const defaultState = {
    locationRanking: [],
    hasData: false,
    locationRankingById : [],
    rankingByIdLoading : false, 
    formatedData:[]
    
   };
  
  function LocationRanking(state = defaultState, action: any) {

    switch (action.type) {

      case  GET_LOCATION_RANKING_DATA:
        return {
          ...state,
          hasData: !action.payload.firstCall,
          
        };
      case  GET_LOCATION_RANKING_DATA_SUCCESS:
        return {
          ...state,
          hasData: action.payload.hasData,
          locationRanking: action.payload.locationRanking.rows,
          pageParameters: action.payload.pageParameters,
          firstCall :action.payload.firstCall
        };
      case  GET_LOCATION_RANKING_DATA_BY_CATEGORY_ID : 
          return {
            ...state,
            rankingByIdLoading: true,
          };
        
       case  GET_LOCATION_RANKING_DATA__BY_CATEGORY_ID_SUCCESS :
        const {oldData} = action.payload;


        return {
          ...state,
          rankingByIdLoading: false,
          locationRanking: [...oldData, ...action.payload.locationRankingById.rows],
          pageParameters: action.payload.pageParameters,
          locationRankingById:action.payload.locationRankingById.rows
        };
       case  GET_AG_GRID_Data__BY_CATEGORY_ID_FAILED : 
  
        return {
          ...state,
          rankingByIdLoading: false,
        };
        case  GET_FORMATED_DATA:
        return {
          ...state,
           
          formatedData: action.payload.payload,
           
        };
        default:
        return state;
    }
  
   }
  
  export default LocationRanking;
  