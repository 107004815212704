import {
  GET_DASHBOARD,
  GET_DASHBOARD_FAIL,
  GET_DASHBOARD_SUCCESS,
  GET_COLOR,
  GET_COLOR_SUCCESS,
  GET_COLOR_FAIL,
  GET_LAST_USED,
  GET_LAST_USED_SUCCESS,
  GET_LAST_USED_FAIL,
  GET_WIDGET,
  GET_WIDGET_SUCCESS,
  GET_WIDGET_FAIL,
  GET_CAOCHING_SECTIONS,
  GET_CAOCHING_SECTIONS_SUCCESS,
  GET_CAOCHING_SECTIONS_FAIL,
  GET_NOM_FILTRED,
  GET_NOM_FILTRED_SUCCESS,
  GET_NOM_FILTRED_FAIL,
  CLEAR_DASHBOARD,
  GET_QBT,
  GET_QBT_SUCCESS,
  GET_QBT_FAIL,
  GET_ICONS,
  GET_ICONS_SUCCESS,
  GET_ICONS_FAIL,
} from './type';

export const getDashboard = (clientId: any) => ({
  type: GET_DASHBOARD,
  clientId,
});
export const getDadhboardSuccess = (payload: any) => ({
  type: GET_DASHBOARD_SUCCESS,
  payload,
});
export const getDadhboardFail = (payload: any) => ({
  type: GET_DASHBOARD_FAIL,
  payload,
});

export const getWidgetData = (
  filter: any,
  settings: any,
  id: number,
  endPoint: string,
  series?: any,
  source?: any,
  sectionIds?: any,
  questionWithAnswerIds?: any,
) => {
  return {
    type: GET_WIDGET,
    filter,
    settings,
    id,
    endPoint,
    series,
    source,
    sectionIds,
    questionWithAnswerIds,
  };
};
export const getWidgetDataSuccess = (payload: any) => {

  return ({
  type: GET_WIDGET_SUCCESS,
  payload,
});}
export const getWidgetDataFail = (payload: any,id:any) =>{ 
  return ({
  type: GET_WIDGET_FAIL,
  payload,
  id
})};
export const getColors = (idclient: any) => ({
  type: GET_COLOR,
  idclient,
});
export const getColorsSuccess = (payload: any) => ({
  type: GET_COLOR_SUCCESS,
  payload,
});
export const getColorsFail = (payload: any) => ({
  type: GET_COLOR_FAIL,
  payload,
});
export const getNomofiltred = (settings?: any) => {
  return {
    type: GET_NOM_FILTRED,
    settings,
  };
};

export const getNomofiltredSuccess = (payload: any) => ({
  type: GET_NOM_FILTRED_SUCCESS,
  payload,
});
export const getNomofiltredFail = (payload: any) => ({
  type: GET_NOM_FILTRED_FAIL,
  payload,
});
export const getLastfilter = (clientId: any) => ({
  type: GET_LAST_USED,
  clientId,
});
export const getLastfilterSuccess = (payload: any) => ({
  type: GET_LAST_USED_SUCCESS,
  payload,
});
export const getLastfilterFail = (payload: any) => ({
  type: GET_LAST_USED_FAIL,
  payload,
});
export const getSCoachingections = (settings: any, sources?: any, sectionIds?: any) => ({
  type: GET_CAOCHING_SECTIONS,
  settings,
  sources,
  sectionIds,
});
export const getSCoachingectionsSuccess = (payload: any) => ({
  type: GET_CAOCHING_SECTIONS_SUCCESS,
  payload,
});
export const getSCoachingectionsFail = (payload: any) => ({
  type: GET_CAOCHING_SECTIONS_FAIL,
  payload,
});
export const getQBTAction = () => ({
  type: GET_QBT,
});
export const getQBTActionSuccess = (payload: any) => ({
  type: GET_QBT_SUCCESS,
  payload,
});
export const getQBTActionFail = (payload: any) => ({
  type: GET_QBT_FAIL,
  payload,
});

export const clearDashboard = () => ({
  type: CLEAR_DASHBOARD,
});
export const getIconsAction = (id:any) => {
  return ({
  type: GET_ICONS,
  id
})};
export const getIconsActionSuccess = (payload: any) => ({
  type: GET_ICONS_SUCCESS,
  payload,
});
export const getIconsActionFail = (payload: any) => ({
  type: GET_ICONS_FAIL,
  payload,
});