import handleSagaDownloadErrors from "pages/commons/handle-saga-download-errors";
import { call, put, takeLatest } from "redux-saga/effects";
import { openGlobalNotification } from "pages/components/notifications";
import { DOWNLOAD_REPORTS_TYPE_1, DOWNLOAD_REPORTS_TYPE_2, DOWNLOAD_REPORTS_TYPE_3, DOWNLOAD_REPORTS_TYPE_4, DOWNLOAD_REPORTS_TYPE_5, DOWNLOAD_REPORTS_TYPE_6, DOWNLOAD_REPORTS_TYPE_7 } from "./type";
import { getClientId } from "helpers/authUtils";
import { DownloadDynamicReports } from "apis/url";
import { AxiosResponse } from "axios";
import { openBlobDataLink } from "../pdfDownLoad/saga";
import { removeDownloadingLoader } from "features/Loader/actions";
import moment from 'moment';
import { TranslateToStringWithLabels } from "pages/commons/Translate";



function* handleDownloadDynamicReports({ payload }: any): Generator<any, void, AxiosResponse<any>> {
  const clientId = getClientId();
  // console.log(payload)

  const response: any = yield call(DownloadDynamicReports, { clientId, filter: payload.filter, type: payload.type });
  // console.log(response)
  if (response) {
    const formattedDateTime = moment().format("YYYY-MM-DDTHH:mm:ss");
    const message = payload.filename + ' ' + TranslateToStringWithLabels({
      LabelsTranslations: payload.LabelsTranslations,
      id: `generic.dynamic.reports.success`
      , defaultMessage: 'Downloaded Successfully !'
    }) + ' !';
    openGlobalNotification({ status: "success", message: message, placement: 'bottomRight' });
    openBlobDataLink(response, true, `${payload.filename}_${formattedDateTime}.xlsx`)

  }
}
function* DynamicReportsSaga(): any {
  yield takeLatest(
    DOWNLOAD_REPORTS_TYPE_1,
    handleSagaDownloadErrors(handleDownloadDynamicReports)
  );
  yield takeLatest(
    DOWNLOAD_REPORTS_TYPE_2,
    handleSagaDownloadErrors(handleDownloadDynamicReports)
  );
  yield takeLatest(
    DOWNLOAD_REPORTS_TYPE_3,
    handleSagaDownloadErrors(handleDownloadDynamicReports)
  ); yield takeLatest(
    DOWNLOAD_REPORTS_TYPE_4,
    handleSagaDownloadErrors(handleDownloadDynamicReports)
  ); yield takeLatest(
    DOWNLOAD_REPORTS_TYPE_5,
    handleSagaDownloadErrors(handleDownloadDynamicReports)
  ); yield takeLatest(
    DOWNLOAD_REPORTS_TYPE_6,
    handleSagaDownloadErrors(handleDownloadDynamicReports)
  ); yield takeLatest(
    DOWNLOAD_REPORTS_TYPE_7,
    handleSagaDownloadErrors(handleDownloadDynamicReports)
  );
}
export default DynamicReportsSaga;
