export const INIT_GLOBAL_FILTER = 'INIT_GLOBAL_FILTER';
export const GET_FILTER_TEMPLATE_SUCCESS = 'GET_FILTER_TEMPLATE_SUCCESS';
export const REQUEST_FILTER_DATA = 'REQUEST_FILTER_DATA';
export const REQUEST_FILTER_DATA_SUCCESS = 'REQUEST_FILTER_DATA_SUCCESS';
export const ON_CHANGE_FILTER_MODEL = 'ON_CHANGE_FILTER_MODEL';
export const INIT_FITLER_LOADING = 'INIT_FITLER_LOADING';
export const CHANGE_FILTER_DATA = 'CHANGE_FILTER_DATA';
export const RESET_GLOBAL_FILTER = 'RESET_GLOBAL_FILTER';
export const APPLY_GLOBAL_FILTER = 'APPLY_GLOBAL_FILTER';
export const SET_ACTIVE_FILTER = 'SET_ACTIVE_FILTER';
export const APPLY_FILTER = 'APPLY_FILTER';
export const CLEAR_GLOBAL_FILTER = 'CLEAR_GLOBAL_FILTER';
export const GET_TOTAL_SURVEY_NUMBER_SUCCESS = 'GET_TOTAL_SURVEY_NUMBER_SUCCESS';
export const GET_HEADER_INFO_SUCCESS = 'GET_HEADER_INFO_SUCCESS';
export const periodFilterOptions = ['Period Yearly', 'Period Quarterly', 'Period Monthly', 'Period Daily'];

export const locationCheckStatus = {
  UNDETERMINATED: 2,
  SELECTED: 1,
  UNSELECTED: 0,
};

export type RequestFilterDataModel = {
  filter: FilterModel;
  template: any;
  sources: any;
  requestType: string;
  config?: any;
  body?: any;
};

export type FilterModel = {
  id: number;
  name: string;
  familyName: string;
  order: number;
  options?: string[];
  key: string;
  modelKey: string;
};

export const FILTER_MODEL_DATA_STRUCTURE = {
  REGULAR: 'regular',
  OBJECT: 'object',
  COMPOUND_OBJECT: 'compoundObject',
};

export const FILTER_FAMILY_NAMES = {
  LCP: 'Location Custom properties',
  SCP: 'Survey custom properties',
  RPO: 'Respondent properties options',
  ACE: 'ACE',
  THEME: 'THEME',
  PI: 'PI',
  HI: 'HI',
  SI: 'SI',
  METRIC: 'METRIC',
};

export const PERIOD_TYPE_ENUM = { Month: 0, Year: 1, YearToDate: 2, Day: 3, Week: 4, Quarter: 5 };
export const PERIOD_TYPE_STRING_ENUM: Record<number, string> = { 0: 'months', 1: 'years', 3: 'days', 5: 'quarters' };
export const COMPARISON_TYPE_ENUM = { previousPeriod: 1, previousYear: 2, custom: 3 };

export const REQUEST_FILTER_DATA_TYPE = {
  INITIAL_REQUEST: 'initialRequest',
  EXPAND_NODE: 'expandNode',
  SEARCH_TERM: 'searchTerm',
};

export const SELECT_STATUS = {
  UNSELETED: 0,
  SELECTED: 1,
  PARTIAL: 2,
};

export const LOCATION_TYPE = {
  CATEGORY: 1,
  STORE: 2,
};

export const PERIOD_COMPARISON_TYPES = [
  { label: 'Previous Period', value: 1 },
  { label: 'Previous Year', value: 2 },
  { label: 'Custom', value: 3 },
];

export const FILTER_BY_TYPES = [
  { value: 1, label: 'Period', stringValue: 'period' },
  { value: 2, label: 'YTD', stringValue: 'YTD' },
];

export const FILTER_BY_ENUM = {
  PERIOD: 1,
  YTD: 2,
};

export const GLOBAL_FILTER_TRANSLATIONS_PREFIX = 'generic.global.filter';

export const TRANSLATIONS_KEYS_PREFIX = {
  PERIOD_OPTIONS: `${GLOBAL_FILTER_TRANSLATIONS_PREFIX}.period.options`,
  COMPARISON_TYPE: `${GLOBAL_FILTER_TRANSLATIONS_PREFIX}.period.comparison.type`,
};
