/* eslint-disable eqeqeq */
import moment from 'moment';
import {
  LOCATION_TYPE,
  SELECT_STATUS,
  periodFilterOptions,
  PERIOD_TYPE_ENUM,
  COMPARISON_TYPE_ENUM,
  FILTER_BY_ENUM,
  FILTER_MODEL_DATA_STRUCTURE,
  FILTER_FAMILY_NAMES,
} from './types';
import {
  filterBy,
  findBy,
  findByRecursive,
  hasKeys,
  hasValues,
  isInteger,
  filterObjectByKeys,
  mapBy,
  reduceNestedObject,
  reduceObjectByKey,
  reduceTreeBy,
  removeDuplicatesBy,
  removeSpecialCharacters,
  tanslatePeriodPrefix,
  updateTreeProperty,
} from 'helpers/index';
import { PeriodIcon, LocationIcon, ProjectIcon, WaveIcon, CommonFilterIcon } from 'assets/images';
import DateFilter from './components/dateFilter/dateFilter';
import LocationFilter from './components/locationFilter';
import SelectFilter from './components/selectFilter';
import { getLangId } from 'apis/url';
import { getClientId } from 'helpers/authUtils';
import { TranslateToString } from 'pages/commons/Translate';

// const moment = require('moment-timezone');

/*********************List utiles related to filter period */

export const periodOptions = [
  { label: 'Yearly', value: 'Period Yearly', type: 1, description: 'Yearly', string: 'years', formatter: (v: any) => v.format('YYYY') },
  {
    label: 'Quarterly',
    value: 'Period Quarterly',
    type: 5,
    description: 'Quarterly',
    string: 'quarters',
    formatter: (v: any) => `Q${v.format('Q')} ${v.format('YYYY')}`,
  },
  {
    label: 'Monthly',
    value: 'Period Monthly',
    type: 0,
    description: 'Monthly',
    string: 'months',
    formatter: (v: any) => v.format('MMM YYYY'),
  },
  { label: 'Daily', value: 'Period Daily', type: 3, description: 'Daily', string: 'days', formatter: (v: any) => v.format('D MMM YYYY') },
  {
    label: 'YTD',
    value: 'Year To Date',
    type: 2,
    description: 'Year To Date',
    string: 'days',
    formatter: (v: any) => v.format('D MMM YYYY'),
  },
];

export const translatePeriodPlaceholder = (value: any) => {
  return TranslateToString({ id: `generic.period.placeholder.${removeSpecialCharacters(value?.toLowerCase())}`, defaultMessage: value });
};

export const formatDateByPeriodType = (date: any, period: any) => {
  switch (period) {
    case 0:
      return date != null ? tanslatePeriodPrefix(moment(TimestampToDate(date)).format('MMM YYYY')) : translatePeriodPlaceholder('mm yyyy');
    case 1:
      return date != null ? moment(TimestampToDate(date)).format('YYYY') : translatePeriodPlaceholder('yyyy');
    case 2:
      return date != null
        ? tanslatePeriodPrefix(moment(TimestampToDate(date)).format('D MMM YYYY'))
        : translatePeriodPlaceholder('D MMM YYYY');
    case 3:
      return date != null
        ? tanslatePeriodPrefix(moment(TimestampToDate(date)).format('D MMM YYYY'))
        : translatePeriodPlaceholder('d mm yyyy');
    case 5:
      return date != null
        ? tanslatePeriodPrefix(
            `Q${moment(TimestampToDate(date)).quarter()} ${moment(TimestampToDate(date)).format('YYYY')}`,
            'generic.quarters'
          )
        : translatePeriodPlaceholder('qq yyyy');
  }
};

export const getPeriodOptionType = (item: string) => {
  return findBy(periodOptions, 'value', item, 'type', 0);
};

export const convertToTimestamp = (dateString: any) => {
  const humanTime = moment(dateString).format('YYYY-MM-DD');
  const timestamp = moment.utc(humanTime).unix();

  return timestamp;
};
export const TimestampToDate = (timestamp: any) => {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const formatDateByPeriod = (date: any, period: any) => {
  switch (period) {
    case 'Period YTD':
      return date != null ? moment(TimestampToDate(date)).format('D MMM YYYY') : 'D MMM YYYY';
    case 'Period Yearly':
      return date != null ? moment(TimestampToDate(date)).format('YYYY') : 'yyyy';
    case 'Period Monthly':
      return date != null ? moment(TimestampToDate(date)).format('MMM YYYY') : 'mm yyyy';
    case 'Period Quarterly':
      return date != null ? `Q${moment(TimestampToDate(date)).quarter()} ${moment(TimestampToDate(date)).format('YYYY')}` : 'qq yyyy';
    case 'Period Daily':
      return date != null ? moment(TimestampToDate(date)).format('D MMM YYYY') : 'd mm yyyy';
  }
};

/**************************************************************** */

export interface TreeNode {
  id: number;
  resultItems: string;
  resultType: number;
  parentId?: number;
  status?: 0;

  additionalData?: {
    totalStore: number;
    surveyNumber: number;
    nps: boolean;
    userId: string;
  };
  children?: TreeNode[];
}

/******************* Tree Data *******************/

export const buildTreeLevel = (data: any[], tab: any[], parentGuid: number, level: number) => {
  let newTab: any[] = tab;
  const children = data.filter((item: any) => item.parentGuid == parentGuid);
  let index: number = tab.findIndex((ele: any) => ele.level == level);
  if (children.length > 0) {
    if (index == -1) {
      newTab.push({ level: level, nodes: children });
    } else {
      let currentLevel: any = newTab[index];
      let currentNodes: any[] = currentLevel?.nodes;
      currentNodes = children.length > 0 ? [...currentNodes, ...children] : currentNodes;
      newTab[index] = { ...currentLevel, nodes: currentNodes };
    }
    for (let i in children) {
      buildTreeLevel(data, newTab, children[i].guid, level + 1);
    }
  }
  return newTab;
};

/*
 build the tree of location (input of the component location tree)
*/
const buildTree = (data: any[], parentGuid: string, level: number) => {
  if (!hasValues(data)) {
    return [];
  }
  const list = data?.filter((item) => item.parentGuid === parentGuid);
  if (!hasValues(list)) {
    return [];
  }
  return list.map((item) => {
    const children = buildTree(data, item.guid, level + 1);
    const node: any = {
      ...item,
      status: item.status,
      expanded: item.expanded && hasValues(children),
      level,
      children,
      additionalData: item.additionalData || {},
    };
    return node;
  });
};

export const getStores = (list: any, key: string, value: any) => {
  if (!hasValues(list)) {
    return [];
  }
  return list.reduce((acc: any, curr: any) => {
    if (curr[key] !== value) {
      return acc.concat(getStores(curr.children, key, value));
    } else {
      return acc.concat([curr, ...getStores(curr.children, key, value)]);
    }
  }, []);
};

export const formatListLocation = (list: any[], ref?: any[], expanded = false, model?: any) => {
  if (!hasValues(list)) return [];
  const loadedStores = getStores(model, 'resultType', LOCATION_TYPE.STORE);
  const data = list.map((item: any) => {
    const parentStatus =
      findByRecursive(ref, 'guid', item.parentGuid, 'status') == SELECT_STATUS.SELECTED ? SELECT_STATUS.SELECTED : SELECT_STATUS.UNSELETED;
    const hasChild = findBy(ref, 'parentGuid', item.guid);
    const defaultStatus = parentStatus === SELECT_STATUS.UNSELETED && hasChild ? SELECT_STATUS.PARTIAL : parentStatus;
    return {
      ...item,
      expanded: expanded ? expanded : hasValues(ref) && findByRecursive(ref, 'guid', item.guid, 'expanded', false),
      status: hasValues(ref) ? findByRecursive(ref, 'guid', item.guid, 'status', defaultStatus) : SELECT_STATUS.UNSELETED,
    };
  });

  const trueResult = buildTree(removeDuplicatesBy([...data, ...loadedStores], 'guid'), '', 0);
  return manageTreeStatus(trueResult);
};

export const getFilterIconByFilterName = ({ name }: { name: string }) => {
  switch (name) {
    case 'period':
      return PeriodIcon;
    case 'Locations':
      return LocationIcon;
    case 'Project':
      return ProjectIcon;
    case 'Waves':
      return WaveIcon;
    default:
      return CommonFilterIcon;
  }
};

const getComponentByFilterName = ({ name }: { name: string }) => {
  switch (name) {
    case 'period':
      return DateFilter;
    case 'Locations':
      return LocationFilter;
    default:
      return SelectFilter;
  }
};

const getConfigByFilterName = (templateId: number, { name, options }: { name: string; options?: string[] }) => {
  const hasOnlyStores = hasValues(options) && options?.length === 1 && options?.includes('Stores');
  switch (name) {
    case 'period':
      return { endPoint: null, method: null, queryParams: {}, withRefresh: false };
    case 'Locations':
      return {
        endPoint: `api/v1/${getLangId()}/templateFilter/${getClientId()}/filterTemplates/${templateId}/locationFilter/`,
        method: (input: any, ref?: any, expanded?: boolean, model?: any) => formatListLocation(input, ref, expanded, model),
        queryParams: { levels: options, onlyCategories: hasOnlyStores ? false : true },
        withRefresh: false,
      };
    default:
      return {
        endPoint: `api/v1/${getLangId()}/templateFilter/${getClientId()}/filterLibrary/${templateId}/filter/`,
        method: (input: any, ref?: any) => input,
        queryParams: {},
        withRefresh: true,
      };
  }
};

const getResetConfigByFilterName = ({ name }: { name: string }) => {
  if (name == 'period') {
    return true;
  }
  return false;
};

export const filterTemplateDefinition = ({ id, filters }: { id: number; filters: any[] }) => {
  if (!hasValues(filters)) return [];
  return filters.map((f: FilterModel) => ({
    id: f.id,
    filterSettings: {
      ...f,
      filterId: f.id,
      filterName: f.name,
      modelKey: getKeyByFilterFamilyName(f),
      modelDataStructure: getDataStructure(f),
      withReset: getResetConfigByFilterName(f),
    },
    Icon: getFilterIconByFilterName(f),
    Component: getComponentByFilterName(f),
    config: getConfigByFilterName(id, f),
  }));
};

type FilterModel = {
  id: number;
  order: number;
  name: string;
  options?: string[];
  familyName: string;
  key: string;
};

export const mapDynamicFilterPropertiesWithFilters = (source: any[], ref: any[]) => {
  if (!hasValues(ref) || !hasValues(source)) {
    return [];
  }
  return source.reduce((acc: any, curr: any) => {
    const x = ref.find((el: any) => el?.name == curr.filterName && el?.familyName == curr.familyName);
    if (!!x) {
      return acc.concat({ ...curr, id: x.id, filterId: x.id });
    }
    return acc;
  }, []);
};

export const mapLastUsedWithTemplate = (lastUsedFilter: any, filterTemplate: any) => {
  const { filters } = filterTemplate;
  const filterPeriodOptions = findBy(filters, 'key', 'period', 'options', []);
  const hasYTDOption = !!filterPeriodOptions.includes('Period YTD');
  const hasComparison = !!filterPeriodOptions.includes('Period comparison');
  const hasPeriodOptions = !!filterPeriodOptions.find((p: string) => periodFilterOptions.includes(p));
  const filterByFromType = lastUsedFilter?.period?.type == PERIOD_TYPE_ENUM.YearToDate ? FILTER_BY_ENUM.YTD : FILTER_BY_ENUM.PERIOD;
  const overrideToDate =
    lastUsedFilter?.period?.type == PERIOD_TYPE_ENUM.YearToDate ? convertToTimestamp(moment()) : lastUsedFilter?.period?.toDate;
  const filterBy = isInteger(lastUsedFilter?.period?.type)
    ? filterByFromType
    : getDefaultPeriodValue(filterTemplate?.defaultPeriodOptions)?.filterBy;

  return {
    ...lastUsedFilter,
    period: {
      ...(findBy(filters, 'key', 'period')
        ? {
            type: isInteger(lastUsedFilter?.period?.type)
              ? lastUsedFilter?.period?.type
              : getDefaultPeriodValue(filterTemplate?.defaultPeriodOptions)?.type,
            fromDate: lastUsedFilter?.period?.fromDate || getDefaultPeriodValue(filterTemplate?.defaultPeriodOptions)?.from,
            toDate: overrideToDate || getDefaultPeriodValue(filterTemplate?.defaultPeriodOptions)?.to,
            isWithComparison: (hasComparison && lastUsedFilter?.period?.isWithComparison) || false,
            comparisonType: isInteger(lastUsedFilter?.period?.comparisonType) ? lastUsedFilter?.period?.comparisonType : 1,
            customToDate: lastUsedFilter?.period?.customToDate || null,
            customFromDate: lastUsedFilter?.period?.customFromDate || null,
            filterBy,
            hasYTDOption,
            hasPeriodOptions,
            defaultPeriod: filterTemplate?.defaultPeriodOptions,
            hasDefaultPeriod: !!filterTemplate.defaultPeriodOptions,
          }
        : {}),
    },
    dynamicFilterProperties: mapDynamicFilterPropertiesWithFilters(lastUsedFilter?.dynamicFilterProperties, filterTemplate?.filters),
    locations: [...(findBy(filters, 'key', 'locations') ? lastUsedFilter?.locations || [] : [])],
    projects: [...(findBy(filters, 'key', 'projects') ? lastUsedFilter?.projects || [] : [])],
    waves: [...(findBy(filters, 'key', 'waves') ? lastUsedFilter?.waves || [] : [])],
    brands: [...(findBy(filters, 'key', 'brands') ? lastUsedFilter?.brands || [] : [])],
    respondent: {
      age: [...(findBy(filters, 'key', 'age') ? lastUsedFilter?.respondent?.age || [] : [])],
      gender: [...(findBy(filters, 'key', 'gender') ? lastUsedFilter?.respondent?.gender || [] : [])],
      nationality: [...(findBy(filters, 'key', 'nationality') ? lastUsedFilter?.respondent?.nationality || [] : [])],
      segment: [...(findBy(filters, 'key', 'segment') ? lastUsedFilter?.respondent?.segment || [] : [])],
    },
    coaches: [...(findBy(filters, 'key', 'coaches') ? lastUsedFilter?.coaches || [] : [])],
    advisors: [...(findBy(filters, 'key', 'advisors') ? lastUsedFilter?.advisors || [] : [])],
    coachingSubTypes: [...(findBy(filters, 'key', 'coachingSubTypes') ? lastUsedFilter?.coachingSubTypes || [] : [])],
    coachingSessionStatuses: [...(findBy(filters, 'key', 'coachingSessionStatuses') ? lastUsedFilter?.coachingSessionStatuses || [] : [])],
    customProperty: {
      ...(findBy(filters, 'familyName', FILTER_FAMILY_NAMES.LCP)
        ? filterModelByFamilyName(lastUsedFilter?.customProperty || {}, filters, FILTER_FAMILY_NAMES.LCP)
        : {}),
    },
    surveyCustomProperty: {
      ...(findBy(filters, 'familyName', FILTER_FAMILY_NAMES.SCP)
        ? { string: filterModelByFamilyName(lastUsedFilter?.surveyCustomProperty?.string || {}, filters, FILTER_FAMILY_NAMES.SCP) }
        : {}),
    },
    ace: {
      ...(findBy(filters, 'familyName', FILTER_FAMILY_NAMES.ACE)
        ? filterModelByFamilyName(lastUsedFilter?.ace || {}, filters, FILTER_FAMILY_NAMES.ACE)
        : {}),
    },
    theme: {
      ...(findBy(filters, 'familyName', FILTER_FAMILY_NAMES.THEME)
        ? filterModelByFamilyName(lastUsedFilter?.theme || {}, filters, FILTER_FAMILY_NAMES.THEME)
        : {}),
    },
    pi: {
      ...(findBy(filters, 'familyName', FILTER_FAMILY_NAMES.PI)
        ? filterModelByFamilyName(lastUsedFilter?.pi || {}, filters, FILTER_FAMILY_NAMES.PI)
        : {}),
    },
    hi: {
      ...(findBy(filters, 'familyName', FILTER_FAMILY_NAMES.HI)
        ? filterModelByFamilyName(lastUsedFilter?.hi || {}, filters, FILTER_FAMILY_NAMES.HI)
        : {}),
    },
    si: {
      ...(findBy(filters, 'familyName', FILTER_FAMILY_NAMES.SI)
        ? filterModelByFamilyName(lastUsedFilter?.si || {}, filters, FILTER_FAMILY_NAMES.SI)
        : {}),
    },
    metric: {
      ...(findBy(filters, 'familyName', FILTER_FAMILY_NAMES.METRIC)
        ? filterModelByFamilyName(lastUsedFilter?.metric || {}, filters, FILTER_FAMILY_NAMES.METRIC)
        : {}),
    },
  };
};

export const filterModelByFamilyName = (model: Record<string, any>, source: any[], familyName: string): Record<string, any> => {
  const familyFilters = filterBy(source, (el: any) => el.familyName == familyName);
  const familyFilterKeys = mapBy(familyFilters, 'key');
  return filterObjectByKeys(model, familyFilterKeys);
};
export const buildFilterProfile = (payload: any) => {
  const {
    period,
    locations,
    dynamicFilterProperties,
    projects,
    waves,
    brands,
    respondent,
    coaches,
    advisors,
    coachingSubTypes,
    coachingSessionStatuses,
    customProperty,
    surveyCustomProperty,
    ace,
    theme,
    pi,
    hi,
    si,
    metric,
  } = payload;
  return {
    period: {
      type: period?.type,
      fromDate: period?.fromDate,
      toDate: period?.toDate,
      isWithComparison: period?.isWithComparison,
      comparisonType: period?.comparisonType,
      customToDate: period?.customToDate,
      customFromDate: period?.customFromDate,
    },
    locations: mapBy(locations, 'resultItems'),
    dynamicFilterProperties,
    projects: hasValues(projects) ? mapBy(projects, 'resultItems') : null,
    waves: hasValues(waves) ? mapBy(waves, 'resultItems') : null,
    brands: hasValues(brands) ? mapBy(brands, 'id') : null,
    respondent: {
      gender: hasValues(respondent?.gender) ? mapBy(respondent?.gender, 'resultItems') : null,
      age: hasValues(respondent?.age) ? mapBy(respondent?.age, 'resultItems') : null,
      nationality: hasValues(respondent?.nationality) ? mapBy(respondent?.nationality, 'resultItems') : null,
      segment: hasValues(respondent?.segment) ? mapBy(respondent?.segment, 'resultItems') : null,
    },
    coaches: hasValues(coaches) ? mapBy(coaches, 'resultItems') : null,
    advisors: hasValues(advisors) ? mapBy(advisors, 'resultItems') : null,
    coachingSessionStatuses: hasValues(coachingSessionStatuses) ? mapBy(coachingSessionStatuses, 'id') : null,
    coachingSubTypes: hasValues(coachingSubTypes) ? mapBy(coachingSubTypes, 'id') : null,
    ...(hasKeys(customProperty) ? { customProperty: reduceObjectByKey(customProperty, 'resultItems') } : {}),
    ...(hasKeys(surveyCustomProperty)
      ? {
          surveyCustomProperty: {
            string: reduceObjectByKey(surveyCustomProperty.string, 'resultItems'),
          },
        }
      : {}),
    ...(hasKeys(ace) ? { ace: reduceNestedObject(ace, ['additionalData', 'answerOrder']) } : {}),
    ...(hasKeys(theme) ? { theme: reduceNestedObject(theme, ['additionalData', 'answerOrder']) } : {}),
    ...(hasKeys(pi) ? { pi: reduceNestedObject(pi, ['additionalData', 'answerOrder']) } : {}),
    ...(hasKeys(hi) ? { hi: reduceNestedObject(hi, ['additionalData', 'answerOrder']) } : {}),
    ...(hasKeys(si) ? { si: reduceNestedObject(si, ['additionalData', 'answerOrder']) } : {}),
    ...(hasKeys(metric) ? { metric: reduceNestedObject(metric, ['additionalData', 'answerOrder']) } : {}),
  };
};

export const checkMethodByDataStructure = {
  [FILTER_MODEL_DATA_STRUCTURE.REGULAR]: (val: any, i: any, key: string) => [...val, i],
  [FILTER_MODEL_DATA_STRUCTURE.OBJECT]: (val: any, i: any, key: string) => ({ ...val, [key]: [...(val[key] || []), i] }),
  [FILTER_MODEL_DATA_STRUCTURE.COMPOUND_OBJECT]: (val: any, i: any, key: string) => ({
    string: { ...(val.string || []), [key]: [...(hasKeys(val.string) ? val.string[key] || [] : []), i] },
  }),
};

export const unCheckMethodByDataStructure = {
  [FILTER_MODEL_DATA_STRUCTURE.REGULAR]: (val: any, i: any, key: string) =>
    val.filter((v: any) => (hasKeys(v) ? v.id !== i.id : v !== i.resultItems)),
  [FILTER_MODEL_DATA_STRUCTURE.OBJECT]: (val: any, i: any, key: string) => ({
    ...val,
    [key]: (val[key] || []).filter((v: any) => (hasKeys(v) ? v.id !== i.id : v !== i.resultItems)),
  }),
  [FILTER_MODEL_DATA_STRUCTURE.COMPOUND_OBJECT]: (val: any, i: any, key: string) => ({
    string: {
      ...(val.string || []),
      [key]: [...(hasKeys(val.string) ? val.string[key] || [] : [])].filter((v: any) => (hasKeys(v) ? v.id !== i.id : v !== i.resultItems)),
    },
  }),
};

export const unCheckAllMethodByDataStructure = {
  [FILTER_MODEL_DATA_STRUCTURE.REGULAR]: () => [],
  [FILTER_MODEL_DATA_STRUCTURE.OBJECT]: (val: any, key: string) => ({ ...val, [key]: [] }),
  [FILTER_MODEL_DATA_STRUCTURE.COMPOUND_OBJECT]: (val: any, key: string) => ({ string: { ...(val.string || []), [key]: [] } }),
};

export const getKeyByFilterFamilyName = ({ familyName, key }: { familyName: string; key: string }): string => {
  if (familyName == FILTER_FAMILY_NAMES.LCP) {
    return 'customProperty';
  }
  if (familyName == FILTER_FAMILY_NAMES.SCP) {
    return 'surveyCustomProperty';
  }
  if (familyName == FILTER_FAMILY_NAMES.RPO) {
    return 'respondent';
  }
  if (['ACE', 'THEME', 'PI', 'HI', 'SI', 'METRIC'].includes(familyName)) {
    return familyName.toLowerCase();
  }
  return key;
};

export const getDataStructure = ({ familyName, key }: { familyName: string; key: string }): string => {
  if (familyName == FILTER_FAMILY_NAMES.LCP) {
    return FILTER_MODEL_DATA_STRUCTURE.OBJECT;
  }
  if (familyName == FILTER_FAMILY_NAMES.SCP) {
    return FILTER_MODEL_DATA_STRUCTURE.COMPOUND_OBJECT;
  }
  if (familyName == FILTER_FAMILY_NAMES.RPO) {
    return FILTER_MODEL_DATA_STRUCTURE.OBJECT;
  }
  if (
    [
      FILTER_FAMILY_NAMES.ACE,
      FILTER_FAMILY_NAMES.THEME,
      FILTER_FAMILY_NAMES.PI,
      FILTER_FAMILY_NAMES.HI,
      FILTER_FAMILY_NAMES.SI,
      FILTER_FAMILY_NAMES.METRIC,
    ].includes(familyName)
  ) {
    return FILTER_MODEL_DATA_STRUCTURE.OBJECT;
  }
  return FILTER_MODEL_DATA_STRUCTURE.REGULAR;
};

const getDefaultPeriodValue = (ref: number) => {
  switch (ref) {
    case 1:
      return {
        from: convertToTimestamp(moment().startOf('year')),
        to: convertToTimestamp(moment().endOf('year')),
        type: PERIOD_TYPE_ENUM.Year,
        filterBy: FILTER_BY_ENUM.PERIOD,
      };
    case 2:
      return {
        from: convertToTimestamp(moment().startOf('month')),
        to: convertToTimestamp(moment().endOf('month')),
        type: PERIOD_TYPE_ENUM.Month,
        filterBy: FILTER_BY_ENUM.PERIOD,
      };
    case 3:
      return {
        from: convertToTimestamp(moment().startOf('year')),
        to: convertToTimestamp(moment()),
        type: PERIOD_TYPE_ENUM.YearToDate,
        filterBy: FILTER_BY_ENUM.YTD,
      };
    default:
      return { from: null, to: null, type: PERIOD_TYPE_ENUM.Year, filterBy: FILTER_BY_ENUM.PERIOD };
  }
};

export const handleCheckTree = (data: any, checked: boolean, node: any) => {
  if (!data.length) {
    return [];
  }
  const result = data.reduce((acc: any, curr: any) => {
    if (curr.guid === node.guid) {
      return acc.concat({
        ...curr,
        status: checked ? SELECT_STATUS.SELECTED : SELECT_STATUS.UNSELETED,
        children: updateTreeProperty(curr.children, 'status', checked ? SELECT_STATUS.SELECTED : SELECT_STATUS.UNSELETED),
      });
    }
    if (hasValues(curr.children) && curr.guid !== node.guid) {
      const children = handleCheckTree(curr.children, checked, node);
      let status = curr.status;
      if (hasValues(children) && children.every((el: any) => el.status == SELECT_STATUS.SELECTED)) {
        status = SELECT_STATUS.SELECTED;
      }
      if (hasValues(children) && children.every((el: any) => el.status == SELECT_STATUS.UNSELETED)) {
        status = SELECT_STATUS.UNSELETED;
      }
      if (
        hasValues(children) &&
        !children.every((el: any) => el.status == SELECT_STATUS.SELECTED) &&
        !children.every((el: any) => el.status == SELECT_STATUS.UNSELETED)
      ) {
        status = SELECT_STATUS.PARTIAL;
      }
      return acc.concat({ ...curr, status, children });
    }

    return acc.concat(curr);
  }, []);
  return result;
};

export const manageTreeStatus = (data: any) => {
  if (!data.length) {
    return [];
  }
  const result = data.reduce((acc: any, curr: any) => {
    if (curr.status === SELECT_STATUS.SELECTED) {
      return acc.concat(curr);
    } else {
      let status = SELECT_STATUS.UNSELETED;
      if (hasValues(curr.children)) {
        const children = manageTreeStatus(curr.children);
        if (children.every((el: any) => el.status == SELECT_STATUS.SELECTED)) {
          status = SELECT_STATUS.SELECTED;
        }
        if (
          !children.every((el: any) => el.status == SELECT_STATUS) &&
          !children.every((el: any) => el.status == SELECT_STATUS.UNSELETED)
        ) {
          status = SELECT_STATUS.PARTIAL;
        }
        return acc.concat({ ...curr, status, children });
      }
    }
    return acc.concat(curr);
  }, []);
  return result;
};

export const handleCheckAll = (data: any, checked: boolean) => {
  if (!data.length) {
    return [];
  }
  const result = data.reduce((acc: any, curr: any) => {
    if (hasValues(curr.children)) {
      const children = handleCheckAll(curr.children, checked);
      return acc.concat({ ...curr, status: checked ? SELECT_STATUS.SELECTED : SELECT_STATUS.UNSELETED, children });
    }

    return acc.concat({ ...curr, status: checked ? SELECT_STATUS.SELECTED : SELECT_STATUS.UNSELETED });
  }, []);
  return result;
};

export const handleExpandTreeItem = (data: any, expanded: boolean, node: any) => {
  if (!data.length) {
    return [];
  }
  const result = data.reduce((acc: any, curr: any) => {
    if (curr.guid === node.guid) {
      return acc.concat({ ...curr, expanded: !expanded });
    }
    if (curr.guid !== node.guid) {
      if (hasValues(curr.children)) {
        const children = handleExpandTreeItem(curr.children, expanded, node);
        return acc.concat({ ...curr, children });
      }
    }
    return acc.concat({ ...curr, expanded: false });
  }, []);
  return result;
};

export function mergeStoresWithTree(list: any[], tree: any[]): any {
  if (!hasValues(tree)) {
    return [];
  }
  if (!hasValues(list)) {
    return tree;
  }
  return tree.reduce((acc: any, curr: any) => {
    if (curr.status !== SELECT_STATUS.SELECTED && !hasValues(curr.children)) {
      const x = list.filter((el: any) => el.resultType === LOCATION_TYPE.STORE && el.parentGuid === curr.guid);
      return acc.concat({ ...curr, children: [...x] });
    }
    return acc.concat({ ...curr, children: mergeStoresWithTree(list, curr.children) });
  }, []);
}

export function mergeTreeWithModel(tree: any[], model: any[]): any {
  if (!hasValues(tree)) {
    return [];
  }
  const selectedCategories = model?.filter((el: any) => el.status == SELECT_STATUS.SELECTED && el.resultType === LOCATION_TYPE.CATEGORY);
  const selectedStores = model?.filter((el: any) => el.status == SELECT_STATUS.SELECTED && el.resultType === LOCATION_TYPE.STORE);
  const selectedLowestCategories = selectedCategories?.map((el: any) => el?.additionalData?.lowestLevelCategories)?.flat();
  const stores = reduceTreeBy(tree, 'resultType', LOCATION_TYPE.STORE);

  const filtredModelStores = selectedStores?.filter((el: any) => !mapBy(stores, 'guid')?.includes(el?.guid));
  const filtredStores = stores?.filter(
    (el: any) => el.status === SELECT_STATUS.SELECTED && !selectedLowestCategories?.includes(el.parentId)
  );
  return [...selectedCategories, ...filtredModelStores, ...filtredStores];
}

export const getPreviousPeriodDetails = (model: any) => {
  const { comparisonType, type, fromDate, toDate } = model;
  let start;
  let to;

  const startMoment = moment(fromDate * 1000);
  const endMoment = moment(toDate * 1000);
  const stringType = findBy(periodOptions, 'type', type, 'string', 'months');
  const formatter = findBy(periodOptions, 'type', type, 'formatter');
  const numberOfPeriod = endMoment.diff(startMoment, stringType) + 1;

  if (comparisonType === COMPARISON_TYPE_ENUM.previousPeriod) {
    const previousStartMoment = startMoment.clone().subtract(numberOfPeriod, stringType);
    const previousEndMoment = endMoment.clone().subtract(numberOfPeriod, stringType);
    start = formatter(previousStartMoment);
    to = formatter(previousEndMoment);
  }

  if (comparisonType === COMPARISON_TYPE_ENUM.previousYear) {
    const previousYearStartMoment = startMoment.clone().subtract(1, 'year');
    const previousYearEndMoment = endMoment.clone().subtract(1, 'year');
    start = formatter(previousYearStartMoment);
    to = formatter(previousYearEndMoment);
  }

  return [
    tanslatePeriodPrefix(start, type == PERIOD_TYPE_ENUM.Quarter ? 'generic.quarters' : 'generic.months'),
    tanslatePeriodPrefix(to, type == PERIOD_TYPE_ENUM.Quarter ? 'generic.quarters' : 'generic.months'),
  ];
};
