export const PrintPdf="PrintPdf"
export const StyleWidget=`<style type="text/css">

@font-face {
	font-family: 'FS Siena';
	src: url('https://cxgconnect.blob.core.windows.net/merge-container/FSSIENAREGULAR.TTF?sv=2020-02-10&spr=https%2Chttp&st=2024-03-22T11%3A11%3A17Z&se=2040-01-01T11%3A11%3A00Z&sr=b&sp=re&sig=TBF7aUjTwvZGTwu8bYxNpagw%2BXqPCzZFSRyiT1wBwOY%3D') format('truetype');
	font-weight: normal;
	font-style: normal;
}
.widgetcontainer{
    display: flex;
    background: white;
    flex-direction: column;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    width: 100%;
    margin-top: 15px;  
    height: auto;
    }
    .nodataContainer{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 20px;
    }
    .headerWidget{
      display: flex;
      justify-content: space-between;
      align-items: center;
      opacity: 1 !important;
  
    }
    .widgettitell{
        background: #003D4C;
        border-top-left-radius: 20px;
        border-bottom-right-radius: 25px;
        font-family: 'FS Siena';
        font-style: normal;
        font-weight: 600;
        width:auto;
        height: 20px;
        font-size: 16px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        padding: 20px;

    }
    .groubytext{
font-family: 'FS Siena';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 15px;
text-align: center;
color: #BB6125;
    }
    .surveysCountText{
font-family: 'FS Siena';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 15px;
color: #919191;
    }
    .bar2{
      width: 48%;
      margin-top: 20px;
    }
    .funnel{
      margin-top:20px;
      width:25%;
      margin-left:12px;
    }
   .pie{
    margin-top:20px;
    width:25%;
    margin-left:13px
   }
@media (max-width: 600px) {

  /* change this value to match your desired breakpoint */
  .bar2,
  .pie,
  .funnel {
    width: 100%;
    margin-left: 0px;
    
      /* change the direction of the container to column*/
  }
}
    .footerwidget{
      display: flex;
      justify-content:end;
    }
    .widgetlyout{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      
    }
    .xaxislyout{
      width:7%;
      padding:0px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .titleaxis{
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: #BB6125;
    margin-right: 10px;
    text-align: center;
    }
    .textaxisstyle{
      font-family: 'FS Siena';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 13px;
      color: #003C4C;
      text-align: center;
    }
    .custom-pagination {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-self: flex-end;
      margin-top: 20px;
      border:solid 1px #9DD3C9;
      border-radius: 50px;
      width: 400;
    }
    /* Style the page size options dropdown */
    .custom-pagination .ant-select-selection {
      border-color: #9DD3C9;
      background-color: #f0f5ff;
    }
    
    /* Style the page size options dropdown on hover */
    .custom-pagination .ant-select-selection:hover {
      background-color: #e6f7ff;
    }
    
    /* Style the page size options */
    .custom-pagination .ant-select-item {
      color: #9DD3C9;
    }
    
    /* Style the current page number */
    .custom-pagination .ant-pagination-item-active {
      background-color: #9DD3C9;
      border-color: #9DD3C9;
      color: #003D4C;
    }
    .cercle{
    width: 40px;
    height: 40px;
    font-family: 'FS Siena';
    color:white;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    text-align: center;
    background-color: #003D4C;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    .textincercle{
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    line-height: 10px;
    color: #003D4C;
    margin-left: 10px ;
    margin-top: 10px;
    }
    .textwhite{
      font-family: 'FS Siena';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 15px;    
      display: flex;
      align-items: center;
      color: #FFFFFF;
      }
      .ant-tree-show-line .ant-tree-indent-unit:after{
        border-color:#BB6125
      }
      .ant-tree-show-line .ant-tree-indent-unit:before{
        border-color:#BB6125
      }
      .ant-tree-switcher-leaf-line:before{
        border-color:#BB6125
      }
      .ant-tree .ant-tree-switcher-leaf-line:before {
        border-inline-end: 1px solid #BB6125;
      }
      .ant-tree .ant-tree-switcher-leaf-line:before {
        border-inline-end: 1px solid #BB6125;
      }
    .legend{
      margin-left: 3%;
      margin-right: 3%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .textdropdownoption{
      font-family: 'FS Siena';
      font-style: normal;
      font-weight: 600;
      font-size: 14px; 
      color: #003D4C;
      text-align: center;
    }
    .vertical-line {
      width: 1px; 
      height: 10vh; 
      background-color:#BB6125;
      margin-top:20px ;
 
    }
    .cxevscxctext{
      font-family: 'FS Siena';
      font-style: normal;
      font-weight: 600;
      font-size: 12px; 
      color: #909090;
      text-align: center;
    }
    .legendSymbolcontainer{
      border-radius: 10px;
      background: rgba(157, 211, 201, 0.2);
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

    }
    .squard{
      width: 10px;
      height: 10px;
      border-radius: 3px;
    }
    .legendItems{
      display: flex;
      flex-direction: row;
      justify-content:center;
      align-items: center;
    }
    .legendcontziner{
      padding:10px;
      display: flex;
      flex-direction: row;
      justify-content:space-between;
      align-items: center;
      color:#77C8A1;
      width: 100%;
      flex-wrap: wrap;
    }
    .circlecontainer{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 85%;
      margin-bottom: -50px;
    }
    .lyoutpermaide{
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-around;
    }
    .textpieres{
      font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: #003D4C;
    text-align: center;
    margin-top: 5px;
    }
.acerankingfunelgroupby{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}
.acerankingtabletitel{
  font-family: 'FS Siena';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 15px;
text-align: center;
color: #003C4C;
margin-top: -20px;
}
.acerankingtableindicator{
  font-family: 'FS Siena';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 17px;
color: #003C4C;
text-align: center;
}
.underline{
  text-align: center;
  height: 2px;
  border: 0;
  width: 30px;
  border-top: 2px solid #BB6125;
}
.answerRanksValue{
font-family: 'FS Siena';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 15px;
color: #003C4C;
margin-top: 18px;
}
.vl {
  border-left: 3px solid #BB6125;
  height: 30px;
}
.btninfo{
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	outline: inherit;
  margin-right: 20px;
  cursor: pointer;
}
.titelInfobtn{
font-family: 'FS Siena';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 19px;
color: #003C4C;
}
.textinfobtn{
  font-family: 'FS Siena';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 13px;
color: #003D4C;
padding: 5px;
}
.tooltipcontainer{
box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
border-radius: 15px;
width: 359px;
height: 136px;
}
.options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.acerankingoverallcontainer{
  background: #003D4C;
  height:25px;
  font-family: 'FS Siena';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
 text-align: center;
 padding: 15px;
}
.radiotext{
font-family: 'FS Siena';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 13px;
color: #003C4C;
}
.buttonVerbatimText{
  font-family: 'FS Siena';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: #003C4C;
}


.DropdownHeaderVERBATIM{
border: 1px solid #003C4C;
border-radius: 30px;
margin-right: 20px;
width: 100%;
text-align: center;
height: 7vh;
background: none;
color: inherit;
padding: 0;
font: inherit;
outline: inherit;
margin-right: 20px;
cursor: pointer;
color: #003C4C;
font-family: 'FS Siena';


}
.ant-select{

  margin-right: 20px;

}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto; /* Adjust the height as needed */
  margin-bottom: 50px;
}

.spinner {
  width: 50px; /* Adjust the width of the PNG icon */
  height: 50px; /* Adjust the height of the PNG icon */
  animation: spin 2s linear infinite; /* Adjust the animation duration as needed */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.DropdownHeader{
border-radius: 30px;
margin-right: 20px;
width: 10rem;
text-align: center;
font-family:'FS Siena';
font-style: normal ;
font-weight: 600 ;
font-size: 12px ;
line-height: 13px ;
color: #003C4C;
display: flex;
align-items: center;
justify-content: center;
}
.ant-select-selection-item{
  font-family:'FS Siena'!important;
  font-family: 'FS Siena'!important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 13px !important;
  color: #003C4C !important;
  text-align: center!important;
  display: flex;
align-items: center;
justify-content: center;
}
.ant-select-selector{
	background-color: transparent !important;
	border: none !important;
}
.ant-select-selection-placeholder{
	font-family: 'FS Siena';
	color: #003C4C !important;
	margin-bottom: 5px;
}
.optiondropdowntext{
  font-family:'FS Siena'!important;
  font-family: 'FS Siena'!important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px ;
  line-height: 13px !important;
  color: #003C4C !important;
  text-align:center !importnat
}
.loadmorecontainer{
border: 1px solid #BB6125;
border-radius: 20px;
padding: 5px;
display: flex;
align-items: center;
justify-content: center;
height: 30px;
width:150px;
cursor: pointer;
}
.loadmorebtn{
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	outline: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadmoretxt{
  font-family: 'FS Siena';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 15px;
text-align: center;
color: #BB6125;
padding: 10px;
}
.nodatamsg{
  font-family: 'FS Siena';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 13px;
  color: #003C4C;
  text-align: center;
  padding: 25px;
  flex-wrap:wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sustainabilityText{
font-family: 'FS Siena';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 15px;
color: #003D4C;
}
.linelegendpieview{
  border: 1px solid #BB6125;
  width:150px;
  margin-right: 20px;
}
.my-table {
  width: 115%;
  border-collapse: collapse;
  table-layout: fixed; 
  border: #BB6125; 
}

/* Define the class for th and td elements */
.my-table th, .my-table td {
  padding: 8px;
  text-align: center;
}

/* Define the class for the last column */
.my-table th:last-child, .my-table td:last-child {
  width: 75%;
}

/* Define the class for the first column */
.my-table th:first-child, .my-table td:first-child {
  width: 3%;
}

/* Define the class for the third column */
.my-table th:nth-child(3), .my-table td:nth-child(3) {
  width: 2%; 
}

/* Define the class for the fourth column */
.my-table th:nth-child(4), .my-table td:nth-child(4) {
  width: 19%;
}

/* Define the class for the second, third, and fourth columns */
.my-table th:nth-child(2), .my-table td:nth-child(2) {
  width: 2%;
}

/* Define the class for th elements */
.my-table th {
  font-family: 'FS Siena';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #4e4038;
}

/* Define the class for td elements */
.my-table td {
  font-family: 'FS Siena';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #003C4C;
}

/* Define the class for .key-item within td elements */
.my-table td .key-item {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
}

/* Define the class for .order_key_border */
.order_key_border {
  border-right: solid 2px #BB6125 ;
  

}

.kpiIndex{
  position: relative;
}
.kpiIndex:after{
  content: '';
  height: 25px;
  width: 2px;
  position: absolute;
  right: 0;
  top: 0; 
  background-color:#BB6125; 
}

.survey-item{
  display: flex;
  flex-direction: row;
  padding:10px;
  width:100%;
  height: auto;
}
.left-side-comments{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-self: flex-start;
  width:80%;
  max-width: 80%;
  min-width: 80%;
  padding:10px

}
.order-question{
  width: 100%;
  display: flex;
  flex-direction: row;
}
.order-comment{
 font-size: 10px;
 font-weight: 700;
 font-family: 'FS Siena';
 font-style: italic;
 color:#AE662B ;
 margin-right: 10px;

}
.square {
  width: 10px !important;
  height: 10px !important;
  background-color: #AE662B;
  border-radius: 3px;
}
.question-comment{
  font-size: 13px;
  font-weight: 700;
  font-family: 'FS Siena';
  color:#003C4C ;
  margin-right: 10px;
  width: 80%;
}
.response-comment{
  font-size: 12px;
  font-weight: 400;
  text-align: justify;
  font-family: 'FS Siena';
  color:#909090 ;
  margin-left: 50px;
  width: 80%;
padding-bottom: 15px;
}
.right-side-comments{
  display: flex; 
  flex-direction: column;
  align-items: end;
  margin-top: -60px;
  text-align: right;
  width: 20%;
  max-width: 20%;
  min-width: 20%;
  margin-right: 30px;
}
.store-comment{
  font-size: 12px;
  font-weight: 600;
  font-family: 'FS Siena';
  color:#AE662B ;
  width:150px;
  text-align: right;
 }
 .totalscoreNumber{
  font-family: 'FS Siena';
  font-weight: 600;
  font-size: 64px;
 }
 .gaptotalscore{
  font-family: 'FS Siena';
  font-weight: 600;
  font-size: 20px;
  color:#003C4C;
  margin-left: 10px;
  margin-top: 32px;
 }

.popupClassNameverbatim{
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}


.btnnostyle{
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	outline: inherit;
  margin: 0px;
  cursor: pointer;
}
.commentContainer{
height: 45vh
};
.commentContainer::-webkit-scrollbar  {
  width: 1px;
  height: 1px; /* Reduce the height as desired */
}
.commentContainer::-webkit-scrollbar-thumb {
  background-color: #AE662B; /* Change to your desired color */

 
}

.no-margin-padding p {
  margin: 0px !important;
  padding: 0px!important;
  display: block!important;
  margin-block-start: 0px!important;
  margin-block-end: 0px!important;
  margin-inline-start: 0px!important;
  margin-inline-end: 0px !important;
}

.ant-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color:#9DD3C9 !important;
  border-color:#9DD3C9 !important;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color:#9DD3C9 !important;
  border-color:#9DD3C9 !important;
}
.ant-tree .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
  top: 50%;
  inset-inline-start: 50%;
  width: 8px;
  height: 8px;
  background-color: #9DD3C9 !important;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: "";
}
.hideonPdfPrint{
  display: none !important;
}
.rioverallLegend{
  justify-content:center !important;
  align-items:center !important
}
.barEchart{
  margin-left:0 !important
}
.commentheight{
  height:35vh !important
}
.commentrightsideexortPdt{
  margin-top:0!important
}
.verbatimQuestionWidth{
  width:95% !important
}
.distributionpiepdf{
  overflow-y: hidden!important;
}
.widthCSOTdropdown{
  display: flex !important;
  width:100%!important;
  height:40px !important;
align-items: center !important;
justify-content: center !important;
}
.distrbutionwidgetpiecontainer{
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
}
.distrubutionpielegendcontainer{
  display:flex;
  align-items:center;
  justify-content:space-between;
  flex-direction:row;
  flex-wrap:wrap;
}

</style>`

export const StyleAllQuestion=`
<link type="text/css" rel="stylesheet"
href="https://cdn.jsdelivr.net/npm/@ag-grid-community/styles@31.0.3/ag-grid.css" />
<style type="text/css">
.ag-row {
  border-bottom: 0.7px solid #ebebeb!important;
}
.ag-cell {
  border-bottom: 0.5px solid #ebebeb!important;
}

.custom-legend-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
.custom-legend-wrapper .custom-legend {
  display: inline-block;
  line-height: 0.9rem;
}
.custom-legend-wrapper .custom-legend .legend-symbol-circle {
  width: 0.9rem;
  height: 0.9rem;
  margin-right: 5px;
  border-radius: 50%;
  background: #1db180;
  display: inline-block;
  vertical-align: middle;
}
.custom-legend-wrapper .custom-legend .legend-symbol-square {
  width: 0.9rem;
  height: 0.9rem;
  margin-right: 5px;
  border-radius: 15%;
  background: #1db180;
  display: inline-block;
  vertical-align: middle;
}
.custom-legend-wrapper .custom-legend .legend-text {
  color: #333;
  font-weight: bold;
  font-size: 9pt;
  vertical-align: bottom;
  display: inline-block;
  margin-right: 15px;
}
.custom-legend-border .legend-symbol-square {
  border-left: 0.45rem #fff;
}
.custom-legend-border .legend-symbol-square::before {
  border-left: 0.45rem #fff;
}
.custom-legend-border .legend-symbol-square::after {
  border-left: 0.45rem #fff;
}

.hideonPdfPrint{
  display: none !important;
}
.widgetcontainer {
    display: flex;
    background: white;
    flex-direction: column;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    width: 100%;
    margin-top: 15px;
    height: auto;
}

.nodataContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
}

.headerWidget {
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 1 !important;

}

.widgettitell {
    background: #003D4C;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 25px;
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    width: auto;
    height: 20px;
    font-size: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    padding: 20px;

}

.groubytext {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: #BB6125;
}

.surveysCountText {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #919191;
}

.bar2 {
    width: 48%;
    margin-top: 20px;
}

.funnel {
    margin-top: 20px;
    width: 25%;
    margin-left: 12px;
}

.pie {
    margin-top: 20px;
    width: 25%;
    margin-left: 13px
}

@media (max-width: 600px) {

    /* change this value to match your desired breakpoint */
    .bar2,
    .pie,
    .funnel {
        width: 100%;
        margin-left: 0px;

        /* change the direction of the container to column*/
    }
}

.footerwidget {
    display: flex;
    justify-content: end;
}

.widgetlyout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

}

.xaxislyout {
    width: 7%;
    padding: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.titleaxis {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: #BB6125;
    margin-right: 10px;
    text-align: center;
}

.textaxisstyle {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: #003C4C;
    text-align: center;
}

.custom-pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-self: flex-end;
    margin-top: 20px;
    border: solid 1px #9DD3C9;
    border-radius: 50px;
    width: 400;
}

/* Style the page size options dropdown */
.custom-pagination .ant-select-selection {
    border-color: #9DD3C9;
    background-color: #f0f5ff;
}

/* Style the page size options dropdown on hover */
.custom-pagination .ant-select-selection:hover {
    background-color: #e6f7ff;
}

/* Style the page size options */
.custom-pagination .ant-select-item {
    color: #9DD3C9;
}

/* Style the current page number */
.custom-pagination .ant-pagination-item-active {
    background-color: #9DD3C9;
    border-color: #9DD3C9;
    color: #003D4C;
}

.cercle {
    width: 40px;
    height: 40px;
    font-family: 'FS Siena';
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    text-align: center;
    background-color: #003D4C;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.textincercle {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    line-height: 10px;
    color: #003D4C;
    margin-left: 10px;
    margin-top: 10px;
}

.textwhite {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.ant-tree-show-line .ant-tree-indent-unit:after {
    border-color: #BB6125
}

.ant-tree-show-line .ant-tree-indent-unit:before {
    border-color: #BB6125
}

.ant-tree-switcher-leaf-line:before {
    border-color: #BB6125
}

.ant-tree .ant-tree-switcher-leaf-line:before {
    border-inline-end: 1px solid #BB6125;
}

.ant-tree .ant-tree-switcher-leaf-line:before {
    border-inline-end: 1px solid #BB6125;
}

.legend {
    margin-left: 3%;
    margin-right: 3%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.textdropdownoption {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #003D4C;
    text-align: center;
}

.vertical-line {
    width: 1px;
    height: 10vh;
    background-color: #BB6125;
    margin-top: 20px;

}

.cxevscxctext {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #909090;
    text-align: center;
}

.legendSymbolcontainer {
    border-radius: 10px;
    background: rgba(157, 211, 201, 0.2);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.squard {
    width: 10px;
    height: 10px;
    border-radius: 3px;
}

.legendItems {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.legendcontziner {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #77C8A1;
    width: 100%;
    flex-wrap: wrap;
}

.circlecontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    margin-bottom: -50px;
}

.lyoutpermaide {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
}

.textpieres {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: #003D4C;
    text-align: center;
    margin-top: 5px;
}

.acerankingfunelgroupby {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
}

.acerankingtabletitel {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: #003C4C;
    margin-top: -20px;
}

.acerankingtableindicator {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    color: #003C4C;
    text-align: center;
}

.underline {
    text-align: center;
    height: 2px;
    border: 0;
    width: 30px;
    border-top: 2px solid #BB6125;
}

.answerRanksValue {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #003C4C;
    margin-top: 18px;
}

.vl {
    border-left: 3px solid #BB6125;
    height: 30px;
}

.btninfo {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
    margin-right: 20px;
    cursor: pointer;
}

.titelInfobtn {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
    color: #003C4C;
}

.textinfobtn {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: #003D4C;
    padding: 5px;
}

.tooltipcontainer {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    width: 359px;
    height: 136px;
}

.options-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.acerankingoverallcontainer {
    background: #003D4C;
    height: 25px;
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 15px;
}

.radiotext {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: #003C4C;
}

.buttonVerbatimText {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: #003C4C;
}


.DropdownHeaderVERBATIM {
    border: 1px solid #003C4C;
    border-radius: 30px;
    margin-right: 20px;
    width: 100%;
    text-align: center;
    height: 7vh;
    background: none;
    color: inherit;
    padding: 0;
    font: inherit;
    outline: inherit;
    margin-right: 20px;
    cursor: pointer;
    color: #003C4C;
    font-family: 'FS Siena';


}

.ant-select {

    margin-right: 20px;

}

.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    /* Adjust the height as needed */
    margin-bottom: 50px;
}

.spinner {
    width: 50px;
    /* Adjust the width of the PNG icon */
    height: 50px;
    /* Adjust the height of the PNG icon */
    animation: spin 2s linear infinite;
    /* Adjust the animation duration as needed */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.DropdownHeader {
    border-radius: 30px;
    margin-right: 20px;
    width: 10rem;
    text-align: center;
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: #003C4C;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-select-selection-item {
    font-family: 'FS Siena' !important;
    font-family: 'FS Siena' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 13px !important;
    color: #003C4C !important;
    text-align: center !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-select-selector {
    background-color: transparent !important;
    border: none !important;
}

.ant-select-selection-placeholder {
    font-family: 'FS Siena';
    color: #003C4C !important;
    margin-bottom: 5px;
}

.optiondropdowntext {
    font-family: 'FS Siena' !important;
    font-family: 'FS Siena' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px;
    line-height: 13px !important;
    color: #003C4C !important;
    text-align: center !importnat
}

.loadmorecontainer {
    border: 1px solid #BB6125;
    border-radius: 20px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 150px;
    cursor: pointer;
}

.loadmorebtn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadmoretxt {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: #BB6125;
    padding: 10px;
}

.nodatamsg {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 13px;
    color: #003C4C;
    text-align: center;
    padding: 25px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sustainabilityText {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #003D4C;
}

.linelegendpieview {
    border: 1px solid #BB6125;
    width: 150px;
    margin-right: 20px;
}

.my-table {
    width: 115%;
    border-collapse: collapse;
    table-layout: fixed;
    border: #BB6125;
}

/* Define the class for th and td elements */
.my-table th,
.my-table td {
    padding: 8px;
    text-align: center;
}

/* Define the class for the last column */
.my-table th:last-child,
.my-table td:last-child {
    width: 75%;
}

/* Define the class for the first column */
.my-table th:first-child,
.my-table td:first-child {
    width: 3%;
}

/* Define the class for the third column */
.my-table th:nth-child(3),
.my-table td:nth-child(3) {
    width: 2%;
}

/* Define the class for the fourth column */
.my-table th:nth-child(4),
.my-table td:nth-child(4) {
    width: 19%;
}

/* Define the class for the second, third, and fourth columns */
.my-table th:nth-child(2),
.my-table td:nth-child(2) {
    width: 2%;
}

/* Define the class for th elements */
.my-table th {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #4e4038;
}

/* Define the class for td elements */
.my-table td {
    font-family: 'FS Siena';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #003C4C;
}

/* Define the class for .key-item within td elements */
.my-table td .key-item {
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
}

/* Define the class for .order_key_border */
.order_key_border {
    border-right: solid 2px #BB6125;


}

.kpiIndex {
    position: relative;
}

.kpiIndex:after {
    content: '';
    height: 25px;
    width: 2px;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #BB6125;
}

.survey-item {
    display: flex;
    flex-direction: row;
    padding: 10px;
    width: 100%;
    height: auto;
}

.left-side-comments {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    align-self: flex-start;
    width: 80%;
    max-width: 80%;
    min-width: 80%;
    padding: 10px
}

.order-question {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.order-comment {
    font-size: 10px;
    font-weight: 700;
    font-family: 'FS Siena';
    font-style: italic;
    color: #AE662B;
    margin-right: 10px;

}

.square {
    width: 10px !important;
    height: 10px !important;
    background-color: #AE662B;
    border-radius: 3px;
}

.question-comment {
    font-size: 13px;
    font-weight: 700;
    font-family: 'FS Siena';
    color: #003C4C;
    margin-right: 10px;
    width: 80%;
}

.response-comment {
    font-size: 12px;
    font-weight: 400;
    text-align: justify;
    font-family: 'FS Siena';
    color: #909090;
    margin-left: 50px;
    width: 80%;
    padding-bottom: 15px;
}

.right-side-comments {
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-top: -60px;
    text-align: right;
    width: 20%;
    max-width: 20%;
    min-width: 20%;
    margin-right: 30px;
}

.store-comment {
    font-size: 12px;
    font-weight: 600;
    font-family: 'FS Siena';
    color: #AE662B;
    width: 150px;
    text-align: right;
}

.totalscoreNumber {
    font-family: 'FS Siena';
    font-weight: 600;
    font-size: 64px;
}

.gaptotalscore {
    font-family: 'FS Siena';
    font-weight: 600;
    font-size: 20px;
    color: #003C4C;
    margin-left: 10px;
    margin-top: 32px;
}

.popupClassNameverbatim {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
}


.btnnostyle {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
    margin: 0px;
    cursor: pointer;
}

.commentContainer {
    height: 45vh
}

;

.commentContainer::-webkit-scrollbar {
    width: 1px;
    height: 1px;
    /* Reduce the height as desired */
}

.commentContainer::-webkit-scrollbar-thumb {
    background-color: #AE662B;
    /* Change to your desired color */


}

.no-margin-padding p {
    margin: 0px !important;
    padding: 0px !important;
    display: block !important;
    margin-block-start: 0px !important;
    margin-block-end: 0px !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
}

.ant-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: #9DD3C9 !important;
    border-color: #9DD3C9 !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background-color: #9DD3C9 !important;
    border-color: #9DD3C9 !important;
}

.ant-tree .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
    top: 50%;
    inset-inline-start: 50%;
    width: 8px;
    height: 8px;
    background-color: #9DD3C9 !important;
    border: 0;
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    content: "";
}

.hideonPdfPrint {
    display: none !important;
}

.rioverallLegend {
    justify-content: center !important;
    align-items: center !important
}

.commentheight {
    height: 35vh !important
}

.commentrightsideexortPdt {
    margin-top: 0 !important
}

.verbatimQuestionWidth {
    width: 95% !important
}

.distributionpiepdf {
    overflow-y: hidden !important;
}

.widthCSOTdropdown {
    display: flex !important;
    width: 100% !important;
    height: 40px !important;
    align-items: center !important;
    justify-content: center !important;
}

.distrbutionwidgetpiecontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.distrubutionpielegendcontainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

.ag-theme-alpine {
    --ag-header-background-color: white;
    --ag-odd-row-background-color: white;
    --ag-font-family: "FS Siena";
    --ag-selected-row-background-color: #ebf6f4;
}

.ag-theme-alpine span.ag-icon.ag-icon-desc {
    color: #003d4c;
    font-size: 20px !important;
}

.ag-theme-alpine span.ag-icon.ag-icon-asc {
    color: #003d4c;
    font-size: 20px !important;
}

.ag-theme-alpine span.ag-icon.ag-icon-none {
    display: block !important;
    color: #003d4c;
    font-size: 20px;
}

.ag-theme-alpine .ag-root-wrapper {
    margin-top: "-20px";
}

.ag-theme-alpine .ag-paging-row-summary-panel {
    display: none;
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
    color: #9dd3c9 !important;
}

.ag-theme-alpine .ag-root-wrapper {
    border: none;
    background-color: white;
}

.ag-theme-alpine .ag-header-cell-label {
    justify-content: center;
}

.ag-theme-alpine .ag-header-cell-label .ag-header-cell-text {
    white-space: normal;
}

.ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
    border-top: none !important;
    border-bottom: none !important;
    border-left: none !important;
}

.ag-theme-alpine .ag-header-row {
    color: #003d4c;
    font-size: 12px;
    letter-spacing: 1px;
}

.ag-theme-alpine .ag-row-hover {
    background-color: #ebf6f4;
}

.ag-theme-alpine .ag-header-cell-resize::after {
    background-color: inherit !important;
}

.ag-theme-alpine .ag-cell-wrapper {
    gap: 25px;
    word-wrap: break-word;
    word-break: break-word;
}

.ag-theme-alpine .surveyStatus {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 25px;
}

.ag-theme-alpine .example-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.ag-theme-alpine .example-header .missionIdSearchBox {
    width: 370px;
    border-radius: 20px;
    height: 37px;
}

.ag-theme-alpine .example-header .missionIdSearchBox:hover {
    border-color: #003c4c;
}

.ag-theme-alpine .pageSizePickerSelect {
    height: 24px;
    width: 50px;
    border: 1px solid #003c4c;
    background: white;
    border-radius: 20px;
    text-align: right;
    color: #003d4c;
    font-size: 12px;
}

.ag-theme-alpine .pageSizePickerDetail {
    display: flex;
    width: 200px;
    height: 100%;
    justify-content: space-around;
    align-items: center;
}

.ag-theme-alpine .pageSizePickerDetail .pageSizePickerDetailTxt {
    font-size: 12px;
    font-family: FS Siena;
    font-weight: 600;
    letter-spacing: 1px;
    color: #003d4c;
}

.ag-theme-alpine .pageSizePickerDetail .pageSizePickerDetailTxtTotalCount {
    font-size: 12px;
    font-family: FS Siena;
    font-weight: 600;
    letter-spacing: 1px;
    color: #bb6125;
}

.scoredQuestion {
    --ag-header-background-color: #f8f8f8;
}

.scoredQuestion .ag-header-cell {
    border-right: 1px solid #bb6125;
}

.locationRankingSectionHeader {
    border-right: 1px solid #bb6125;
    text-align: start;
    writing-mode: vertical-rl !important;
    white-space: normal;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.locationRankingSectionHeader .ag-header-cell-text {
    white-space: normal;
}

.locationRankingSectionHeader .ag-header-cell-label {
    justify-content: center;
    text-align: center;
}

.QuestionStatus {
    display: flex;
    gap: 7px;
}

.ambassador-background-color {
    background-color: #92d050;
}

.advocateds-background-color {
    background-color: #cee9b0;
}

.unimpressed-background-color {
    background-color: #e0e0e0;
}

.custom-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.location-ranking-grid .ag-pinned-left-header {
    border-right: 1px solid #bb6125;
}

.location-ranking-grid .ag-header-cell-label {
    justify-content: center;
    align-items: center;
}

.location-ranking-grid .locationRankingQBTCellHeader {
    writing-mode: vertical-rl !important;
    white-space: normal;
    font-weight: 400;
    font-size: 12px;
    font-family: "FS Siena";
    min-width: 30px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.location-ranking-grid .locationRankingQBTCellHeader .ag-header-cell-text {
    padding-left: 5px;
}

.location-ranking-grid .locationRankingQBTHeader {
    writing-mode: vertical-rl;
    border-right: 1px solid #bb6125;
    padding-left: 0px;
    padding-right: 0px;
}

.location-ranking-grid .ag-group-value {
    color: #003c4c;
    font-family: 'FS Siena';
}

.location-ranking-grid .ag-ltr .ag-row-group-indent-1 {
    padding-left: 20px;
    text-align: start;
}

.location-ranking-grid .ag-ltr .ag-row-group-indent-2 {
    padding-left: 40px;
    text-align: start;
}

.location-ranking-grid .ag-ltr .ag-row-group-leaf-indent {
    margin-left: 0px;
}

.location-ranking-grid .ag-cell-wrapper {
    gap: 10px;
}

.first-location-ranking-section {
    background-color: #e1e4e6;
}

.second-location-ranking-section {
    background-color: #e9f5f1;
}

.third-location-ranking-section {
    background-color: #ecd5c2;
}

.fourth-location-ranking-section {
    background-color: #f2f2f2;
}

.fifth-location-ranking-section {
    background-color: #fffee7;
}

.sixth-location-ranking-section {
    background-color: #e1e4e6;
}

.seventh-location-ranking-section {
    background-color: #e9f5f1;
}

.eight-location-ranking-section {
    background-color: #ecd5c2;
}

.default-background-color {
    background-color: #f2f2f2;
}

.surveyExplorer .ag-header-cell-resize::after {
    background-color: #bb6125 !important;
}

.surveyExplorer .ag-cell-wrapper {
    text-align: center;
    word-wrap: break-word;
    gap: 25px;
    word-break: break-word;
}

.IofList .ag-root-wrapper {
    border: 1px solid #bb6125;
}

.IofList .ag-header-cell {
    border-right: 1px solid #bb6125;
    border-bottom: 1px solid #bb6125;
}

.ag-header-row {
    color: #003d4c;
    font-size: 10px;
    letter-spacing: 1px;
}

.QuestionDistributionHeaderFirst {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 0px;
    border-left: 1px solid #babfc7;
    border-right: 1px solid #babfc7;
    border-top: 1px solid #babfc7 !important;
    top: 10px !important;
    background-color: #f8f8f8;
}

.QuestionDistributionHeaderLast {
    border-bottom: 0px;
    border-left: 1px solid #babfc7;
    border-right: 1px solid #babfc7;
    border-top: 0px !important;
    background-color: #f8f8f8;
}

.QuestionDistributionHeader {
    border-right: 1px solid #babfc7;
    border-left: 1px solid #babfc7;
    border-bottom: 0px solid #bb6125;
    border-bottom: 0px;
}

.QuestionDistributionHeader~.QuestionDistributionHeader {
    border-left: 0px;
    border-radius: 0px;
}

.ag-header-cell {
    border-right: 1px solid #babfc7;
    border-bottom: 1px solid #babfc7;
}

.ag-header-group-cell-label {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ag-header-group-text {
    line-height: 20px;
    white-space: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100%;
    text-align: center;
    word-wrap: break-word;
    word-break: break-word;

}

.ag-ltr .ag-row-group {
    align-items: center;
}

.ag-ltr .ag-row-group .ag-row-group-indent-0 {
    padding-left: 10px;
    text-align: start;
}

.ag-ltr .ag-row-group .ag-row-group-indent-1 {
    padding-left: 30px;
    text-align: start;
}

.ag-ltr .ag-row-group-indent-2 {
    padding-left: 50px;
    text-align: start;
}

.ag-ltr .ag-row-group-indent-3 {
    padding-left: 70px;
    text-align: start;
}

.ag-ltr .ag-group-contracted {
    margin-right: 15px;
}

.ag-ltr .ag-row-level-1 .ag-row-group-leaf-indent {
    margin-left: 20px;
    text-align: start;
}

.ag-row .ag-cell {
    border-right: 1px solid white;
    display: flex;
    align-items: center;
    line-height: normal;
    white-space: normal;
    text-align: center;
    word-wrap: break-word;
    word-break: break-word;
}

.ag-header {
    border-bottom: 0px;
}

.ag-header-cell,
.ag-header-group-cell {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.ag-header-row-column-group {
    margin-left: -1px;
}

.ag-header-group-cell {
    margin-bottom: 0px;
}

.ag-header-group-cell-no-group {
    border: 0px;
}

.ag-header-group-cell-with-group {
    min-width: 66px;
}

.ag-header-row:last-child {
    background-color: #f8f8f8;
}

.ag-header-cell-label .ag-header-cell-text {
    width: 100%;
    text-align: center;
    white-space: normal;
}

.ag-header-group-cell-with-group:last-child:not(:first) {
    border-top-right-radius: 5px;
}

.ag-pinned-left-header {
    border-right: 0px;
}

.ag-cell-wrapper {
    gap: 5px;
}
</style>`
export const StyleLocation=`
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/@ag-grid-community/styles@31.0.3/ag-grid.css" />

<style type="text/css">

.hideonPdfPrint{
  display: none !important;
}
.ag-row {
  border-bottom: 0.7px solid #ebebeb!important;
}
.ag-cell {
  border-bottom: 0.5px solid #ebebeb!important;
}

.widgetcontainer {
  display: flex;
  background: white;
  flex-direction: column;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  width: 100%;
  margin-top: 15px;
  height: auto;
}

.nodataContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

.headerWidget {
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 1 !important;

}

.widgettitell {
  background: #003D4C;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 25px;
  font-family: 'FS Siena';
  font-style: normal;
  font-weight: 600;
  width: auto;
  height: 20px;
  font-size: 16px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  padding: 20px;

}

.custom-legend-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
.custom-legend-wrapper .custom-legend {
  display: inline-block;
  line-height: 0.9rem;
}
.custom-legend-wrapper .custom-legend .legend-symbol-circle {
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  background: #1db180;
  display: inline-block;
  vertical-align: middle;
}
.custom-legend-wrapper .custom-legend .legend-symbol-square {
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 15%;
  background: #1db180;
  display: inline-block;
  vertical-align: middle;
}
.custom-legend-wrapper .custom-legend .legend-text {
  color: #333;
  font-weight: bold;
  font-size: 9pt;
  vertical-align: bottom;
  display: inline-block;
  margin-right: 15px;
}
.custom-legend-border .legend-symbol-square {
  border-left: 0.45rem #fff;
}
.custom-legend-border .legend-symbol-square::before {
  border-left: 0.45rem #fff;
}
.custom-legend-border .legend-symbol-square::after {
  border-left: 0.45rem #fff;
}

 .ag-theme-alpine {
	 --ag-header-background-color: white;
	 --ag-odd-row-background-color: white;
	 --ag-font-family: "FS Siena";
	 --ag-selected-row-background-color: #ebf6f4;
}
 .ag-theme-alpine span.ag-icon.ag-icon-desc {
	 color: #003d4c;
	 font-size: 20px !important;
}
 .ag-theme-alpine span.ag-icon.ag-icon-asc {
	 color: #003d4c;
	 font-size: 20px !important;
}
 .ag-theme-alpine span.ag-icon.ag-icon-none {
	 display: block !important;
	 color: #003d4c;
	 font-size: 20px;
}
 .ag-theme-alpine .ag-root-wrapper {
	 margin-top: "-20px";
}
 .ag-theme-alpine .ag-paging-row-summary-panel {
	 display: none;
}
 .ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
	 color: #9dd3c9 !important;
}
 .ag-theme-alpine .ag-root-wrapper {
	 border: none;
	 background-color: white;
}
 .ag-theme-alpine .ag-header-cell-label {
	 justify-content: center;
}
 .ag-theme-alpine .ag-header-cell-label .ag-header-cell-text {
	 white-space: normal;
}
 .ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
	 border-top: none !important;
	 border-bottom: none !important;
	 border-left: none !important;
}
 .ag-theme-alpine .ag-header-row {
	 color: #003d4c;
	 font-size: 12px;
	 letter-spacing: 1px;
}
 .ag-theme-alpine .ag-row-hover {
	 background-color: #ebf6f4;
}
 .ag-theme-alpine .ag-header-cell-resize::after {
	 background-color: inherit !important;
}
 .ag-theme-alpine .ag-cell-wrapper {
	 gap: 25px;
	 word-wrap: break-word;
	 word-break: break-word;
}
 .ag-theme-alpine .surveyStatus {
	 display: flex;
	 justify-content: space-around;
	 align-items: center;
	 gap: 25px;
}
 .ag-theme-alpine .example-header {
	 display: flex;
	 align-items: center;
	 justify-content: space-between;
	 padding: 20px;
}
 .ag-theme-alpine .example-header .missionIdSearchBox {
	 width: 370px;
	 border-radius: 20px;
	 height: 37px;
}
 .ag-theme-alpine .example-header .missionIdSearchBox:hover {
	 border-color: #003c4c;
}
 .ag-theme-alpine .pageSizePickerSelect {
	 height: 24px;
	 width: 50px;
	 border: 1px solid #003c4c;
	 background: white;
	 border-radius: 20px;
	 text-align: right;
	 color: #003d4c;
	 font-size: 12px;
}
 .ag-theme-alpine .pageSizePickerDetail {
	 display: flex;
	 width: 200px;
	 height: 100%;
	 justify-content: space-around;
	 align-items: center;
}
 .ag-theme-alpine .pageSizePickerDetail .pageSizePickerDetailTxt {
	 font-size: 12px;
	 font-family: FS Siena;
	 font-weight: 600;
	 letter-spacing: 1px;
	 color: #003d4c;
}
 .ag-theme-alpine .pageSizePickerDetail .pageSizePickerDetailTxtTotalCount {
	 font-size: 12px;
	 font-family: FS Siena;
	 font-weight: 600;
	 letter-spacing: 1px;
	 color: #bb6125;
}
 .scoredQuestion {
	 --ag-header-background-color: #f8f8f8;
}
 .scoredQuestion .ag-header-cell {
	 border-right: 1px solid #bb6125;
}
 .locationRankingSectionHeader {
	 border-right: 1px solid #bb6125;
	 text-align: start;
	 writing-mode: vertical-rl !important;
	 white-space: normal;
	 padding-left: 0px !important;
	 padding-right: 0px !important;
}
 .locationRankingSectionHeader .ag-header-cell-text {
	 white-space: normal;
}
 .locationRankingSectionHeader .ag-header-cell-label {
	 justify-content: center;
	 text-align: center;
}
 .QuestionStatus {
	 display: flex;
	 gap: 7px;
}
 .ambassador-background-color {
	 background-color: #92d050;
}
 .advocateds-background-color {
	 background-color: #cee9b0;
}
 .unimpressed-background-color {
	 background-color: #e0e0e0;
}
 .custom-overlay {
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 height: 100%;
}
  .ag-pinned-left-header {
	 border-right: 1px solid #bb6125;
}
  .ag-header-cell-label {
	 justify-content: center;
	 align-items: center;
}
  .locationRankingQBTCellHeader {
	 writing-mode: vertical-rl !important;
	 white-space: normal;
	 font-weight: 400;
	 font-size: 12px;
	 font-family: "FS Siena";
	 min-width: 30px;
	 padding-left: 0px !important;
	 padding-right: 0px !important;
}
  .locationRankingQBTCellHeader .ag-header-cell-text {
	 padding-left: 5px;
}
  .locationRankingQBTHeader {
	 writing-mode: vertical-rl;
	 border-right: 1px solid #bb6125;
	 padding-left: 0px;
	 padding-right: 0px;
}
  .ag-group-value {
	 color: #003c4c;
	 font-family: 'FS Siena';
}
  .ag-ltr .ag-row-group-indent-1 {
	 padding-left: 20px;
	 text-align: start;
}
  .ag-ltr .ag-row-group-indent-2 {
	 padding-left: 40px;
	 text-align: start;
}
  .ag-ltr .ag-row-group-leaf-indent {
	 margin-left: 0px;
}
  .ag-cell-wrapper {
	 gap: 10px;
}
.ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
  padding-top: 10px;
  border-right: var(--ag-borders-critical) var(--ag-border-color);
}

 .first-location-ranking-section {
	 background-color: #e1e4e6;
}
 .second-location-ranking-section {
	 background-color: #e9f5f1;
}
 .third-location-ranking-section {
	 background-color: #ecd5c2;
}
 .fourth-location-ranking-section {
	 background-color: #f2f2f2;
}
 .fifth-location-ranking-section {
	 background-color: #fffee7;
}
 .sixth-location-ranking-section {
	 background-color: #e1e4e6;
}
 .seventh-location-ranking-section {
	 background-color: #e9f5f1;
}
 .eight-location-ranking-section {
	 background-color: #ecd5c2;
}
 .default-background-color {
	 background-color: #f2f2f2;
}

  .ag-header-row {
	 color: #003d4c;
	 font-size: 10px;
	 letter-spacing: 1px;
}

 
</style>`