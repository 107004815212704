// @flow
import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';
import { composeWithDevTools } from 'redux-devtools-extension';
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

export function configureStore() {
  const enhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );
  const store = createStore(reducers,enhancer);
  sagaMiddleware.run(sagas);
  return store;
}
