// @flow
/* eslint-disable  */

import { Cookies } from 'react-cookie';
import {
  DASHBOARD_ID_COOKIE,
  TOKEN_COOKIE_NAME,
  LANGUAGE_COOKIE_NAME,
  CLIENT_LOGO_COOKIE,
  CLIENT_ID_COOKIE,
  CHANGELANG_COOKIE_NAME,
} from '../constants/cookie-names';
import jwt_decode from 'jwt-decode';
import { ACCESS_LEVELS, USER_RIGHTS } from '../constants/user-rights';
/**
 * Checks if user is authenticated
 */
export const isUserAuthenticated = (): boolean => {
  const cookies = new Cookies();
  const token: string | undefined = cookies.get(TOKEN_COOKIE_NAME);

  if (!token) {
    return false;
  }

  const decodedToken = jwt_decode(token);
  const tokenExpireDate = decodedToken?.exp;
  const currentTime = Date.now() / 1000;

  if (currentTime >= tokenExpireDate) {
    return false;
  }

  return true;
};

export const getClientId = () => {
  const cookies = new Cookies();
  const clientId = cookies.get(CLIENT_ID_COOKIE);
  return clientId;
};
/**
 * Returns the logged in user
 */
export const getLoggedInUser = () => {
  const cookies = new Cookies();
  const user = cookies.get(TOKEN_COOKIE_NAME);
  return user ? jwt_decode(user) : null;
};
export const getUserSetting = () => {
  const cookies = new Cookies();
  const settings = cookies.get(CLIENT_LOGO_COOKIE);
  return settings ? settings : null;
};
export const getUserType = () => {
  const cookies = new Cookies();
  const user = jwt_decode(cookies.get(TOKEN_COOKIE_NAME));
  return user ? user.userType : null;
};

export const getUser = () => {
  const cookies = new Cookies();
  const user = jwt_decode(cookies.get(TOKEN_COOKIE_NAME));
  return user;
};

// export const getUserRights = () => {
//     const cookies = new Cookies();
//     const user = cookies.get(USER_COOKIE_NAME);
//     const permissions = user.permissinos || cookies.get(USER_PERMISSIONS_COOKIE_NAME);
//     return permissions || [];
// };
export const getUserRights = () => {
  const tab = [];
  const cookies = new Cookies();
  const Token = jwt_decode(cookies.get(TOKEN_COOKIE_NAME));
  const accesLevel = Object.keys(ACCESS_LEVELS);

  accesLevel.forEach((el, key) => {
    if (Object.keys(Token).includes(el.toLowerCase())) {
      let v = Token[el.toLowerCase()];
      if (Array.isArray(v)) {
        v.forEach((m) => tab.push({ type: el, value: m }));
      } else {
        [v].forEach((m) => tab.push({ type: el, value: m }));
      }
    }
  });

  const permissions = tab;
  return permissions || [];
};
export const getUserToken = (name = TOKEN_COOKIE_NAME) => {
  const cookies = new Cookies();
  const Token = cookies.get(name);
  return Token ? Token : null;
};

export const removeUserToken = () => {
  const cookies = new Cookies();
  cookies.remove(TOKEN_COOKIE_NAME);
};

export const hasAllRights = (rights, userRights) =>
  rights.reduce((result, right) => {
    const found = userRights.find(
      // Full match
      (x) =>
        (x.type === right.type && x.value === right.value) ||
        // Has EDIT but VIEW is required (EDIT includes VIEW)
        (x.value === right.value && x.type === ACCESS_LEVELS.EDIT && right.type === ACCESS_LEVELS.VIEW) ||
        // ADMIN
        (x.type === ACCESS_LEVELS.FULL && x.value === USER_RIGHTS.EVERYTHING)
    );
    return result && userRights && found;
  }, true);

export const getLangFromCookies = () => {
  const cookies = new Cookies();
  const lang = cookies.get(LANGUAGE_COOKIE_NAME);
  return lang || 4;
};
export const setLangInCookies = async (id, path: string = '/') => {
  const cookies = new Cookies();

  cookies.set(LANGUAGE_COOKIE_NAME, `${id}`, { path });
};

export const setChangeLangCookies = (change, path = '/') => {
  const cookies = new Cookies();

  cookies.set(CHANGELANG_COOKIE_NAME, `${change}`, { path });
};
export const getChangeLangCookies = () => {
  const cookies = new Cookies();
  const change = cookies.get(CHANGELANG_COOKIE_NAME);
  return change || false;
};
export const getDashboardPageId = () => {
  const cookies = new Cookies();
  const dashboardId = cookies.get(DASHBOARD_ID_COOKIE);
  return dashboardId ? dashboardId : null;
};
