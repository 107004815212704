import { useSelector } from 'react-redux';
import './loader.scss';
import LoaderIcon from '../../assets/images/CXGAnimationLoader.gif';

export const LoadingIcon: React.FC<{ width: string; height: string }> = ({ width, height }) => {
  return <img alt="LoaderIcon" src={LoaderIcon} width={width} height={height} />;
};

export const Loading = () => {
  return (
    <div className="app-loader">
      <LoadingIcon width="auto" height="auto" />
    </div>
  );
};

const Loader = () => {
  const loader = useSelector((state: any) => state.loaderReducer.isLoading);

  if (loader) {
    return <Loading />;
  }
  return null;
};

export default Loader;
