import { StatusToast } from 'pages/components/modals/toastMassage';
import {
  CHANGE_LANGUAGE,
  REQUEST_LABELS_TRANSLATIONS,
  GET_REPORTING_LANGUAGE,
  GET_REPORTING_LANGUAGE_SUCCESS,
  REQUEST_APP_VERSION,
  INIT_NAVIGATION,
  REQUEST_CONTACT_EMAIL,
  REQUEST_APP_VERSION_SUCCESS,
  REQUEST_CONTACT_EMAIL_SUCCESS,
  POST_EMAIL,
  POST_EMAIL_SUCCESS,
  SHOW_MESSAGE,
  SHOW_MESSAGE_SUCCESS,
  CLOSE_MESSAGE,
  CLOSE_MESSAGE_SUCCESS,
  START_LOADING,
  STOP_LOADING,
  GET_REPORTING_PAGES,
  GET_REPORTING_PAGES_SUCCESS,
  START_GLOBAL_LOADING,
  STOP_GLOBAL_LOADING,
  INIT_APP,
  CLEAN_REPORTING_PAGES,
  INTI_HOME,
  CHANGE_LANGUAGE_SUCCESS,
  INIT_CLIENTS,
  INIT_CLIENTS_SUCCESS,
  START_STORE_LOADING,
  STOP_STORE_LOADING,
  GET_ALL_LANGUAGE,
  GET_ALL_LANGUAGE_SUCCESS,
  GET_DYNAMIC_REPORTS,
  GET_DYNAMIC_REPORTS_SUCCESS,
  REQUEST_USER_ROLES,
  REQUEST_USER_ROLES_SUCCESS,
} from './type';

export const changeLanguage = (id: number, lang: string) => ({
  type: CHANGE_LANGUAGE,
  payload: {
    id,
    lang,
  },
});
export const changeLanguageSuccess = (id: number) => ({
  type: CHANGE_LANGUAGE_SUCCESS,
  payload: {
    id,
  },
});
export const requestLabelsTranslations = (payload: any) => ({
  type: REQUEST_LABELS_TRANSLATIONS,
  payload,
});
export const requestAllLanguage = () => ({
  type: GET_ALL_LANGUAGE,
});
export const requestAllLanguageSuccess = (payload: any) => ({
  type: GET_ALL_LANGUAGE_SUCCESS,
  payload: payload,
});
export const requestDynamicReports = (payload: { id: String }) => ({
  type: GET_DYNAMIC_REPORTS,
  payload,
});
export const requestDynamicReportsSuccess = (payload: any) => ({
  type: GET_DYNAMIC_REPORTS_SUCCESS,
  payload: payload,
});


export const requestReportingLanguage = (payload: { id: String }) => ({
  type: GET_REPORTING_LANGUAGE,
  payload,
});
export const requestReportingLanguageSuccess = (payload: any) => ({
  type: GET_REPORTING_LANGUAGE_SUCCESS,
  payload: payload,
});
export const requestAppVersion = () => ({
  type: REQUEST_APP_VERSION,
});
export const requestAppVersionSuccess = (payload: any) => ({
  type: REQUEST_APP_VERSION_SUCCESS,
  payload: payload.versioningTranslations,
});

export const requestContactEmail = () => {
  return {
    type: REQUEST_CONTACT_EMAIL,
  };
};
export const requestContactEmailSuccess = (payload: any) => {
  return {
    type: REQUEST_CONTACT_EMAIL_SUCCESS,
    payload: payload,
  };
};

export const postEmail = (payload: any, onSuccess = () => {},onFail = () => {}) => {
  return {
    type: POST_EMAIL,
    payload: payload,
    onSuccess : onSuccess,
    onFail : onFail,
  };
};
export const postEmailSuccess = (payload: any) => {
  return {
    type: POST_EMAIL_SUCCESS,
    payload: payload,
  };
};
/***toast message */
export const showToastMessage = (payload: { type: string; message: string; title: string; isOpen: boolean;status : StatusToast }) => ({
  type: SHOW_MESSAGE,
  payload: payload,
});
export const showMessageSuccess = (payload: any) => ({
  type: SHOW_MESSAGE_SUCCESS,
  payload,
});
export const closeToastMessage = () => ({
  type: CLOSE_MESSAGE,
});
export const closeToastMessageSuccess = (payload: any) => ({
  type: CLOSE_MESSAGE_SUCCESS,
  payload,
});

export const startLoading = () => ({
  type: START_LOADING,
});
export const stopLoading = () => ({
  type: STOP_LOADING,
});
export const startStoreLoading = () => ({
  type: START_STORE_LOADING,
});
export const stopStoreLoading = () => ({
  type: STOP_STORE_LOADING,
});
export const startGlobalLoading = () => ({
  type: START_GLOBAL_LOADING,
});
export const stopGlobalLoading = () => ({
  type: STOP_GLOBAL_LOADING,
});
export const getReportingPages = () => ({
  type: GET_REPORTING_PAGES,
});

export const getReportingPagesSuccess = (payload: any) => ({
  type: GET_REPORTING_PAGES_SUCCESS,
  payload,
});

export const cleanReportingPages = () => ({
  type: CLEAN_REPORTING_PAGES,
});

export const initNavigation = (payload: any) => ({
  type: INIT_NAVIGATION,
  payload,
});

export const initApp = () => ({
  type: INIT_APP,
});

export const initHome = (clientId: string) => ({
  type: INTI_HOME,
  clientId,
});

export const initClients = () => ({
  type: INIT_CLIENTS,
});
export const initClientsSuccess = (payload: any) => ({
  type: INIT_CLIENTS_SUCCESS,
  payload,
});
export const requestUserRoles = (params:any) => ({
  type: REQUEST_USER_ROLES,
  params
});

export const requestUserRolesSuccess = (payload:any) => ({
  type: REQUEST_USER_ROLES_SUCCESS,
  payload
});