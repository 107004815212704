import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, List, Typography, Input } from 'antd';
import { NoData, SearchIcon } from 'assets/images';
import { CheckAllSearchIcon } from 'assets/images';
import { changeFilterData, onChangeFilterModel } from 'redux/actions';
import { upsertBy, findBy, hasValues, removeSpecialCharacters } from 'helpers/index';
import ListLoading from './listLoading';
import Translate, { TranslateToString } from 'pages/commons/Translate';
import { LabelsTranslationsContext } from 'pages/commons/Context';
import { FILTER_MODEL_DATA_STRUCTURE, GLOBAL_FILTER_TRANSLATIONS_PREFIX } from '../types';
import { checkMethodByDataStructure, unCheckAllMethodByDataStructure, unCheckMethodByDataStructure } from '../utils';
const { Text } = Typography;

type Props = {
  filterSettings: any;
  checkAllRef: React.MutableRefObject<boolean>;
};

const CommunSelectFilter: React.FC<Props> = ({ filterSettings, checkAllRef }) => {
  const dispatch = useDispatch();
  const { id, key, modelKey, modelDataStructure } = filterSettings;
  const LabelsTranslations = useContext(LabelsTranslationsContext);
  const filterData = useSelector((state: any) => state.Filter.filtersData[id]);
  const filtersDataInstance = useSelector((state: any) => state.Filter.filtersDataInstance[id]);
  const model = useSelector((state: any) => state.Filter.filterModel[modelKey]);
  const generalTemplateSettings = useSelector((state: any) => state.Filter.generalTemplateSettings);
  const isLoading = useSelector((state: any) => state.Filter.loadingFilters[id]?.status);

  const filterModel = findBy(generalTemplateSettings?.listFilters, 'filterId', id);
  const selectedOptions = filterModel?.filterProperties;
  const listFilters = generalTemplateSettings?.listFilters;

  const handleOnChange = (e: any, item: any) => {
    let value = model || [];
    let filterProperties = findBy(listFilters, 'filterId', filterData?.filterId, 'filterProperties', []);
    let data = { ...filterData, filterProperties };
    if (e.target.checked) {
      const checkMethod = checkMethodByDataStructure[modelDataStructure];
      value = checkMethod(value, item, key);
      filterProperties = [...filterProperties, item];
    } else {
      checkAllRef.current = false;
      const unCheckMethod = unCheckMethodByDataStructure[modelDataStructure];
      if (modelDataStructure === FILTER_MODEL_DATA_STRUCTURE.REGULAR && !hasValues(value)) {
        value = filtersDataInstance?.filterProperties || [];
      }
      if (modelDataStructure === FILTER_MODEL_DATA_STRUCTURE.OBJECT && !hasValues(value[key])) {
        value = { ...value, [key]: filtersDataInstance?.filterProperties || [] };
      }
      if (modelDataStructure === FILTER_MODEL_DATA_STRUCTURE.COMPOUND_OBJECT && !hasValues(value.string?.[key])) {
        value = { string: { ...(value.string || []), [key]: filtersDataInstance?.filterProperties || [] } };
      }
      value = unCheckMethod(value, item, key);
      filterProperties = hasValues(filterProperties) ? filterProperties : filtersDataInstance?.filterProperties || [];
      filterProperties = filterProperties.filter((p: any) => p.id !== item.id);
    }
    data = upsertBy(listFilters, { ...filterData, filterProperties }, 'filterId');
    const filtredData = data.filter((d: any) => hasValues(d.filterProperties));
    if (filterData?.filterProperties?.length === filterProperties?.length) {
      handleOnSelectAll();
    } else {
      dispatch(onChangeFilterModel(modelKey, value, filtredData));
    }
  };

  const handleOnSelectAll = () => {
    let value = model || [];
    checkAllRef.current = !checkAllRef.current;
    const uncheckAllMethod = unCheckAllMethodByDataStructure[modelDataStructure];
    value = uncheckAllMethod(value, key);
    dispatch(
      onChangeFilterModel(
        modelKey,
        value,
        generalTemplateSettings?.listFilters.filter((el: any) => el.filterId !== id)
      )
    );
  };

  const getItemTranslation = (text: string): string => {
    const translationKey = `${GLOBAL_FILTER_TRANSLATIONS_PREFIX}.${removeSpecialCharacters(key.toLowerCase())}.${removeSpecialCharacters(
      text?.toLowerCase()
    )}`;
    const result = findBy(LabelsTranslations, 'fullKey', translationKey, 'defaultValue', text);
    return result;
  };

  const onTextChange = (e: any) => {
    const query = e.target.value;
    let searchList = filterData?.filterProperties?.filter((item: any) =>
      getItemTranslation(item.resultItems).toLowerCase().includes(query.toLowerCase())
    );
    dispatch(changeFilterData(id, { ...filtersDataInstance, filterProperties: searchList }));
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: 5,
        }}>
        <CheckAllSearchIcon onClick={handleOnSelectAll} style={{ width: 20, cursor: 'pointer' }} />
        <Input
          className="filter-item__input-search"
          placeholder={TranslateToString({ id: 'generic.search' })}
          prefix={<SearchIcon />}
          style={{
            borderRadius: '25px',
            marginLeft: '10px',
            flex: 1,
            width: 240,
          }}
          onChange={onTextChange}
          autoFocus={false}
        />
      </div>
      {isLoading && <ListLoading size={'large'} />}
      {hasValues(filtersDataInstance?.filterProperties) && !isLoading && (
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginTop: '10px',
            marginLeft: 8,
          }}>
          <List
            style={{ borderColor: 'white', paddingInline: 0 }}
            loading={isLoading}
            size="small"
            itemLayout="horizontal"
            bordered
            dataSource={filtersDataInstance?.filterProperties || []}
            renderItem={(item: any, index: number) => (
              <List.Item
                key={index}
                className="cxg-text"
                style={{
                  justifyContent: 'flex-start',
                  paddingInline: 0,
                  borderBottomColor: 'white',
                }}>
                <Checkbox
                  checked={!!checkAllRef.current || selectedOptions?.findIndex((ele: any) => ele.id === item.id) > -1}
                  onChange={(e) => {
                    handleOnChange(e, item);
                  }}></Checkbox>
                <Text className="cxg-text listText">
                  <Translate
                    id={`${GLOBAL_FILTER_TRANSLATIONS_PREFIX}.${removeSpecialCharacters(key.toLowerCase())}.${removeSpecialCharacters(
                      item.resultItems?.toLowerCase()
                    )}`}
                    defaultMessage={item.resultItems}
                  />
                </Text>
              </List.Item>
            )}
          />
        </div>
      )}
      {hasValues(filterData?.filterProperties) && !hasValues(filtersDataInstance?.filterProperties) && !isLoading && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
          <NoData />
          <div style={{ color: '#003C4c', fontFamily: 'FS Siena', fontSize: '12px', marginTop: '5px' }}>
            <Translate id="generic.nodataavailable" defaultMessage="No Data Available" />
          </div>
        </div>
      )}
    </>
  );
};

export default CommunSelectFilter;
