/* eslint-disable eqeqeq */
import React, { useState, useRef } from 'react';
import { Typography, Space, Button } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import '../../styles.scss';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { convertToTimestamp } from '../../utils';
import { removeSpecialCharacters } from 'helpers/index';
import Translate from 'pages/commons/Translate';

const { Text } = Typography;
dayjs.extend(customParseFormat);
type Props = { start: number; end: number; handleChange: (from: number | null, to: number | null) => void };
const Demo: React.FC<Props> = ({ start, end, handleChange }) => {
  const yearRef = useRef([0, 0]);
  const [currentYear, setCurrentYear] = useState(moment().format('YYYY'));
  const listData = Array.from({ length: 4 }).map((_, i) => `Quarter ${i + 1}`);

  const addYear = () => {
    let newYear: any = moment(currentYear).add(1, 'year');
    setCurrentYear(newYear);
  };
  const removeYear = () => {
    let newYear: any = moment(currentYear).subtract(1, 'year');
    setCurrentYear(newYear);
  };

  const onHandleChange = (index: number) => {
    const startValue = convertToTimestamp(
      moment(currentYear).startOf('year').startOf('quarter').add(index, 'quarter').format('D MMM YYYY')
    );
    const endValue = convertToTimestamp(moment(currentYear).startOf('year').endOf('quarter').add(index, 'quarter').format('D MMM YYYY'));

    if (yearRef.current?.[0] == 0 && yearRef.current?.[1] == 0) {
      handleChange(startValue, endValue);
      yearRef.current = [startValue, endValue];
    } else if (yearRef.current?.[0] == startValue || yearRef.current?.[1] == endValue) {
      handleChange(startValue, endValue);
      yearRef.current = [startValue, endValue];
    } else if (startValue > yearRef.current?.[0] && endValue < yearRef.current?.[1]) {
      handleChange(startValue, endValue);
      yearRef.current = [startValue, endValue];
    } else if (startValue == yearRef.current?.[0] && endValue == yearRef.current?.[1]) {
      handleChange(startValue, endValue);
      yearRef.current = [startValue, endValue];
    } else {
      const minPeriod = Math.min(startValue, start);
      const maxPeriod = Math.max(endValue, end);
      handleChange(minPeriod, maxPeriod);
      yearRef.current = [minPeriod, maxPeriod];
    }
  };

  const getButtonClassName = (index: number) => {
    const startTimestamp = convertToTimestamp(
      moment(currentYear).startOf('year').startOf('quarter').add(index, 'quarter').format('D MMM YYYY')
    );
    const endTimestamp = convertToTimestamp(
      moment(currentYear).startOf('year').endOf('quarter').add(index, 'quarter').format('D MMM YYYY')
    );
    if (startTimestamp == start && endTimestamp == end) {
      return 'single';
    }
    if (startTimestamp == start && endTimestamp < end) {
      return 'start-range';
    }
    if (startTimestamp > start && endTimestamp == end) {
      return 'end-range';
    }
    if (startTimestamp > start && endTimestamp < end) {
      return 'selected-range';
    }
    return 'item';
  };

  const renderQuarterItem = (value: any, index: number) => {
    return (
      <Button key={value} onClick={() => onHandleChange(index)} className={getButtonClassName(index)} title={getButtonClassName(index)}>
        <Translate id={`generic.quarters.${removeSpecialCharacters(value?.toLowerCase())}`} defaultMessage={value} />
      </Button>
    );
  };

  return (
    <Space
      direction="vertical"
      size={20}
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Space size={48} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CaretLeftOutlined style={{ fontSize: 20, color: '#BB6125' }} onClick={removeYear} />

        <Text className={'cxg-text'} style={{ fontSize: 14, fontWeight: 'bold', color: '#003C4C', textAlign: 'center' }}>
          {moment(currentYear).format('YYYY')}
        </Text>
        <CaretRightOutlined
          style={{ fontSize: 20, color: moment(currentYear).format('YYYY') < moment().format('YYYY') ? '#BB6125' : 'gray' }}
          onClick={addYear}
        />
      </Space>
      <Space
        size={[0, 10]}
        className="quarter-calendar"
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
        {listData.map((item, index) => renderQuarterItem(item, index))}
      </Space>
    </Space>
  );
};

export default Demo;
