// @flow

import { combineReducers } from 'redux';
import Layout from '../Layout/reducer';
import AuthReducer from '../pages/Auth/reducers';
import loaderReducer from '../features/Loader/reducer';
import Dashboard from '../pages/dashboard/reducer';
import FilterReducer from '../pages/components/filter/reducers';
import surveyExplorerReducer from '../pages/dashboard/widgets/SurveyExplorerTable/reducer';
import LocationRanking from '../pages/dashboard/widgets/LocationRanking/reducer';
import AllQuestionDistribution from '../pages/dashboard/widgets/AllQuestionDataGrid/reducer';
import surveyExplorerEVOCReducer from '../pages/dashboard/widgets/SurveyExplorerTableExternalVOC/reducer';
import AgGridGlobalReducer from '../pages/dashboard/widgets/LocationRanking/AgGridGlobalReducer';
import notificationReducer from '../pages/commons/notifications/reducers'
import TranslationComment from 'pages/dashboard/widgets/Comments/reducer';
import TranslationVerbatim from 'pages/dashboard/widgets/VerbatimAnalysis/reducer';
import IOFSManagementReducer from 'pages/admin/iof-management/redux/reducer';

export default combineReducers({
  loaderReducer,
  Layout,
  Dashboard,
  surveyExplorerReducer,
  LocationRanking,
  AllQuestionDistribution,
  AgGridGlobalReducer,
  Auth: AuthReducer,
  notificationReducer,
  Filter: FilterReducer,
  TranslationComment,
  TranslationVerbatim,
  IOFSManagementReducer,
  surveyExplorerEVOCReducer
});
