import { lazy, Suspense } from 'react';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import RoutesWrapper from './RoutesWrapper';
import InitRoutes from './InitRoutes';
import PrivacyHandler from './PrivacyHandler';

const HomeWrapper = lazy(() => import('pages/home/HomeWrapper'));
const LandingPageView = lazy(() => import('../pages/Auth/LandingPageView'));
const ExperienceReportViewer = lazy(() => import('../pages/dashboard/widgets/SurveyExplorerTable/ExperienceReportViewer'));
const ExperienceReportViewerEVOC = lazy(() => import('../pages/dashboard/widgets/SurveyExplorerTableExternalVOC/ExperienceReportViewerEVOC'));
const Login = lazy(() => import('../pages/Auth/Login'));
const ForgetPassword = lazy(() => import('../pages/Auth/forget-password'));
const RestPassword = lazy(() => import('../pages/Auth/RestPassword'));
// const Clients = lazy(() => import('pages/home/Clients'));
const ReleaseNote = lazy(() => import('pages/home/layouts/VersionReleaseNote'));
// const Clients = lazy(() => import('pages/admin/clients/Clients'));
// const IOFList = lazy(() => import('pages/admin/iof-management/iofList'));
const HomeAdmin = lazy(() => import('pages/admin/clients/homeAdmin'));

function Routing() {
  return (
    <BrowserRouter>
      <RoutesWrapper>
        <InitRoutes>
          <Suspense fallback={<div className="app-loader"></div>}>
            <Routes>
              <Route path="/" element={<Navigate to="/home-admin/clients" replace />} />
              <Route path="/home-admin/*" element={<PrivacyHandler Component={HomeAdmin} />} />
              <Route path="/dashboard" element={<PrivacyHandler Component={HomeWrapper} />} />
              <Route path="/dashboard/page/*" element={<PrivacyHandler Component={HomeWrapper} />} />
              <Route path="/landing-page" element={<PrivacyHandler Component={LandingPageView} />} />
              <Route
                path="/experience-report-viewer/:langId/:clientId/:locationId/:missionId/:isRead/:projectName/:ace"
                element={<PrivacyHandler Component={ExperienceReportViewer} />}
              />
              <Route
                path="/experience-report-viewer/:langId/:clientId/:locationId/:missionId/:isRead/:projectName"
                element={<PrivacyHandler Component={ExperienceReportViewer} />}
              />
              <Route
                path="/experience-report-viewer-evoc/:langId/:clientId/:locationId/:missionId/:isRead/:ace"
                element={<PrivacyHandler Component={ExperienceReportViewerEVOC} />}
              />
              <Route
                path="/experience-report-viewer-evoc/:langId/:clientId/:locationId/:missionId/:isRead"
                element={<PrivacyHandler Component={ExperienceReportViewerEVOC} />}
              />
              <Route path="/release_note/latest" element={<ReleaseNote />} />

              <Route path="/login" element={<Login />} >
                <Route path=":path" element={<Login />} />
              </Route>
              <Route path="/forget-password" element={<ForgetPassword />} >
                <Route path=":path" element={<ForgetPassword />} />
              </Route>
              <Route path="/reset-password" element={<RestPassword />} >
                <Route path=":username" element={<RestPassword />} />
                <Route path=":path/:username" element={<RestPassword />} />
              </Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Suspense>
        </InitRoutes>
      </RoutesWrapper>
    </BrowserRouter>
  );
}

export default Routing;
