import axios, { AxiosInstance } from 'axios'; // Import Axios

import * as QS from 'qs';
import { getUserToken, getLangFromCookies, getClientId, getLoggedInUser } from '../helpers/authUtils';
import { API_ENDPOINTS, BAR_ORIENTATION, CHART_DISPLAY_TYPE, KPI_WIDGET_CHART_VIEW } from 'pages/dashboard/type';
import Auth from 'types/auth.models';
import qs from 'qs';
import { CXG_DOTNET_API, HOSTNAMES_DOTNET_API_MAPPING, SYS_LAST_USED } from 'constants/common';
export const ERT_SOURCES = Object.freeze({
  CXE: 0,
  EXTERNAL_VOC: 1,
});

const getBackendUrl = () => {
  return HOSTNAMES_DOTNET_API_MAPPING[window.location.hostname] || CXG_DOTNET_API.QA;
};

export const api = axios.create({
  baseURL: getBackendUrl(),
});
const api2: AxiosInstance = axios.create({
  baseURL: getBackendUrl(), // Your base URL
  validateStatus: function (status) {
    return status >= 200 && status < 300; // Throw an error for non-2xx status codes
  },
});
// Add a request interceptor to modify the headers

api2.interceptors.request.use((config) => {
  const token = getUserToken();
  // Add the token to the headers
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export const getLangId = () => {
  const lang = getLangFromCookies();
  return lang;
};

export const getTranslation = async (queryParamsObject: any, langId = null) => {
  const mainPath = `api/v1/${langId || getLangId()}/common/FrontLabel/list`;
  const queryString = QS.stringify(queryParamsObject);
  const fullPath = `${mainPath}?${queryString}`;
  return api.get(fullPath);
};

export const saveUsagelogs = async (data: any) => api.get(`api/v1/${getLangId()}/common/UserUsageLogs`, data);

export const UserLogin = async (payload: any,queryParamsObject?:Record<string,any>) => {
  try {
    const mainPath = `api/v1/${getLangId()}/account/usersignin`;
    const queryString = QS.stringify(queryParamsObject);
    const fullPath = `${mainPath}?${queryString}`;
    const response = await api.post(fullPath, payload);

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('API request failed with status: ' + response.status);
    }
  } catch (error) {
    throw error;
  }
};
export const requestAllLanguage = async () => {
  return api.get(`/api/v1/${getLangId()}/common/languages`);
};
export const requestReportingLanguage = async ({ id }: any) => {
  return api2.get(`/api/v1/${getLangId()}/clients/${id}/reportingLanguages`);
};
export const requestDynamicReports = async ({ id }: any) => {
  return api2.get(`/api/v1/${getLangId()}/clients/${id}/dynamicReports`);

};
export const DownloadDynamicReports = async (payload: any) => {
  console.log(payload)
  return api2.post(`/api/v1/${getLangId()}/clients/${payload.clientId}/dynamicReports/${payload.type}`,payload.filter, { responseType: 'blob' });

};
export const requestChangeLanguage = async ({ id }: any, langId: any) => {
  return api2.put(`/api/v1/${getLangId()}/Reporting/${id}/UserClientLanguage`, { id: langId });
};
export const requestForgetPassword = async (body: any) => {
  const email: any = body.email.trim();
  const WebHost: any = body.WebHost;
  console.log('WebHost');

  return api.put(`api/v1/${getLangId()}/account/triggerresetpassword/user`, {
    email,
    WebHost,
  });
};

export const requestResetPassword = async (body: any) => {
  const payload = body.payload;
  return api.put(`api/v1/${getLangId()}/account/resetpassword`, payload);
};
export const getLandingPage = async (clientId: any) => {
  return api2.get(`/api/v1/${getLangId()}/Landing/landingFiles/${clientId}`);
};
export const SetdisplayLpUser = async (userId: any, showAgain: boolean) => {
  return api2.post(`api/v1/${getLangId()}/Landing/${userId}/${showAgain}`);
};
export const GetdisplayLpUser = async (userId: any) => {
  return api2.get(`api/v1/${getLangId()}/Landing/${userId}/showAgain`);
};
export const requestAppVersion = async () => {
  return api2.get(`api/v1/${getLangId()}/VersioningController/GetlastActiveVersion`);
};

export const requestMyProfile = async () => {
  return api2.get(`api/v1/${getLangId()}/common/myprofile`);
};
export const GetDashboard = async (clientId: any) => {
  return api2.get(`api/v1/${getLangId()}/reporting/${clientId}/GetDashboard`);
};

export const requestDashboardClients = async () => {
  return api2.get(`api/v1/${getLangId()}/clients/dashboard`);
};

export const requestRolesData = async () => {
  return api2.get('api/v1/access/roles/list');
};
export const requestallUserRoles = async (userName:any, queryParamsObject:any) => {
  const mainPath = `api/v1/access/user/roles`;
  const queryString = QS.stringify(queryParamsObject);
  const fullPath = `${mainPath}?${queryString}`;
  return api2.get(fullPath);
};
export const GetAgGridData = async (
  settings: any,
  clientId: number,
  endPoint: string,
  searchItem: any,
  pageSize: any,
  pageNumber: any,
  orderBy: any,
  lastFilter: any,
  mergeDataHandler?: any,
  parentSectionName?: any,
  parentId?: any,
  oldData?: any,
  filters?: any,
  expand?: any
) => {
  const pageParameters = {
    filterBy: null,
    orderBy: orderBy,
    pageNumber: pageNumber,
    pageSize: pageSize,
    searchTerm: searchItem,
  };
  console.log("pagess",pageParameters);
  const source = ['CXE'];
  if (!expand) expand = '';
  
  return api2.post(`api/v1/${getLangId()}/reporting/${clientId}/${endPoint}${expand}`, {
    pageParameters,
    settings,
    source,
    mergeDataHandler,
    ...lastFilter,
    parentSectionName,
    parentId,
    oldData,
    filters,
    extendPeriodRange: false,
  });
};
export const GetAgGridDataEVOC = async (
  settings: any,
  clientId: number,
  endPoint: string,
  searchItem: any,
  pageSize: any,
  pageNumber: any,
  orderBy: any,
  lastFilter: any,
  PaginationParams?:any,
  mergeDataHandler?: any,
  parentSectionName?: any,
  parentId?: any,
  oldData?: any,
  filters?: any,
  expand?: any,
) => {
  const pageParameters = {
    filterBy: null,
    orderBy: orderBy,
    pageNumber: pageNumber,
    pageSize: pageSize,
    searchTerm: searchItem,
  };

  const source = ['External_VoC'];
  if (!expand) expand = '';
  return api2.post(`api/v1/${getLangId()}/reporting/${clientId}/${endPoint}${expand}`, {
    pageParameters,
    settings,
    source,
    mergeDataHandler,
    ...lastFilter,
    parentSectionName,
    parentId,
    oldData,
    filters,
    extendPeriodRange: false,
    PaginationParams

  });
};
export const GetLocationRankingById = async (
  settings: any,
  clientId: number,
  endPoint: string,
  searchItem: any,
  pageSize: any,
  pageNumber: any,
  orderBy: any,
  lastFilter: any,
  mergeDataHandler?: any
) => {
  const pageParameters = {
    filterBy: null,
    orderBy: orderBy,
    pageNumber: pageNumber,
    pageSize: pageSize,
    searchTerm: searchItem,
  };

  const source = ['CXE'];
  return api2.post(`api/v1/${getLangId()}/reporting/${clientId}/${endPoint}`, {
    pageParameters,
    settings,
    source,
    mergeDataHandler,
    ...lastFilter,
  });
};

export const changeMissionListStatus = async (clientId: Number, userId: number, status: Number, missionList: any,source:any) => {
  return api2.post(`api/v1/${getLangId()}/reporting/${clientId}/ReadMissions?userId=${userId}&status=${status}`, missionList);
};
export const changeMissionListEVOCStatus = async (clientId: Number, userId: number, status: Number, missionList: any,source:any) => {
  return api2.post(`api/v1/${getLangId()}/reporting/${clientId}/ReadExternalSurveys?userId=${userId}&status=${status}`, missionList);
};
export const requestMissionDetails = async (clientId: Number, missionId: any, data: any) => {
  return api2.post(`api/v1/${getLangId()}/reporting/${clientId}/GetMissionDetails/${missionId}`, data);
};
export const requestDesign = async (clientid: Number) => {
  return api2.get(`api/v1/${getLangId()}/clients/clientdesigns/client/${clientid}`);
};

export const GetWidget = async (
  lastFilter: any,
  settings: any,
  clientId: number,
  endPoint: string,
  series?: any,
  source?: any,
  sectionIds?: any,
  questionWithAnswerIds?: any
) => {
  const period = {
    type: 'month',
    fromDate: 1672531200,
    toDate: 1690761600,
    isWithComparison: true,
  };
  const extendPeriodRange = false;
  if (endPoint === API_ENDPOINTS.NPS_CHART)
    return api2.post(`api/v1/${getLangId()}/reporting/${clientId}/${endPoint}`, { ...lastFilter, settings, series, extendPeriodRange });
  else if (endPoint === API_ENDPOINTS.ACE_TREND)
    return api2.post(`api/v1/${getLangId()}/reporting/${clientId}/${endPoint}`, {
      ...lastFilter,
      settings,
      series,
      source,
      extendPeriodRange,
    });
  else if (endPoint === API_ENDPOINTS.DISTRIBUTION && settings.chartView === CHART_DISPLAY_TYPE.Column && settings.seriesBy != 'None')
    return api2.post(`api/v1/${getLangId()}/reporting/${clientId}/${endPoint}?reverse=true `, {
      ...lastFilter,
      settings,
      series,
      extendPeriodRange,
    });
  else if (
    endPoint === API_ENDPOINTS.KPI_WIDGET &&
    settings.barsOrientation == BAR_ORIENTATION.vertical &&
    settings.chartView == KPI_WIDGET_CHART_VIEW.MultiStackedBar
  )
    return api2.post(`api/v1/${getLangId()}/reporting/${clientId}/${endPoint}?reverse=true `, {
      ...lastFilter,
      settings,
      series,
      extendPeriodRange,
    });
  else if (endPoint === 'SectionsScoreTrend' && settings.chartView === CHART_DISPLAY_TYPE.Column)
    return api2.post(`api/v1/${getLangId()}/reporting/${clientId}/${endPoint}?reverse=true`, {
      ...lastFilter,
      settings,
      source,
      sectionIds,
      questionWithAnswerIds,
      extendPeriodRange,
    });
  return api2.post(`api/v1/${getLangId()}/reporting/${clientId}/${endPoint}`, {
    ...lastFilter,
    settings,
    series,
    source,
    sectionIds,
    questionWithAnswerIds,
    extendPeriodRange,
  });
};

export const requestDashboard = async (clientId: Number, dashboard: any, filters: any, widgetId: Number) => {
  return api2.post(`api/v1/${getLangId()}/reporting/${clientId}/${dashboard}`, filters, { responseType: 'blob' });
};
export const requestExperinceReportDocumentViewerData = async (langId: number, clientId: number, missionId: any, translate: any) => {
  return api2.get(`api/v1/${langId || getLangId()}/reporting/${clientId}/GetMissionView/${missionId}${translate ? '?translate=true' : ''}`);
};
export const requestExperinceReportDocumentViewerVOCData = async (langId: number, clientId: number, missionId: any, translate: any) => {
  const queryString = QS.stringify({ translate: !!translate, source: ERT_SOURCES.EXTERNAL_VOC });
  return api2.get(`api/v1/${langId || getLangId()}/reporting/${clientId}/GetMissionView/${missionId}?${queryString}`);
};

export const downloadAsPdfData = async (clientId: Number, data: any, type: string) => {
  return api2.post(`api/v1/${getLangId()}/reporting/export/${clientId}/ToPdf`, data, { responseType: 'blob' });
};
export const requestContact = async () => {
  return api2.get(`api/v1/${getLangId()}/noms/contacts`);
};
export const sendMessage = async ({ body }: any) => {
  return api2.post(`api/v1/${getLangId()}/account/internalContact`, body);
};
export const getCountryList = () => {
  return api
    .get(`api/v1/${getLangId()}/noms/countries`)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getColorPalette = (idclient: number | string) => {
  return api2.get(`api/v1/${getLangId()}/clients/clientdesigns/client/${idclient}`);
};

export const getSystLastUsed = (idclient?: any) => {
  return api2.get(`api/v1/${getLangId()}/reporting/${getClientId()}/GetFilterProfile/${SYS_LAST_USED}`);
};
// type ProperiesParams = {
//   clientId: string;
//   templateId: string;
//   filterId: any;
//   queryParams: any;
//   body: any;
// };
// export const requestFilterProperties = ({
//   clientId,
//   templateId,
//   filterId,
//   queryParams,
//   body,
// }: ProperiesParams) => {
//   return api2.post(
//     `api/v1/4/templateFilter/${clientId}/filterLibrary/${templateId}/filter/${filterId}?${queryParams}`,
//     body
//   );
// };
export const saveContact = async (payload: any) => {
  return api.post(`api/v1/${getLangId()}/account/contact`, payload.payload);
};
export const nomonclatureFiltred = async (clientId: any, settings: any) => {
  const headers: any = { Withlastusedfilters: true };
  return api2.post(`api/v1/${getLangId()}/reporting/${clientId}/NomenclaturesFiltered`, settings, { headers });
};
export const requestSSOLogin = async (additionalParams: Auth.SSOLogin.SSOLoginPayload) => {
  const params = additionalParams ? `?${QS.stringify(additionalParams)}` : '';
  const response = await api.get(`Sso/authorize${params}`);
  return response;
};

export const requestActiveCompaign = async () => {
  //default campaign
  return api.get(`api/v1/${getLangId()}/Campaigns/defaultcampaign`);
};

export const requestCompaignWithPath = async (path : string) => {
  return api.get(`api/v1/${getLangId()}/Campaigns/path/${path}`);
};

export const requestLoginToken = async (body: Auth.SSOLogin.ISSOUserLogin,queryParamsObject?:Record<string,any>) => {
  try {
    const mainPath = `api/v1/${getLangId()}/account/usersignin`;
    const queryString = QS.stringify(queryParamsObject);
    const fullPath = `${mainPath}?${queryString}`;
    const response = await api.post(fullPath, body);
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    throw e;
  }
};

export const requestLogout = async () => {
  const SSOLogin = localStorage.getItem('isSSO');
  const ssid = localStorage.getItem('SSID');
  const authenticatedUser: any = getLoggedInUser();
  const sessionId = authenticatedUser?.sid;
  const params = QS.stringify({ ...(ssid ? { ssid, returnUrl: window.location.origin } : { sid: sessionId }) });
  const path = `api/v1/${getLangId()}/account/${SSOLogin ? `federatedLogout?${params}` : `logout?${params}`}`;
  const response = await api2.get(path);
  return response?.data;
};
export const requestSessionDetailsData = async (additionalParams: { sessionId: string }) => {
  const params = additionalParams ? `?${QS.stringify(additionalParams)}` : '';
  const response = await api.get(`api/v1/4/account/sessionDetails${params}`);
  return response;
};
/**** Client dashboard get template api *********

 */
export const requestFilterTemplate = async ({ id }: any) => {
  return api2.get(`api/v1/${getLangId()}/templateFilter/${getClientId()}/filterTemplates/dashboardPage/${id}`);
};
export const savefilterProfile = async (Profile: any) => {
  return api2.post(`api/v1/${getLangId()}/reporting/${getClientId()}/SaveFilterProfile/`, Profile);
};

type ProperiesParams = {
  templateId: string;
  filterId: any;
  queryParams: any;
  body: any;
  categoryId?: any;
  endPoint?: string;
};
export const requestFilterProperties = async ({ templateId, filterId, queryParams, body, endPoint }: ProperiesParams) => {
  try {
    const queryString = qs.stringify(queryParams, { indices: false });
    const response = await api2.post(`${endPoint}${filterId}?${queryString}`, body);
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    throw error;
  }
};
export const requestFilterLocationProperties = ({ templateId, filterId, queryParams, body }: ProperiesParams) => {
  return api2.post(
    `api/v1/${getLangId()}/templateFilter/${getClientId()}/filterTemplates/${templateId}/locationFilter/${filterId}?${queryParams}`,
    body
  );
};
type getSurveysParams = {
  templateId: string;
  queryParams: any;
  body: any;
};

export const requestTotalNumberOfSurveys = ({ templateId, queryParams, body }: getSurveysParams) => {
  const queryString = qs.stringify(queryParams, { indices: false });
  return api2.post(`api/v1/${getLangId()}/templateFilter/${getClientId()}/filterTemplates/${templateId}/surveyNumber?${queryString}`, body);
};

type getHearderInfoParams = {
  pageId: string;
  queryParams: any;
  body: any;
};

export const requestHeaderInfo = ({ pageId, queryParams, body }: getHearderInfoParams) => {
  const queryString = qs.stringify(queryParams, { indices: false });
  return api2.post(`api/v1/${getLangId()}/templateFilter/${getClientId()}/page/${pageId}/headerInfo?${queryString}`, body);
};

export const GetCaochingSectionCalls = async (settings: any, clientId: number, source?: any, sectionIds?: any) => {
  const period = {
    type: 'month',
    fromDate: 1672531200,
    toDate: 1690761600,
    isWithComparison: true,
  };
  return api2.post(`api/v1.1/${getLangId()}/reporting/${clientId}/GetCoachingSessionServiceValues`, {
    period,
    settings,
    source,
    sectionIds,
  });
};
export const translateComment = async (text: string, langCode: any) => {
  const formData = new FormData();
  formData.append('languages', langCode);
  formData.append('text', text);
  return api2.post(`api/v1/${getLangId()}/common/Translate`, formData);
};
export const getQbt = async () => {
  return api2.get(`api/v1/${getLangId()}/common/GetBusinessTypes`);
};

export const getImgKpi = async (id: any) => {
  return api2.get(`api/v1/${getLangId()}/clients/dashboard/${getClientId()}/getWidgetIcons/${id}`);
};
export const exportToPdf = async (clientId: any, data: any) => {
  return api2.post(`api/v1/${getLangId()}/reporting/export/${clientId}/ToPdf`, data, { responseType: 'blob' });
};
