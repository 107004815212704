// @flow
import { NavigateFunction } from 'react-router';
import {
  LOGIN_USER,
  UPDATE_USER_SESSION,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_USER,
  REFRESH_TOKEN,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  SET_SHOW_LP_USER,
  GET_SHOW_LP_USER,
  GET_LANDING_PAGE,
  GET_ACTIVE_COMPAIGN,
  GET_ACTIVE_COMPAIGN_SUCCESS,
  GET_LANDING_PAGE_SUCCES,
  GOTO_LANDING_PAGE,
  GOTO_DASHBOARD,
  SAVE_CONTACT,
  SAVE_CONTACT_SUCCESS,
  SAVE_CONTACT_FAIL,
  REQUEST_CHECK_SSO_SESSION,
  LOGIN_USER_FROM_SSO,
  SSO_REDIRECT_SUCCESS,
  LOGIN_USER_BY_SSO,
  GET_SESSION_ID,
  OLD_CONNECT_REDIRECT,
  REQUEST_MY_PROFILE_SUCCESS,
  GET_COMPAIGN_WITH_PATH,
  GET_COMPAIGN_WITH_PATH_SUCCESS,
  RESET_STATES,
} from './constants';
import Auth from 'types/auth.models';

export type AuthAction = { type: string; payload?: any };

export const loginUser = (
  username: string,
  password: string,
  navigate: NavigateFunction,
  recaptchaValue?: any,
  returnLocation?: any,
  successSend?:any,
  path?: string ,
): AuthAction => {
  console.log("loginUser",path);
  return {
    type: LOGIN_USER,
    payload: { username, password, navigate, recaptchaValue, returnLocation,path },
  };
};

export const updateUserSession = (user: any): AuthAction => ({
  type: UPDATE_USER_SESSION,
  payload: user,
});

export const loginUserSuccess = (user: any): AuthAction => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const loginUserFailed = (error: string): AuthAction => ({
  type: LOGIN_USER_FAILED,
  payload: error,
});

export const registerUser = (fullname: string, email: string, password: string): AuthAction => ({
  type: REGISTER_USER,
  payload: { fullname, email, password },
});

export const registerUserSuccess = (user: {}): AuthAction => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});

export const registerUserFailed = (error: string): AuthAction => ({
  type: REGISTER_USER_FAILED,
  payload: error,
});

export const logoutUser = (navigate?: NavigateFunction): AuthAction => ({
  type: LOGOUT_USER,
  payload: { navigate },
});

export const refreshToken = () => ({
  type: REFRESH_TOKEN,
});

export const forgetPassword = (email: string,WebHost:any): any => ({
  type: FORGET_PASSWORD,
  email: email,
  WebHost:WebHost
});

export const forgetPasswordSuccess = (passwordResetStatus: string): AuthAction => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error: string): AuthAction => ({
  type: FORGET_PASSWORD_FAILED,
  payload: error,
});

export const resetPassword = (newPassword: string, newPasswordConfirmed: string, token: string, username: string,navigate:any,successSend:any): AuthAction => ({
  type: RESET_PASSWORD,
  payload: { newPassword, newPasswordConfirmed, token, username ,navigate,successSend},
});

export const resetPasswordSuccess = (passwordResetStatus: string): AuthAction => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: passwordResetStatus,
});

export const resetPasswordFailed = (error: string): AuthAction => ({
  type: RESET_PASSWORD_FAILED,
  payload: error,
});
export const setShowLpUser = (userId: number, showAgain: boolean) => ({
  type: SET_SHOW_LP_USER,
  userId,
  showAgain,
});
export const getShowlpUser = (userId: number) => ({
  type: GET_SHOW_LP_USER,
  userId,
});

export const getLandingPageData = (clientId: number) => ({
  type: GET_LANDING_PAGE,
  clientId,
});

export const getActiveCampaign = () => ({
  type: GET_ACTIVE_COMPAIGN,
});


export const getActiveCampaignSuccess = (payload: any) => ({
  type: GET_ACTIVE_COMPAIGN_SUCCESS,
  payload,
});

export const getCampaignWithPath = (path : String,navigate : Function) => ({
  type: GET_COMPAIGN_WITH_PATH,
  path,
  navigate
});
export const getCampaignWithPathSuccess = (payload: any) => ({
  type: GET_COMPAIGN_WITH_PATH_SUCCESS,
  payload,
});

export const getLandingPageSuccess = (payload: any) => ({
  type: GET_LANDING_PAGE_SUCCES,
  payload,
});
export const goToLandingPage = () => ({
  type: GOTO_LANDING_PAGE,
});

export const goToDashboard = () => ({
  type: GOTO_DASHBOARD,
});
export const savecontact = (payload: any) => ({
  type: SAVE_CONTACT,
  payload,
});
export const savecontactSuccess = (payload: any) => ({
  type: SAVE_CONTACT_SUCCESS,
  payload,
});
export const savecontactFail = (payload: any) => ({
  type: SAVE_CONTACT_FAIL,
  payload,
});
export const requestCheckSSOSession = () => ({
  type: REQUEST_CHECK_SSO_SESSION,
});

export const requestLoginFromSSO = (username: string, ssid: string, navigate: NavigateFunction,path ?: string) => ({
  type: LOGIN_USER_FROM_SSO,
  payload: { username, ssid, navigate,path },
});

export const getSessionDetails = (sessionId: string, clientId: string, navigate: NavigateFunction) => ({
  type: GET_SESSION_ID,
  clientId,
  sessionId,
  navigate,
});
export const SSORedirectSuccess = () => ({
  type: SSO_REDIRECT_SUCCESS,
});

export const oldConnectRedicredSuccess = () => ({
  type: OLD_CONNECT_REDIRECT,
});

export const loginUserBySSO = () => ({
  type: LOGIN_USER_BY_SSO,
});

export const requestMyProfileSuccess = (user: any): AuthAction => ({
  type: REQUEST_MY_PROFILE_SUCCESS,
  payload: user,
});
export const resetState=()=>({
  type:RESET_STATES
})