/* eslint-disable eqeqeq */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { Row, Col, Typography, Switch } from 'antd';
import SelectFilter from './periodSelect';
import YearlyCalendar from './YearlyCalendar';
import Daily from './daily';
import MonthlyCalendar from './MonthlyCalendar';
import QuarterSelect from './QuarterCalendar';
import { VectorIcon } from 'assets/images';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ComparedPeriodComponent from './comparedByDate';
import { FILTER_BY_ENUM, PERIOD_TYPE_ENUM } from '../../types';
import { onChangeFilterModel } from 'redux/actions';
import { formatDateByPeriodType } from '../../utils';
import '../../styles.scss';
import './Calendar.scss';
import Translate from 'pages/commons/Translate';

dayjs.extend(customParseFormat);
const { Text } = Typography;
type Props = { filterSettings: any };

const PeriodFilter: React.FC<Props> = ({ filterSettings }) => {
  const dispatch = useDispatch();

  const filterModel = useSelector((state: any) => state.Filter.filterModel['period']);
  const isWithComparison = filterModel?.isWithComparison;
  const hasComparison = filterSettings?.options.findIndex((item: any) => item == 'Period comparison') > -1;

  const toggle = () => {
    dispatch(onChangeFilterModel('period', { ...filterModel, isWithComparison: !isWithComparison }));
  };

  const handleOnChange = (fromDate: number | null, toDate: number | null) => {
    dispatch(onChangeFilterModel('period', { ...filterModel, fromDate, toDate }));
  };

  return (
    <div className="date-filter">
      <SelectFilter filterSettings={filterSettings}>
        {(filterBy: any, periodType: any) => (
          <Row className="filterDateRow">
            {filterBy == FILTER_BY_ENUM.PERIOD && filterModel.hasPeriodOptions && (
              <>
                {periodType == PERIOD_TYPE_ENUM.Day && (
                  <Daily start={filterModel.fromDate} end={filterModel.toDate} handleChange={handleOnChange} />
                )}
                {periodType == PERIOD_TYPE_ENUM.Month && (
                  <MonthlyCalendar start={filterModel.fromDate} end={filterModel.toDate} handleChange={handleOnChange} />
                )}
                {periodType == PERIOD_TYPE_ENUM.Quarter && (
                  <QuarterSelect start={filterModel.fromDate} end={filterModel.toDate} handleChange={handleOnChange} />
                )}
                {periodType == PERIOD_TYPE_ENUM.Year && (
                  <YearlyCalendar start={filterModel.fromDate} end={filterModel.toDate} handleChange={handleOnChange} />
                )}
              </>
            )}
            <div style={{ width: '100vw', alignItems: 'center', marginTop: 0, padding: 0, display: 'grid' }}>
              <Row className="filterDateRow">
                <Col span={10} className="filterDateCol cxg-text">
                  {formatDateByPeriodType(filterModel.fromDate, filterModel?.type)}
                </Col>
                <Col span={4} className="filterDateCol" style={{ background: 'white' }}>
                  <VectorIcon />
                </Col>
                <Col span={10} className="filterDateCol cxg-text">
                  {formatDateByPeriodType(filterModel.toDate, filterModel?.type)}
                </Col>
              </Row>
              {hasComparison && (
                <>
                  <Row gutter={16} className="filterDateRow" style={{ margin: 0, padding: 0 }}>
                    <Col span={12} className="filterDateCol" style={{ background: 'transparent', justifyContent: 'flex-start' }}>
                      <Text className={'cxg-text'} style={{ fontSize: '14px', fontWeight: 'bold', color: '#003C4C' }}>
                        <Translate id="pages.reporting.filters.summary.comparisonfromto" />
                      </Text>
                    </Col>
                    <Col span={12} className="filterDateCol" style={{ background: 'transparent', justifyContent: 'flex-end' }}>
                      <Switch checked={isWithComparison} onChange={toggle} />
                    </Col>
                  </Row>
                  {isWithComparison && (
                    <Row className="filterDateRow" style={{ justifyContent: 'center' }}>
                      <ComparedPeriodComponent periodType={periodType} />
                    </Row>
                  )}
                </>
              )}
            </div>
          </Row>
        )}
      </SelectFilter>
    </div>
  );
};

export default PeriodFilter;
