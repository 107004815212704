import { GET_LOCATION_RANKING_DATA,
    GET_LOCATION_RANKING_DATA_SUCCESS,GET_AG_GRID_Data_FAILED,GET_LOCATION_RANKING_DATA_BY_CATEGORY_ID 
,GET_LOCATION_RANKING_DATA__BY_CATEGORY_ID_SUCCESS,GET_AG_GRID_Data__BY_CATEGORY_ID_FAILED,
START_AGGRID_LOADING,
GET_FORMATED_DATA
} from './type';
  
  export const requestLocationRankingData = (params:any, firstCall:boolean) => ({
      type: GET_LOCATION_RANKING_DATA,
      payload: {params, firstCall},
  });
  
  export const requestLocationRankingDataSuccess = (payload:any) => (
      {
      type: GET_LOCATION_RANKING_DATA_SUCCESS,
      payload,
  });
  export const requestLoadingAggrid = (payload:any) => (
    {
    type: START_AGGRID_LOADING ,
    payload,
});
  export const requestLocationRankingDataFailed = (payload:any) => ({
      type: GET_AG_GRID_Data_FAILED,
      payload,
  });
  
  // location ranking by category id
export const requestGetLocationRankingDataById = (body: any, oldData: any) => ({
  type: GET_LOCATION_RANKING_DATA_BY_CATEGORY_ID,
  body,
  oldData,
});

export const requestLocationRankingDataByIdSuccess = (payload:any) => (
   {
    type: GET_LOCATION_RANKING_DATA__BY_CATEGORY_ID_SUCCESS,
    payload,
});
export const requestLocationRankingDataByIdFailed = (payload:any) => ({
    type: GET_AG_GRID_Data__BY_CATEGORY_ID_FAILED,
    payload,
});

export const requestFlattenData = (payload:any) => (
    {
    type: GET_FORMATED_DATA ,
    payload,
});