import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LabelsTranslationsContext } from 'pages/commons/Context';
import { initApp } from 'redux/actions';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { clarity } from 'react-microsoft-clarity';
import { getKeyValueOrDefault } from 'helpers/index';
import { CLARITY, GTM } from 'constants/common';

const InitRoutes: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();
  const LabelsTranslations = useSelector((state: any) => state.Layout.LabelsTranslations);

  useEffect(() => {
    const gtmId = getKeyValueOrDefault(GTM, window.location.hostname);
    if (gtmId) {
      TagManager.initialize({ gtmId });
    }
  }, []);

  useEffect(() => {
    const clarityId = getKeyValueOrDefault(CLARITY, window.location.hostname);
    if (clarityId) {
      clarity.init(clarityId);
    }
  }, []);

  useEffect(() => {
    dispatch(initApp());
  }, [dispatch]);

  return <LabelsTranslationsContext.Provider value={LabelsTranslations}>{children}</LabelsTranslationsContext.Provider>;
};

export default InitRoutes;
