export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAIL = 'GET_DASHBOARD_FAIL';
export const GET_WIDGET = 'GET_WIDGET';
export const GET_WIDGET_SUCCESS = 'GET_WIDGET_SUCCESS';
export const GET_WIDGET_FAIL = 'GET_WIDGET_FAIL';
export const GET_COLOR = 'GET_COLOR';
export const GET_COLOR_SUCCESS = 'GET_COLOR_SUCCESS';
export const GET_COLOR_FAIL = 'GET_COLOR_FAIL';
export const GET_LAST_USED = 'GET_LAST_USED';
export const GET_LAST_USED_SUCCESS = 'GET_LAST_USED_SUCCESS';
export const GET_LAST_USED_FAIL = 'GET_LAST_USED_FAIL';
export const GET_CAOCHING_SECTIONS = 'GET_CAOCHING_SECTIONS';
export const GET_CAOCHING_SECTIONS_SUCCESS = 'GET_CAOCHING_SECTIONS_SUCCESS';
export const GET_CAOCHING_SECTIONS_FAIL = 'GET_CAOCHING_SECTIONS_FAIL';
export const GET_NOM_FILTRED = 'GET_NOM_FILTRED';
export const GET_NOM_FILTRED_SUCCESS = 'GET_NOM_FILTRED_SUCCESS';
export const GET_NOM_FILTRED_FAIL = 'GET_NOM_FILTRED_FAIL';
export const GET_QBT = 'GET_QBT';
export const GET_QBT_SUCCESS = 'GET_QBT_SUCCESS';
export const GET_QBT_FAIL = 'GET_QBT_FAIL';
export const CLEAR_DASHBOARD = 'CLEAR_DASHBOARD';
export const GET_ICONS="GET_ICONS";
export const GET_ICONS_SUCCESS="GET_ICONS_SUCCESS";
export const GET_ICONS_FAIL="GET_ICONS_FAIL";
export const WIDGET_VIEWS = {
  GroupedWithOverall: 1,
  GroupedOnly: 2,
  OverallOnly: 3,
};
export const CHART_DISPLAY_TYPE = {
  StackedBar: 1,
  Table: 2,
  Detail: 3,
  ScoreVsPreviousPeriods: 4,
  Trend: 5,
  Funnel: 6,
  HorizontalBar: 7,
  Column: 8,
  Mixed: 10,
  Pie: 11,
};
export const BAR_ORIENTATION = {
  vertical: 2,
  horizental: 1,
};
export const KPI_WIDGET_CHART_VIEW = {
  Pyramid: 1,
  Donut: 2,
  StackedBar: 3,
  MultiStackedBar: 4,
  HealthIndex: 5,
  SustainabilityIndex: 6,
  RelationShipIndex: 7,
};
export const API_ENDPOINTS = {
  ACE_INDEX: 'AceIndex',
  ACE_DETAILS: 'AceDetails',
  ACE_RANKING: 'AceRanking',
  ACE_TREND: 'AceTrend',
  
  ANSWER_ANALYSIS: 'AnswerAnalysis',
  ASSOCIATE_ATTRIBUTES: 'AssociateAttributes',
  CASE_MANAGEMENT_AVERAGE_PERIOD: 'CaseManageAveragePeriod',
  CASE_MANAGEMENT_STATUS_VS_PREVIOUS_PERIODS: 'CaseManageStatusVsPreviousPeriods',
  CASE_MANAGEMENT_NUMBER_OF_OPEN_CASES_BY_ELAPSED_DAYS: 'CaseManageNumberOfOpenCasesByElapsedDays',
  QUESTION_COMMENTS: 'QuestionsComments',
  DEMOGRAPHICS: 'Demographics',
  NUMBER_OF_RESPONDERS: 'NumberOfResponses',
  DISTRIBUTION: 'Distribution',
  CUSTOMER_SATISFACTION: 'CustomerSatisfaction',
  QUESTIONS_ADVOCACY_INFLUENCE: 'QuestionsAdvocacyInfluence',
  QUESTIONS_LEADERBOARD: 'QuestionsLeaderboard',
  RAW_DATA: 'RawData',
  SECTION_PERFORMANCE: 'SectionsPerformance',
  STORE_QUESTION: 'StoreQuestion',
  SURVEY_EXPLORER_LIST_ALL_MISSIONS: 'ListAllMissions',
 SURVEY_EXPLORER_EXTERNAL_VOC :'SurveyExplorerExternalVoC',
  TOTAL_SCORE: 'TotalScore',
  SECTIONS_SCORE_GRID: 'SectionsScoreGrid',
  SECTIONS_SCORE_TREND: 'SectionsScoreTrend',
  VERBATIM_ANALYSIS: 'QuestionsVerbatimAnalysis',
  VISITS_PER_SCORE_GROUPS: 'VisitsPerScoreGroups',
  LOCATION_RANKING: 'LocationRanking',
  ALL_QUESTIONS_ANSWER_DISTRIBUTION: 'AllQuestionsAnswerDistribution',
  MISSION_DETAILS: 'GetAuditDetails',
  MISSION_LIST: 'MissionList',
  COACHING_CALENDAR: 'CoachingCalendar',
  COACHING_HISTORY: 'CoachingHistory',
  COACHING_PIE_CHART: 'CoachingPieChart',
  COACHING_OVERVIEW: 'CoachingOverview',
  CXC_DEEP_DIVE: 'CXCDeepDive',
  NPS_CHART: 'NPS',
  TOP_BOTTOM_COACHING_CHART: 'TopBottomCoaching',
  CXE_VS_CXC: 'CXEvsCXC',
  CHECKLIST_TRENDS: 'ChecklistTrends',
  KPI_WIDGET: 'NestedKPIRanking',
};
export const RELATION_SHIP_INDEX_COLORS_PALETTE = [
  {
    name: ' ',
    minRange: 0,
    maxRange: 0,
    color: '#d2534c',
  },
  {
    name: 'Poor',
    minRange: 0,
    maxRange: 20,
    color: '#d2534c',
  },
  {
    name: 'Low',
    minRange: 21,
    maxRange: 40,
    color: '#bb8f76',
  },
  {
    name: 'Average',
    minRange: 41,
    maxRange: 60,
    color: '#94b79f',
  },
  {
    name: 'Good',
    minRange: 61,
    maxRange: 80,
    color: '#5db494',
  },
  {
    name: 'Strong',
    minRange: 80,
    maxRange: 100,
    color: '#12796d',
  },
  {
    name: ' ',
    minRange: 100,
    maxRange: 100,
    color: '#004351',
  },
];
