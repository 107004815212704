// @flow
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  GET_LANDING_PAGE_SUCCES,
  GOTO_DASHBOARD,
  GOTO_LANDING_PAGE,
  DASHBOARD,
  LANDING_PAGE,
  SAVE_CONTACT,
  SAVE_CONTACT_SUCCESS,
  SAVE_CONTACT_FAIL,
  SSO_REDIRECT_SUCCESS,
  OLD_CONNECT_REDIRECT,
  REQUEST_MY_PROFILE_SUCCESS,
  GET_ACTIVE_COMPAIGN_SUCCESS,
  GET_COMPAIGN_WITH_PATH_SUCCESS,
  RESET_STATES,
} from './constants';
import { getLoggedInUser } from '../commons/gettoken';
const INIT_STATE = {
  user: getLoggedInUser(),
  userProfile: null,
  loading: false,
  isLoginError: false,
  isSuccess:false,
  error: null,
  landingSuccess: false,
  landingPageData: {},
  activeCampaignData: {},
  currentPage: null,
  clientDesign: null,
  isSave: false,
  SSORedirectSuccess: false,
  isOldConnectRedirectSuccess: false,
};
type AuthAction = { type: string; payload: any };
type State = { user?: {} | null; loading?: boolean; value?: boolean; SSORedirectSuccess: boolean };

const Auth = (state: State = INIT_STATE, action: AuthAction) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, payload: action.payload, loading: true, isLoginError: false, };
    case LOGIN_USER_SUCCESS:
      const client: any = action.payload;
      return {
        ...state,
        user: getLoggedInUser(),
        loading: false,
        error: null,
        isLoginError: false,
        // clientDesign:client.cxgDesign
      };
    case LOGIN_USER_FAILED:
      return { ...state, loading: false, isLoginError: true, error: action.payload };
    case FORGET_PASSWORD:
      return { ...state, loading: true,isLoginError : false,error : "" };
    case FORGET_PASSWORD_SUCCESS:
      return { ...state, passwordResetStatus: null, loading: false, error: null,isLoginError: false,isSuccess:true };
    case FORGET_PASSWORD_FAILED:
      return { ...state,isLoginError: true, error: action.payload, loading: false,isSuccess:false };
    case RESET_PASSWORD:
      return { ...state, loading: true, isLoginError: false };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, passwordResetStatus: null, loading: false, error: null, isLoginError: false };
    case RESET_PASSWORD_FAILED:
      return { ...state, isLoginError: true, error: action.payload, loading: false };
    case GET_LANDING_PAGE_SUCCES:
      return {
        ...state,
        landingPageData: action.payload,
      };
    case GET_ACTIVE_COMPAIGN_SUCCESS: 
        return {
          ...state,
          activeCampaignData : action.payload,
        }
    case GET_COMPAIGN_WITH_PATH_SUCCESS: 
    return {
      ...state,
      activeCampaignData : action.payload,
    }
    case GOTO_LANDING_PAGE:
      return {
        ...state,
        currentPage: LANDING_PAGE,
      };
    case GOTO_DASHBOARD:
      return {
        ...state,
        currentPage: DASHBOARD,
      };
    case SAVE_CONTACT:
      return {
        ...state,
        loading: true,
        isSave: false,
        error: '',
      };
    case SAVE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        isSave: true,
        error: '',
      };
    case SAVE_CONTACT_FAIL:
      return {
        ...state,
        loading: false,
        isSave: false,
        error: action.payload,
      };
      case RESET_STATES:
        return {
          ...state,
          isSuccess:false,
          isLoginError:false
        };
    case SSO_REDIRECT_SUCCESS:
      return { ...state, SSORedirectSuccess: true };

    case OLD_CONNECT_REDIRECT:
      return { ...state, isOldConnectRedirectSuccess: true };
    case REQUEST_MY_PROFILE_SUCCESS:
      return { ...state, userProfile: action.payload };
    default:
      return { ...state };
  }
};
export default Auth;
