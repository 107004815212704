/* eslint-disable  */
import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import handleSagaErrors from 'pages/commons/handel-saga-errors';
// import { queryParams, DefaultQueryParamsObject } from 'src/helpers/aggrid-helpers';
// import { requestLoginToIOFSPlatform } from 'src/common/api';
import {
  getIofsList,
   getIofsInfos, 
   getIofsFieldWork,
  getInfos,
  getAttachments,
  getPosAttachments,
  getScenarioAttachments, } from 'apis/iofs.api';

// import { addGenericNotificationFromError } from 'src/common/notifications/actions';
import { ActionTypes, IOFSListSearchFields, IOFSListSortFields } from './types';
import moment from 'moment';

import * as Actions from './actions';
import { AxiosResponse } from 'axios';

// function* handleRequestLoginToIOFS(payload:any) {
//   try {
//     const urlencoded = new URLSearchParams();
//     urlencoded.append('grant_type', 'client_credentials');
//     urlencoded.append('client_secret', 'K5D8Q~~U55OsPKdzQbwbKEBwdpgTXcSKqeGwobnE');
//     urlencoded.append('client_id', 'd9f3ac7d-fccb-4865-963f-7e6c298af3c3');
//     urlencoded.append('scope', 'https://cxgdev.crm4.dynamics.com/.default');

//     const response = yield call(requestLoginToIOFSPlatform, urlencoded);
//     yield put(Actions.requestIOFSAccessSuccess(response));
//   } catch (e) {
//     yield put(addGenericNotificationFromError(e));
//   }
// }

function* handleRequestIOFSListData( {payload} :any) : Generator<any, void, AxiosResponse<any, any>> {
  const { params, filterModel } = payload;
  let orderBy = '';
  let searchTerm = '';
 
 console.log( params, filterModel )
  const filterKeys = Object.keys(filterModel);
  filterKeys.forEach((filter) => {
    switch (filter) {
      case 'kickOff':
        return (searchTerm += `${filter}=${moment(filterModel[filter].dateFrom).format('YYYY-MM-DD')},`);
      case 'modifiedon':
        return (searchTerm += `${filter}=${moment(filterModel[filter].dateFrom).format('YYYY-MM-DD')},`);
      default:
        if (filterModel[filter].filter) {
          return (searchTerm += `${filter}=${filterModel[filter].filter},`);
        } else {
          return;
        }
    }
  });

  const queryParamsObject = {
    orderBy,
    searchTerm,
  }; 

  const response :any = yield call(getIofsList, queryParamsObject);
  
  let resData = response.data != '' ? (Array.isArray(response.data) == true ? response.data : [response.data]) : [];
  yield put(Actions.requestIOFSListDataSuccess(resData));
}

function* handleRequestIOFSFieldWork( {payload} :any) {
  const { iofId } = payload;
  const { data } = yield call(getIofsFieldWork, iofId);
  yield put(Actions.requestFieldWorkSuccess(data));
}
function* handleRequestIOFSGeneralInfos({payload}:any) {
  // console.log("^^^^",payload)
  const { params, iofId } = payload;
  const { data } = yield call(getIofsInfos, iofId);
  yield put(Actions.requestIofInfosSuccess(data));
}
function* handleRequestIOFSEvaluator( {payload} :any) {
  const { params, iofId } = payload;
  const { data } = yield call(getInfos, { apiUrl: 'evaluator', iofId });
  yield put(Actions.requestIofEvaluatorSuccess(data));
}

function* handleRequestIOFSScenario( {payload}:any ) {
  const { params, iofId, filterModel } = payload;
  let orderBy = 'cxgit_scenarioid desc';
  let searchTerm = '';

  const filterKeys = Object.keys(filterModel);

  filterKeys.forEach((filter) => {
    switch (filter) {
      case 'modifiedon':
        if(filterModel[filter].filter!=='')
        return (searchTerm += `${filter}=${moment(filterModel[filter].dateFrom).format('YYYY-MM-DD')}&${moment(
          filterModel[filter].dateTo
        ).format('YYYY-MM-DD')},`);
        else
        return;

      default:
        if (filterModel[filter].filter) {
          return (searchTerm += `${filter}=${filterModel[filter].filter},`);
        } else {
          return;
        }
    }
  });

  const queryParamsObject = {
    orderBy,
    searchTerm,
  };
  /**************************end */
  const { data } = yield call(getInfos, { apiUrl: 'scenarios', iofId, queryParamsObject });
  let resData = data != '' ? (Array.isArray(data) == true ? data : [data]) : [];
  yield put(Actions.requestIofScenarioSuccess(resData));
}
function* handleRequestIOFSPos({payload} :any) {
  const { params, iofId, filterModel } = payload;
  let orderBy = 'cxgit_posid desc';
  console.log(params);
  //const { sortModel } = params;

  let searchTerm = '';
  const filterKeys = Object.keys(filterModel);
  filterKeys.forEach((filter) => {
    switch (filter) {
      default:
        if (filterModel[filter].filter) {
          return (searchTerm += `${filter}=${filterModel[filter].filter},`);
        } else {
          return;
        }
    }
  });
  const queryParamsObject = {
    orderBy,
    searchTerm,
  };
  const { data } = yield call(getInfos, { apiUrl: 'pos', iofId, queryParamsObject });
  let resData = data != '' ? (Array.isArray(data) == true ? data : [data]) : [];
  yield put(Actions.requestIofPosesSuccess(resData));
}
function* handleRequestIOFSPmReport({payload} :any) {
  const { params, iofId, filterModel } = payload;

  let orderBy = 'cxgit_ioffeesid desc';

  let searchTerm = '';

  const filterKeys = Object.keys(filterModel);

  filterKeys.forEach((filter) => {
    switch (filter) {
      case 'modifiedon':
        return (searchTerm += `${filter}=${moment(filterModel[filter].dateFrom).format('YYYY-MM-DD')},`);
      default:
        if (filterModel[filter].filter) {
          return (searchTerm += `${filter}=${filterModel[filter].filter},`);
        } else {
          return;
        }
    }
  });

  const queryParamsObject = {
    orderBy,
    searchTerm,
  };
  const { data } = yield call(getInfos, { apiUrl: 'fees', iofId, queryParamsObject });
  let resData = data != '' ? (Array.isArray(data) == true ? data : [data]) : [];
  yield put(Actions.requestIofPmSuccess(resData));
}
function* handleRequestAnalysis( {payload}:any ) {
  const { params, iofId } = payload;
  const { data } = yield call(getInfos, { apiUrl: 'analysis', iofId });
  console.log('saga', data);
  yield put(Actions.requestIofAnalysisSuccess(data));
}

function* handleRequestIOFSScenarioById({payload}:any) {
  const { params, idScenario } = payload;
  const { data } = yield call(getInfos, { apiUrl: 'scenarioDetails', iofId: idScenario });
  yield put(Actions.requestScenarioByIdSuccess(data));
}
function* handleRequestIOFSPosById( {payload} :any) {
  console.log("eeeeeeeee");
  
  const { params, posId } = payload;
  const { data } = yield call(getInfos, { apiUrl: 'posDetails', iofId: posId });
  yield put(Actions.requestPosByIdSuccess(data));
}
function* handleRequestAttachment({payload} : any) {
  const { params, props } = payload;
  const { iofId, iofName } = props;
 console.log(props)
  const { data } = yield call(getAttachments, {iofId,iofName});
  yield put(Actions.requestAttachmentSuccess(data));
}
function* handleRequesPostAttachment({ payload}:any) {
  const { params, id ,name } = payload;
 
  const { data } = yield call(getPosAttachments, { id,name });
  console.log(data);
  yield put(Actions.requestPosAttachmentSuccess(data));
}
function* handleRequestScenarioAttachment({ payload }:any) {
  const { params,  id, name } = payload;
  const { data } = yield call(getScenarioAttachments, { id,name });
  yield put(Actions.requestScenarioAttachmentSuccess(data));
  // params.successCallback(data, data.length);
}
export default function* init() {
  // yield takeEvery(ActionTypes.RequestIOFSAccess, handleSagaErrors(handleRequestLoginToIOFS));
  yield takeLatest(ActionTypes.RequestIOFSListData, handleSagaErrors(handleRequestIOFSListData));
  yield takeLatest(ActionTypes.requestFieldWork, handleSagaErrors(handleRequestIOFSFieldWork));
  yield takeLatest(ActionTypes.requestIofInfos, handleSagaErrors(handleRequestIOFSGeneralInfos));
  yield takeLatest(ActionTypes.requestIofPos, handleSagaErrors(handleRequestIOFSPos));
  yield takeLatest(ActionTypes.requestIofPm, handleSagaErrors(handleRequestIOFSPmReport));
  yield takeLatest(ActionTypes.requestIofAnalysis, handleSagaErrors(handleRequestAnalysis));
  yield takeLatest(ActionTypes.requestIofEvaluator, handleSagaErrors(handleRequestIOFSEvaluator));
  yield takeLatest(ActionTypes.requestIofScenario, handleSagaErrors(handleRequestIOFSScenario));
  yield takeLatest(ActionTypes.requestIofScenarioById, handleSagaErrors(handleRequestIOFSScenarioById));
  yield takeLatest(ActionTypes.requestIofPosById, handleSagaErrors(handleRequestIOFSPosById));
  yield takeLatest(ActionTypes.requestAttachment, handleSagaErrors(handleRequestAttachment));
  yield takeLatest(ActionTypes.requestPosAttachment, handleSagaErrors(handleRequesPostAttachment));
  yield takeLatest(ActionTypes.requestScenarioAttachment, handleSagaErrors(handleRequestScenarioAttachment));
}
