/* eslint-disable  */

import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// import handleSagaErrors from 'src/common/handle-saga-errors';
import {
    GetAgGridData,
    requestDashboard,
    requestExperinceReportDocumentViewerData,
    downloadAsPdfData,
    changeMissionListStatus,
    requestMissionDetails,
    requestDesign,
} from "../../../../apis/url";
import handleSagaErrors from "pages/commons/handel-saga-errors";
import handleSagaDownloadErrors from "pages/commons/handle-saga-download-errors";
import {
    GET_AG_GRID_Data,
    handleRequestChangeMissionStatus,
    DownloadMissionsPDFs,
    RequestExperinceReportDocumentViewer,
    RequestExperinceReportDocumentDownloadAsPdf,
    RequestMissionDetails,
    RequestClientDesign,
    ERT_HEAD,
} from "./type";
import {
    GET_QUESTION_LEADER_BOARD,
    GET_QUESTION_HIGHEST_ADVOCAY,
} from "../QuestionsLeaderboard/type";
import {
    requestAQuestionsLeaderboardSucess,
    requestQuestionsHighestAdvocaySucess,
} from "../QuestionsLeaderboard/actions";
import {
    requestAgGridTableDataSuccess,
    requestAgGridTableData,
    requestExperinceReportDocumentViewerSuccess,
    requestMissionDetailsSuccess,
    requestClientDesignSuccess,
} from "./action";
import { getClientId } from "helpers/authUtils";
import {
    getMissionExportFileName,
    openBlobDataLink,
    parseJSONObject,
} from "./utilis";
import { openGlobalNotification } from "pages/components/notifications";
import JSZip from 'jszip';
import { TranslateToStringWithLabels } from "pages/commons/Translate";

function* handleRequestAgGridData({ params }) {
    const { startRow, endRow, filterModel, sortModel } = params.params.request;
    const { endPoint, widgetSetting, lastFilter } = params;
    const clientId = getClientId();

    let orderBy = "Id desc";
    let searchTerm = "";

    if (sortModel.length)
        orderBy =
            (sortModel[0].colId.endsWith("Name")
                ? sortModel[0].colId.substring(0, sortModel[0].colId.length - 4)
                : sortModel[0].colId.endsWith("Answer")
                    ? sortModel[0].colId.substring(0, sortModel[0].colId.length - 6)
                    : sortModel[0].colId
            ).replace(/\s/g, "") +
            " " +
            sortModel[0].sort; // replace to remove all white space

    const pageSize = endRow - startRow;
    const pageNumber = Math.round(startRow / pageSize) + 1;

    const response = yield call(
        GetAgGridData,
        widgetSetting,
        clientId,
        endPoint,
        params.searchItem,
        pageSize !== null ? pageSize : undefined,
        pageNumber,
        orderBy,
        lastFilter
    );
    console.log("resSE", response);
    const pageParams =
        response.status == 204
            ? {
                totalCount: 0,
            }
            : parseJSONObject(response.headers["x-pagination"]);
    console.log("pageParams", pageParams);
    const queryParamsObject = {
        pageNumber: Math.round(startRow / pageSize) + 1,

        pageSize: endRow - startRow,

        orderBy,

        filterBy: "",

        searchTerm,
        totalCount: pageParams && pageParams.totalCount,
    };
    let formattedData = [];

    if (response.data && response.data !== "") {
        formattedData = response.data.map((survey) => {
            if (survey.aceAnswer && survey.aceAnswer != "")
                survey.aceAnswer = TranslateToStringWithLabels({
                    LabelsTranslations: params.LabelsTranslations,
                    id: `generic.survey.ace.${survey.aceAnswer}`, defaultMessage: survey.aceAnswer
                });
            const formattedEntry = {
                ...survey,
                // Add more fields here
            };

            survey.dynamicProperties.forEach((property) => {
                formattedEntry[property.name] = property.value;
            });
            // console.log("formattedEntry", formattedEntry);
            return formattedEntry;
        });
    }
    params.params.successCallback(
        formattedData,
        response.status == 204
            ? 0
            : JSON.parse(response.headers["x-pagination"]).totalCount
    );
    yield put(
        requestAgGridTableDataSuccess({
            agGridData: formattedData,
            pageParameters: queryParamsObject,
        })
    );
}


async function extractAndCreateFinalZip(zipBlobs) {
    try {
        const finalZip = new JSZip();

        for (let indexblob = 0; indexblob < zipBlobs.length; indexblob++) {
            const blob = zipBlobs[indexblob];
            const zip = await JSZip.loadAsync(blob);
            console.log(zip, indexblob);

            const fileNames = Object.keys(zip.files);
            for (let indexfile = 0; indexfile < fileNames.length; indexfile++) {
                const fileName = fileNames[indexfile];
                const fileData = await zip.files[fileName].async('blob');
                finalZip.file(fileName, fileData);
                console.log(fileName, fileData, indexfile, indexblob);

                if (indexblob === zipBlobs.length - 1 && indexfile === fileNames.length - 1) {
                    const downloadLink = document.createElement('a');
                    downloadLink.href = URL.createObjectURL(await finalZip.generateAsync({ type: 'blob' }));
                    downloadLink.download = 'missions-export.zip';
                    downloadLink.click();
                }
            }
        }
    } catch (error) {
        console.error('Error extracting files:', error);
        throw error;
    }
}
function* handleDownloadMissionsPdf({
    clientId,
    clientName,
    missions,
    widgetSettings,
    languageId = 4,
    widgetId,
}) {
    try {
        if (missions.length > 1) {
            const batchSize = 10;
            const numBatches = Math.ceil(missions.length / batchSize);
            const responses = [];
            for (let i = 0; i < numBatches; i++) {
                const start = i * batchSize;
                const end = Math.min(start + batchSize, missions.length);
                const batch = missions.slice(start, end);

                const response = yield call(
                    requestDashboard,
                    clientId,
                    "DownloadMissionsPdf",
                    {
                        missionIds: batch.map((x) => x.id),
                        clientId,
                        filters: { translate: widgetSettings.translate },
                        footer: widgetSettings.footer,
                        footerHeight: widgetSettings.footerHeight,
                        languageId,
                        widgetId,
                    }
                );
                responses.push(response.data);
            }
            yield extractAndCreateFinalZip(responses);
            openGlobalNotification({ status: "success", message: 'File Downloaded successfully!', placement: 'bottomRight' });

        }

        else {
            const response = yield call(
                requestDashboard,
                clientId,
                "DownloadMissionsPdf",
                {
                    missionIds: missions.map((x) => x.id),
                    clientId,
                    filters: { translate: widgetSettings.translate },
                    footer: widgetSettings.footer,
                    footerHeight: widgetSettings.footerHeight,
                    languageId,
                    widgetId,
                }
            );
            if (response)
                openGlobalNotification({ status: "success", message: 'File Downloaded successfully!', placement: 'bottomRight' });

            if (widgetSettings && widgetSettings.preview) {
                const { callback } = widgetSettings;
                const objectURL = window.URL.createObjectURL(response.data);
                callback && callback(objectURL);
                return;
            }
            const [mission] = missions;
            const { id, projectName, aceAnswer, locationName } = mission;
            const exportFileName = getMissionExportFileName(id, projectName, aceAnswer, locationName);
            openBlobDataLink(response, true, `${exportFileName}.pdf`);

        }

    } catch (error) {
        console.error('Error handling download:', error);
        openGlobalNotification({ status: "error", message: 'Failed to download files!', placement: 'bottomRight' });
    }
}
function* handleDownloadMissionsPdf1({
    clientId,
    clientName,
    missions,
    widgetSettings,
    languageId = 4,
    widgetId,
}) {
    const response = yield call(
        requestDashboard,
        clientId,
        "DownloadMissionsPdf",
        {
            missionIds: missions.map((x) => x.id),
            clientId,
            filters: { translate: widgetSettings.translate },
            footer: widgetSettings.footer,
            footerHeight: widgetSettings.footerHeight,
            languageId,
            widgetId,
        }
    );
    if (response)
        openGlobalNotification({ status: "success", message: 'File Downloaded successfully!', placement: 'bottomRight' });

    if (missions.length > 1) {
        openBlobDataLink(response, true, "missions-export.zip");
        return;
    }
    if (widgetSettings && widgetSettings.preview) {
        const { callback } = widgetSettings;
        const objectURL = window.URL.createObjectURL(response.data);
        callback && callback(objectURL);
        return;
    }
    const [mission] = missions;
    const { id, projectName, aceAnswer, locationName } = mission;
    const exportFileName = getMissionExportFileName(id, projectName, aceAnswer, locationName);

    openBlobDataLink(response, true, `${exportFileName}.pdf`);
}

function* handleRequestExperinceReportDocumentViewer({
    langId,
    clientId,
    missionId,
    translate,
    callback,
}) {
    const response = yield call(
        requestExperinceReportDocumentViewerData,
        langId,
        clientId,
        missionId,
        translate
    );
    if (response.status == 200 && callback) {
        callback();
    }
    yield put(requestExperinceReportDocumentViewerSuccess(response));
}
function* handleRequestExperinceReportDocumentDownloadAsPdf({
    id,
    clientId,
    element,
    locationId,
    projectName,
    ace
}) {
    const htmlElement = document.getElementById(element);

    const formData = new FormData();
    formData.append("head", ERT_HEAD);
    formData.append("body", htmlElement.innerHTML);
    formData.append("pageSize", "A5");

    const response = yield call(downloadAsPdfData, clientId, formData);
    if (ace != "undefined")
        openBlobDataLink(
            response,
            true,
            `${projectName}_${locationId}_${id}_${ace}_${new Date(Date.now()).toISOString().split("T")[0]
            }.pdf`
        );
    else
        openBlobDataLink(
            response,
            true,
            `${projectName}_${locationId}_${id}_${new Date(Date.now()).toISOString().split("T")[0]
            }.pdf`
        );
}

function* handleRequestChangeMissionListStatus({
    clientId,
    userId,
    status,
    missionList,
    callback,
}) {
    const source = 0
    const response = yield call(
        changeMissionListStatus,
        clientId,
        userId,
        status,
        missionList,
        source
    );
    if (response.status == 200 && callback) {
        callback();
    }

    return response;
}
function* handleRequestMissionDetails({ clientId, missionId, settings }) {
    let missionDetails = null;

    missionDetails = yield call(
        requestMissionDetails,
        clientId,
        missionId,
        settings
    );

    // missionDetails.surveyItems = preformatTreeData(missionDetails.surveyItems);
    yield put(requestMissionDetailsSuccess(missionDetails));
}
function* handleRequestClientDesign({ clientId }) {
    const clientDesign = yield call(requestDesign, clientId);
    yield put(requestClientDesignSuccess(clientDesign));
}

// QuestionsLeaderBoard //
function* handleRequestLeaderQuestion({ params }) {
    const { startRow, endRow, filterModel, sortModel } = params.params.request;
    const { endPoint, widgetSetting, lastFilter } = params;

    const clientId = getClientId();

    let orderBy = "Id desc";

    let searchTerm = "";

    if (sortModel.length) orderBy = `${sortModel[0].colId} ${sortModel[0].sort}`;

    // const pageNumber = params.pageNumber

    const response = yield call(
        GetAgGridData,
        widgetSetting,
        clientId,
        endPoint,
        undefined,
        undefined,
        undefined,
        undefined,
        lastFilter,
        undefined
    );
    // const pageParams = JSON.parse(response.headers["x-pagination"]);
    const queryParamsObject = {
        pageNumber: Math.round(startRow / endRow) + 1,

        pageSize: endRow - startRow,

        orderBy,

        filterBy: "",

        searchTerm,
        // totalCount: pageParams && pageParams.totalCount,
    };

    params.params.successCallback(
        response.status === 204 ? [] : response.data.questions,
        response.status === 204 ? 0 : response.data.questions.length
    );
    yield put(
        requestAQuestionsLeaderboardSucess({
            questionLeaderBoard:
                response.status === 204 ? [] : response.data.questions,
            // pageParameters: queryParamsObject,
        })
    );
}
// question highest influence
function* handleRequestQuestionHihghestInfluence({ params }) {
    const { startRow, endRow, filterModel, sortModel } = params.params.request;
    const { endPoint, widgetSetting, lastFilter } = params;

    const clientId = getClientId();

    let orderBy = "Id desc";

    let searchTerm = "";

    if (sortModel.length) orderBy = `${sortModel[0].colId} ${sortModel[0].sort}`;

    // const pageNumber = params.pageNumber

    const response = yield call(
        GetAgGridData,
        widgetSetting,
        clientId,
        endPoint,
        undefined,
        undefined,
        undefined,
        undefined,
        lastFilter,
        undefined
    );

    params.params.successCallback(
        response.status === 204 ? [] : response.data.questionsTop,
        response.status === 204 ? 0 : response.data.questionsTop.length
    );
    yield put(
        requestQuestionsHighestAdvocaySucess({
            highestInfluenceQuestion:
                response.status === 204 ? [] : response.data.questionsTop,
            // pageParameters: queryParamsObject,
        })
    );
}
export default function* AgGridSaga() {
    yield takeLatest(GET_AG_GRID_Data, handleSagaErrors(handleRequestAgGridData));
    yield takeLatest(
        DownloadMissionsPDFs,
        handleSagaDownloadErrors(handleDownloadMissionsPdf)
    );
    yield takeLatest(
        RequestExperinceReportDocumentViewer,
        handleSagaErrors(handleRequestExperinceReportDocumentViewer)
    );
    yield takeLatest(
        RequestExperinceReportDocumentDownloadAsPdf,
        handleSagaErrors(handleRequestExperinceReportDocumentDownloadAsPdf)
    );
    yield takeLatest(
        handleRequestChangeMissionStatus,
        handleSagaErrors(handleRequestChangeMissionListStatus)
    );
    yield takeLatest(RequestMissionDetails, handleRequestMissionDetails);
    yield takeLatest(RequestClientDesign, handleRequestClientDesign);
    yield takeEvery(GET_QUESTION_LEADER_BOARD, handleRequestLeaderQuestion);
    yield takeLatest(
        GET_QUESTION_HIGHEST_ADVOCAY,
        handleRequestQuestionHihghestInfluence
    );
}
