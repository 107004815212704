import Translate, {TranslateToString} from 'pages/commons/Translate'
import React from 'react'
import {useSelector} from 'react-redux'

export default function FooterPdf(props) {
  const {user,generated,exportedby,exportdate}=props
  const date =new Date
  const year =date.getFullYear()
  const day =date.toLocaleDateString('fr-FR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  return (
    <div style={{display:"flex",justifyContent:'space-between',alignItems:"center",backgroundColor:'white',height:50,alignItems:'center',boxShadow: '0px -5px 5px -5px rgba(0, 0, 0, 0.5)',}}>
      <span style={{padding:20,fontFamily:'FS Siena', fontSize:14,fontWeight:'bold',color:'#003D4C'}}>
      {generated}  {year}
      </span>
     <div>
    <span style={{padding:20,fontFamily:'FS Siena', fontSize:12,fontWeight:'bold',color:'#003D4C'}}>
   {exportedby} {user?.given_name} {user?.family_name}
    </span> 
    <br/>
    <span style={{padding:20,fontFamily:'FS Siena', fontSize:12,fontWeight:'bold',color:'#003D4C'}}>
    {exportdate} {day}
  </span>
     </div>
    </div>
  )
}
