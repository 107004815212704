import {
  GET_AG_GRID_EVOC_Data_SUCCESS,
  CleanMissionDetails_EVOC,
  RequestClientDesign_EVOC_Success,
  RequestExperinceReportDocumentViewer_EVOC_Success,
  RequestMissionDetails_EVOC_Success,
  CleanClientDesign_EVOC,
  CURRENT_PAGE
} from "./type";
import {GET_QUESTION_LEADER_BOARD_SUCCESS,GET_QUESTION_HIGHEST_ADVOCAY_SUCCESS} from "../QuestionsLeaderboard/type"
export const defaultState = {
  agGridData: [],
  loading: false,
  documentLoading: false,
  documentHtmlViewer: "",
  missionDetails:null ,
  questionLeaderBoard:[],
  highestInfluenceQuestion:[],
  currentPage:0
};

function surveyExplorerEVOCReducer(state = defaultState, action: any) {

  switch (action.type) {
    case  GET_AG_GRID_EVOC_Data_SUCCESS:
      return {
        ...state,
        loading: true,
        agGridData: action.payload.agGridData,
        pageParameters: action.payload.pageParameters,
        documentLoading: false,
      };
      case  GET_QUESTION_LEADER_BOARD_SUCCESS:
        return {
          ...state,
          loading: true,
          questionLeaderBoard: action.payload.questionLeaderBoard,
          pageParameters: action.payload.pageParameters,
         };
         case  GET_QUESTION_HIGHEST_ADVOCAY_SUCCESS:
          return {
            ...state,
            loading: true,
            highestInfluenceQuestion: action.payload.highestInfluenceQuestion,
           };
    case  RequestExperinceReportDocumentViewer_EVOC_Success:
      return {
        ...state,
        documentHtmlViewer: action.payload.data,
        documentLoading: true,
      };
    case  RequestMissionDetails_EVOC_Success: {
      return {
        ...state,
        missionDetails: action.payload.data,
      };
    }
    case  RequestClientDesign_EVOC_Success:
      return {
        ...state,
        clientDesign: action.payload.data,
      };
    case  CleanMissionDetails_EVOC:
      return {
        ...state,
        missionDetails: null,
      };
      case CleanClientDesign_EVOC:
      return {
        ...state,
        clientDesign: null,
      };
      case CURRENT_PAGE:        
        return {
          ...state,
          currentPage: action.payload,
        };
      default:
      return state;
  }

 }

export default surveyExplorerEVOCReducer;
