import { CLEAR_TRANSLATION, GET_COMMENT_DATA, GET_GET_COMMENT_DATA_FAIL, GET_GET_COMMENT_DATA_SUCCESS, GET_TEXT_TRANSLATION, GET_TEXT_TRANSLATION_FAIL, GET_TEXT_TRANSLATION_SUCCESS } from "./type";

export const getTransationText = (groupText: any, id: any) => ({
    type: GET_TEXT_TRANSLATION,
    groupText,
    id,
  });
  
  export const getTransationTextSuccess = (texttranslated: any, id: any) => {
    const payload = { texttranslated, id };
    return {
      type: GET_TEXT_TRANSLATION_SUCCESS,
      payload,
    };
  };
  
  export const getTransationTextFail = (payload: any) => ({
    type: GET_TEXT_TRANSLATION_FAIL,
    payload,
  });
  export const clearTranslation=(id:number)=>{

 return  (
    { type:CLEAR_TRANSLATION,
    id
  }
  )
}

export const getCommentData = (
  filter: any,
  settings: any,
  id: number,
  endPoint: string,
  series?: any,
  source?: any,
  sectionIds?: any,
  questionWithAnswerIds?: any
) => {
  return {
    type: GET_COMMENT_DATA,
    filter,
    settings,
    id,
    endPoint,
    series,
    source,
    sectionIds,
    questionWithAnswerIds,
  };
};
export const getCommentSuccess = (payload: any) => ({
  type: GET_GET_COMMENT_DATA_SUCCESS,
  payload,
});
export const getCommentDataFail = (payload: any) => ({
  type: GET_GET_COMMENT_DATA_FAIL,
  payload,
});