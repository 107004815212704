import { GetWidget, getLangId, translateComment } from 'apis/url';
import { AxiosResponse } from 'axios';
import { call, put, takeEvery, fork, all, select, takeLatest } from 'redux-saga/effects';
import { clearTranslation, getCommentDataFail, getCommentSuccess, getTransationTextFail, getTransationTextSuccess } from 'redux/actions';
import { GET_COMMENT_DATA, GET_TEXT_TRANSLATION } from './type';
import { getClientId } from 'helpers/authUtils';

export function* handleGetTranslationText(payload: any): Generator<any, void, AxiosResponse<any, any>> {
    const { groupText, id } = payload;
    const translatedText = yield select((s: any) => s.Dashboard.translatedText);
    const  reportingLanguage:any= yield select((s:any)=>s.Layout.reportingLanguage)
  const langcode=reportingLanguage.find((item:any) => item.value === getLangId())?.code?.toLowerCase();
    try {
      const translations: any = yield all(groupText.map((text: any) => call(translateComment, text,langcode)));
      const texttranslated = yield translations &&
        translations.map((t: any) => {
          return t.data[0].translations[0].text;
        });
  
      yield put(getTransationTextSuccess(texttranslated, id));
    } catch (e) {
  //console.log("eeeeee",e)
      yield put(getTransationTextFail(e));
    }
  }
  export function* handelGetWidgets(payload: any): Generator<any, void, AxiosResponse<any, any>> {
    const { settings, filter, id, endPoint, series, source, sectionIds, questionWithAnswerIds } = payload;
    const lastFilter = yield select((s: any) => s.Dashboard.lastUpdateFilter);
    const clientId = getClientId();
    try {
       const response: any = yield lastFilter&&call(
        GetWidget,
        lastFilter,
        settings,
        clientId,
        endPoint,
        series,
        source,
        sectionIds,
        questionWithAnswerIds
      );
      const res = response?.data;
      const data = { res, id };
      yield put(getCommentSuccess(data));
      yield put (clearTranslation(id));
    } catch (error: any) {
      yield put(getCommentDataFail(error.response.data.message));
    }
  }

  export function* watchHandelGetTranslationText(): any {
    yield takeEvery(GET_TEXT_TRANSLATION, handleGetTranslationText);
  }
  export function* watchHandelGetComments(): any {
    yield takeEvery(GET_COMMENT_DATA, handelGetWidgets);
  }
  export default function* translationCommentSaga(): any {
    yield all([
      fork(watchHandelGetTranslationText),
      fork(watchHandelGetComments),
    ]);
  }