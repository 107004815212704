import { notification } from "antd";
import {ReactComponent as Success} from "assets/images/successNotif.svg";
import {ReactComponent as Close} from "assets/images/close.svg";
import {ReactComponent as Fail} from "assets/images/fail_status.svg";
import {ReactComponent as Info} from "assets/images/information_status.svg";


const icons = {
    success : <Success />,
    fail : <Fail/> ,
    info : <Info/>,
}

type Icons = 'success' | 'fail' | 'info'  ;

const NotifMessage=({message} : { message : string })=>{
    return (
        <div style={{display:'flex',alignItems:'center',justifyContent:"flex-start",flexDirection:'row',height : "100%"}}>
            <span style={{color:'#FFFFFF',fontFamily:'FS Siena',fontSize:15,margin:"0px 10px"}}>{message}</span>
        </div>
    )
}

export const openGlobalNotification = ({status = "success",message = "The question is loaded successfully",placement='topRight'} : {status? : Icons,message? : string ,placement?:any}) => {
    notification.open({
    message: <NotifMessage message = {message}/>,
    className: 'custom-class-notifications-global',
    style: { top : 50,borderRadius : "15px"},
    icon :  icons[status] ?? <></>,
    closeIcon : <div className="notification-container"><div className="notification-separator"></div><Close /></div>,
    placement:placement
});
};