import { NOTIFICATION_ADD_GENERIC, NOTIFICATION_REMOVE_GENERIC } from './types';

export const addGenericNotification = (notification: any) => ({
  type: NOTIFICATION_ADD_GENERIC,
  payload: { notification },
});

export const addGenericNotificationFromError = (error: any) => ({
  type: NOTIFICATION_ADD_GENERIC,
  payload: {
    notification: {
      id: +new Date(),
      type: 'error',
      message: error?.message,
      errorData: error?.response?.data,
      errorStatus: error?.response?.status,
    },
  },
});

export const removeGenericNotification = (id: any) => ({
  type: NOTIFICATION_REMOVE_GENERIC,
  payload: { id },
});
