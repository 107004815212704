import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getTranslation,
  requestReportingLanguage,
  requestAppVersion,
  requestContact,
  sendMessage,
  GetDashboard,
  getColorPalette,
  requestDashboardClients,
  requestRolesData,
  requestChangeLanguage,
  requestAllLanguage,
  requestDynamicReports,
  requestallUserRoles,
} from '../apis/url';
import {
  requestLabelsTranslations,
  requestReportingLanguageSuccess,
  requestAppVersionSuccess,
  requestContactEmailSuccess,
  showToastMessage,
  getReportingPagesSuccess,
  initNavigation,
  changeLanguageSuccess,
  initClientsSuccess,
  requestAllLanguageSuccess,
  requestDynamicReportsSuccess,
  requestUserRolesSuccess,
  requestUserRoles
} from './action';
import {
  CHANGE_LANGUAGE,
  GET_REPORTING_LANGUAGE,
  REQUEST_APP_VERSION,
  REQUEST_CONTACT_EMAIL,
  POST_EMAIL,
  CLOSE_MESSAGE,
  GET_REPORTING_PAGES,
  INIT_APP,
  INTI_HOME,
  INIT_CLIENTS,
  GET_ALL_LANGUAGE,
  GET_DYNAMIC_REPORTS,
  REQUEST_USER_ROLES_SUCCESS,
  REQUEST_USER_ROLES,
} from './type';
import { AxiosResponse } from 'axios';
import { getClientId, getUser, getUserType, setLangInCookies } from 'helpers/authUtils';
import { findFirstPageItem } from './utiles';
import handleSagaErrors from 'pages/commons/handel-saga-errors';
import { getColorsSuccess } from 'redux/actions';
import { handelGetNomonclatureFiltred } from 'pages/dashboard/saga';
import { renameProperties } from 'helpers/index';
import { USER_TYPES } from 'constants/common';
import { StatusToast } from 'pages/components/modals/toastMassage';

export function* handelGetDashboard(action: any): Generator<any, void, AxiosResponse<any, any>> {
  try {
    const clientid = getClientId();
    const response: any = yield call(GetDashboard, clientid);
    let data = response.data;
    let currentPage: any = findFirstPageItem({ list: data.pageItems, path: '', keys: [] });
    yield put(
      initNavigation({ path: currentPage.path, FirstPageCode: currentPage?.page.code, id: currentPage.page.id, keys: currentPage.keys })
    );
    yield put(getReportingPagesSuccess(data.pageItems));

    // yield call (handelGetColor,clientid);
  } catch (error: any) {
    // yield put(getDadhboardFail(error.response.data.message));
  }
}

const flattenPages: any = (pages: [any], path: string = '', pathname: string = '', keys: string[] = []) => {
  if (!Array.isArray(pages) || !pages?.length) {
    return [];
  }
  return pages.reduce((acc, curr) => {
    if (curr?.children?.length) {
      return acc.concat(flattenPages(curr.children, `${path}/${curr.code}`, `${pathname}/${curr.name}`, [...keys, curr.id?.toString()]));
    }
    return acc.concat({
      ...curr,
      path: `${path}/${curr.code}`,
      pathname: `${pathname}/${curr.name}`,
      keys: [...keys, curr.id?.toString()],
    });
  }, []);
};

function* handleInitApp(): Generator<any, void, AxiosResponse<any>> {
  const [{ data: labelsTranslations }, { data: appVersion }]: any = yield all([
    call(getTranslation, { DefaultValueOnly: true, searchTerm: `platform=CONNECT|COMMON` }),
    call(requestAppVersion),
  ]);
  yield put(requestLabelsTranslations(labelsTranslations));
  yield put(requestAppVersionSuccess(appVersion));
  //  yield call(getAllLanguage)
}

function* handleInitHome({ clientId }: { clientId: string }): Generator<any, void, AxiosResponse<any>> {
  try {
    const [{ data: clientDashboard }, { data: reportingLanguages }, { data: dynamicReports }, { data: colorPalette }, rolesList]: any = yield all([
      call(GetDashboard, clientId),
      call(requestReportingLanguage, { id: clientId }),
      call(requestDynamicReports, { id: clientId }),
      call(getColorPalette, clientId),
      ...(getUserType() !== USER_TYPES.CLIENT ? [call(requestRolesData)] : []),
    ]);
    yield put(
      getReportingPagesSuccess({
        clientName: clientDashboard.clientName,
        pageItems: clientDashboard.pageItems,
        flattenPages: flattenPages(clientDashboard.pageItems),
        sourcesTranslations: clientDashboard.sourcesTranslations,
        rolesList: renameProperties(rolesList?.data),
        dashboardStyle:clientDashboard.dashboardStyle
      })
    );
    yield put(requestReportingLanguageSuccess(reportingLanguages));
    yield put(requestDynamicReportsSuccess(dynamicReports));
    yield put(getColorsSuccess(colorPalette));
    yield put(requestUserRoles(clientId));

  } catch (error: any) {
    throw error;
  }
}

function* handleInitClients(): Generator<any, void, AxiosResponse<any>> {
  const { data } = yield call(requestDashboardClients);
  yield put(initClientsSuccess(data));
}
function* changeLanguage({ payload }: any): Generator<any, void, AxiosResponse<any>> {
  const clientId = getClientId();
  if (clientId) {
    const response1: any = yield call(requestChangeLanguage, { id: clientId }, payload?.id);
    yield put(changeLanguageSuccess(payload?.id));

    const response2: any = yield call(requestReportingLanguage, { id: clientId });
    yield put(requestReportingLanguageSuccess(response2.data));
  }

  const response: any = yield call(getTranslation, { DefaultValueOnly: true, searchTerm: `platform=CONNECT|COMMON` }, payload?.id);
  yield put(requestLabelsTranslations(response.data));
}

function* getReportingLanguage({ payload }: any): Generator<any, void, AxiosResponse<any>> {
  const clientId = getClientId();
  if (clientId) {
    const response: any = yield call(requestReportingLanguage, { id: clientId });
    yield put(requestReportingLanguageSuccess(response.data));
  }
}

function* getDynamicReports({ payload }: any): Generator<any, void, AxiosResponse<any>> {
  const clientId = getClientId();
  if (clientId) {
    const response: any = yield call(requestDynamicReports, { id: clientId });
    yield put(requestDynamicReportsSuccess(response.data));
  }
}

export const DefaultPageParameters = {
  currentPage: 1,
  pageNumber: 1,
  pageSize: 10,
  totalCount: 0,
  totalPages: 0,
  orderedBy: 'Id desc',
  filteredBy: null,
  searchTerm: '',
};

function* handleRequestUserRoles({ }: any): Generator<any, void, any> {
  const user :any= getUser()
  const paging: any = DefaultPageParameters;
  const queryParamsObject = {
    pageNumber: paging.pageNumber,
    pageSize: paging.pageSize,
    orderBy: paging.orderBy || paging.orderedBy,
    filterBy: paging.filterBy || paging.filteredBy,
  };



  const response: any = yield call(requestallUserRoles, user.unique_name, queryParamsObject);
  const userRoles: any[] = response.data; // Assuming Role is a defined type for user roles
  const userRolesPageParameters: any = response.pageParameters;
  yield put(requestUserRolesSuccess({  userRoles, userRolesPageParameters }));
}

function* getAllLanguage(): Generator<any, void, AxiosResponse<any>> {
  const response: any = yield call(requestAllLanguage);
  yield put(requestAllLanguageSuccess(response.data));
}
function* getAppVersion({ payload }: any): Generator<any, void, AxiosResponse<any>> {
  const response: any = yield call(requestAppVersion);

  yield put(requestAppVersionSuccess(response.data));
}

function* getContactList({ payload }: any): Generator<any, void, AxiosResponse<any>> {
  const response: any = yield call(requestContact);
  yield put(requestContactEmailSuccess(response.data));
}

function* sendEmail({ payload, onSuccess, onFail }: any): Generator<any, void, AxiosResponse<any>> {
  // TODO : investigate if type event needed in showToastMessage
  try {
    const response: any = yield call(sendMessage, { body: payload });
    onSuccess();
  } catch (error) {
    onFail();

  }
}

function* closeMessage(): Generator<any, void, AxiosResponse<any>> {
  // yield put(showToastMessage(data));
}

function* LayoutSaga(): any {
  yield takeLatest(INIT_APP, handleSagaErrors(handleInitApp, false));
  yield takeLatest(INTI_HOME, handleSagaErrors(handleInitHome));
  yield takeLatest(INIT_CLIENTS, handleSagaErrors(handleInitClients));
  yield takeLatest(GET_REPORTING_PAGES, handleSagaErrors(handelGetDashboard));
  yield takeLatest(GET_ALL_LANGUAGE, handleSagaErrors(getAllLanguage));
  yield takeLatest(GET_REPORTING_LANGUAGE, handleSagaErrors(getReportingLanguage));
  yield takeLatest(GET_DYNAMIC_REPORTS, getDynamicReports);
  yield takeLatest(REQUEST_USER_ROLES, handleSagaErrors(handleRequestUserRoles));
  yield takeLatest(CHANGE_LANGUAGE, handleSagaErrors(changeLanguage));
  yield takeLatest(REQUEST_APP_VERSION, getAppVersion);
  yield takeLatest(REQUEST_CONTACT_EMAIL, getContactList);
  yield takeLatest(POST_EMAIL, sendEmail);
  yield takeLatest(CLOSE_MESSAGE, closeMessage);
}
export default LayoutSaga;
