/* eslint-disable */
import axios from 'axios';
import { CXG_NODE_API, HOSTNAMES_NODE_API_MAPPING } from 'constants/common';
import * as QS from 'qs';
// import { apiMap } from 'src/common/api-map';

// const apiNodeUrl = (apiMap[window.location.hostname] && apiMap[window.location.hostname].nodeURL) || process.env.REACT_APP_CXG_ENV_API;

const getIOFUrl = () => {
  return HOSTNAMES_NODE_API_MAPPING[window.location.hostname] || CXG_NODE_API.QA;
};

export const resteToken = async (body:any) => {
  const data = QS.stringify({
    grant_type: process.env.REACT_APP_CXG_ENV_GRANT_TYPE,
    client_id: process.env.REACT_APP_CXG_ENV_CLIENT_ID,
    client_secret: process.env.REACT_APP_CXG_ENV_CLIENT_SECRET,
    scope: process.env.REACT_APP_CXG_ENV_SCOPE,
  });
  const config = {
    method: 'post',
    crossDomain: true,
    url: process.env.REACT_APP_CXG_ENV_IOF_AUTH_URL,
    headers: {
      Cookie: process.env.REACT_APP_CXG_ENV_COOKIES,
      'content-type': 'application/x-www-form-urlencoded',
    },
    data: body,
  };
  return axios(config);
};
const axiosInstance = axios.create({
  // baseURL: apiNodeUrl,
  baseURL: getIOFUrl(),
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export const getIofsList = async (queryParamsObject:any) => {
  const mainPath = '/iof/All';
  const queryString = QS.stringify(queryParamsObject);
  const config = {
    method: 'get',
    url: `${mainPath}?${queryString.slice(0, -3)}`,
  };
  return axiosInstance(config);
};
export const getIofsFieldWork = async (id:any) => {
  const config = {
    method: 'get',
    url: `/iof/fielwork/${id}`,
  };
  const res = await axiosInstance(config);
  return res;
};
export const getIofsInfos = async (id:any) => {
  const config = {
    method: 'get',
    url: `/iof/generalInfos/${id}`,
  };
  const res = await axiosInstance(config);
  return res;
};
export const getIofsEvaluatorInfos = async (id:any) => {
  const config = {
    method: 'get',
    url: `/iof/evaluator/${id}`,
  };
  const res = await axiosInstance(config);
  return res;
};

export const getInfos = async (params:any) => {
  const { apiUrl, iofId, queryParamsObject } = params;
  const queryString = QS.stringify(queryParamsObject);
  const config = {
    method: 'get',
    url: queryParamsObject
      ? `/iof/${apiUrl}/${iofId}?${queryString.slice(0, -3)}`
      : `/iof/${apiUrl}/${iofId}`,
  };
  const res = await axiosInstance(config);
  return res;
};

export const getCustomProperties = async (clienId:any) => {
  const config = {
    method: 'get',
    url: `/iof/clientProperties/${clienId}`,
  };
  const res = await axiosInstance(config);
  return res;
};
export const getAttachments = async ({ iofId ,iofName }:any) => {
  const config = {
    method: 'get',
    url: `/iof/attachments?name=${iofName}&id=${iofId}`,
  };
  const res = await axiosInstance(config);
  return res;
};

export const getPosAttachments = async ({ id,name }:any) => {
  const config = {
    method: 'get',
    url: `/iof/posAttachments?name=${name}&id=${id}`,
  };
  const res = await axiosInstance(config);
  return res;
};

export const getScenarioAttachments = async ({ id,name }:any) => {
  const config = {
    method: 'get',
    url: `/iof/scenarioAttachments?name=${name}&id=${id}`,
  };
  const res = await axiosInstance(config);
  return res;
};
