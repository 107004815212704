// @flow
import * as React from 'react';

import { useSelector } from 'react-redux';
import Notification from './Notification';
import './styles.scss';

const Notifications = () => {
    const genericNotifications:any = useSelector((state:any) => state.notificationReducer.notifications);
    return (
        <div className="notifications">
            {genericNotifications.map((genericNotification:any) => (
                <Notification
                    notification={genericNotification}
                    key={`${genericNotification.notification.id}-${genericNotification.notification.message}`}
                />
            ))}
        </div>
    );
};

export default Notifications;
