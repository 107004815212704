import { GET_AG_GRID_Data,RequestMissionDetails,CleanMissionDetails,
  RequestClientDesignSuccess,RequestClientDesign,RequestMissionDetailsSuccess,CleanClientDesign,
  handleRequestChangeMissionStatus,GET_AG_GRID_Data_SUCCESS,GET_AG_GRID_Data_FAILED,DownloadMissionsPDFs,PrintPage,
  RequestExperinceReportDocumentDownloadAsPdf,RequestExperinceReportDocumentViewer,RequestExperinceReportDocumentViewerSuccess } from './type';

export const requestAgGridTableData = (params:any) => ({
    type: GET_AG_GRID_Data,
    params,
});

export const requestAgGridTableDataSuccess = (payload:any) => (
    {
    type: GET_AG_GRID_Data_SUCCESS,
    payload,
});
export const requestAgGridTableDataFailed = (payload:any) => ({
    type: GET_AG_GRID_Data_FAILED,
    payload,
});

export const downloadMissionsPdfs = (clientId:Number, clientName:any, missions:any, widgetSettings:any, languageId:Number,widgetId:Number) => ({
    type: DownloadMissionsPDFs,
    clientId,
    clientName,
    missions,
    widgetSettings,
    languageId,
    widgetId
  });
  export const requestExperinceReportDocumentViewer = (langId:Number, clientId:Number, missionId:any, translate = false,callback:any) => ({
    type: RequestExperinceReportDocumentViewer,
    langId,
    clientId,
    missionId,
    translate,
    callback
  });
  
  export const requestExperinceReportDocumentViewerSuccess = (payload:any) => ({
    type: RequestExperinceReportDocumentViewerSuccess,
    payload,
  });
  
  export const downloadAsPdf = (id:Number, clientId:Number, element:any, locationId:any,projectName:any,ace:any) => ({
    type: RequestExperinceReportDocumentDownloadAsPdf,
    id,
    clientId,
    element,
    locationId,
    projectName,
    ace
  });
  export const printPage = (elementId = null, fileName = 'dashboard-export') => ({
    type: PrintPage,
    elementId,
    fileName,
  });
  export const requestChangeMissionStatus = ( clientId:Number, userId:string, status:Number,missionList:any,callback:any) => ({
    type: handleRequestChangeMissionStatus,
    clientId,
    userId,
    status,
    missionList,
    callback
  });
  export const requestMissionDetails = (clientId:Number, missionId:any,  settings:any) => ({
    type: RequestMissionDetails,
    clientId,
    missionId,
    settings,
  });
  export const requestMissionDetailsSuccess = (payload:any) => ({
    type: RequestMissionDetailsSuccess,
    payload,
  });
  export const requestClientDesign = (clientId:Number) => ({
    type: RequestClientDesign,
    clientId,
  });
  export const requestClientDesignSuccess = (payload:any) => ({
    type: RequestClientDesignSuccess,
    payload,
  });
  export const cleanMissionDetails = () => ({
    type: CleanMissionDetails,
  });
  export const cleanClientDesign = () => ({
    type: CleanClientDesign,
  });