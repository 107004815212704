import { useRef } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getLoggedInUser } from 'helpers/authUtils';
import { requestCheckSSOSession, requestLoginFromSSO } from 'redux/actions';
import { HOSTNAMES } from 'constants/common';
import { Loading } from 'features/Loader/Loader';

const RoutesWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectRef = useRef(false);
  const isSSORedirectSuccess = useSelector((state: any) => state.Auth.SSORedirectSuccess);

  const searchParams = new URLSearchParams(window.location.search);
  const username = searchParams.get('uid');
  const ssid = searchParams.get('ssid');
  const noSSOSession = searchParams.get('no_idp_session');
  const error = searchParams.get('error');

  const isSSOError = error === 'invalid_user';
  const redirectFromSSO = username && ssid;

  const userData: any = getLoggedInUser();

  if (isSSORedirectSuccess) {
    redirectRef.current = false;
  }

  // if (window.location.hostname !== HOSTNAMES.LOCALHOST) {
  //   if ((!userData || userData.fromSso) && !redirectFromSSO && !noSSOSession && !isSSOError) {
  //     sessionStorage.setItem('pathname', window.location.pathname);
  //     sessionStorage.setItem('search', window.location.search);
  //     dispatch(requestCheckSSOSession());
  //     return <></>;
  //   }
  // }

  if (redirectFromSSO) {
    redirectRef.current = true;
    dispatch(requestLoginFromSSO(username, ssid, navigate));
    return <Loading />;
  }

  if (noSSOSession) {
    const route = sessionStorage.getItem('pathname') ? `${sessionStorage.getItem('pathname')}${sessionStorage.getItem('search')}` : '/';
    return <Navigate to={route} replace />;
  }

  if (!redirectRef.current && isSSORedirectSuccess) {
    return <>{children} </>;
  }

  if (!redirectRef.current && !isSSORedirectSuccess) {
    return <>{children} </>;
  }

  return <></>;
};

export default RoutesWrapper;
