import { getClientId } from 'helpers/authUtils';
import {
  GetWidget,
  GetDashboard,
  getColorPalette,
  getSystLastUsed,
  GetCaochingSectionCalls,
  translateComment,
  nomonclatureFiltred,
  getQbt,
  getImgKpi,
} from '../../apis/url';
import {
  getWidgetDataFail,
  getWidgetDataSuccess,
  getColorsFail,
  getColorsSuccess,
  getDadhboardFail,
  getDadhboardSuccess,
  getLastfilterFail,
  getLastfilterSuccess,
  getSCoachingectionsSuccess,
  getSCoachingectionsFail,
  getNomofiltredSuccess,
  getNomofiltredFail,
  getQBTActionSuccess,
  getQBTActionFail,
  getIconsAction,
  getIconsActionSuccess,
  getIconsActionFail,
} from './action';

import { GET_CAOCHING_SECTIONS, GET_COLOR, GET_DASHBOARD, GET_ICONS, GET_LAST_USED, GET_NOM_FILTRED, GET_QBT, GET_WIDGET } from './type';
import { formatSysLastUsed } from './utils';
import { AxiosResponse } from 'axios';
import { call, put, takeEvery, fork, all, select, takeLatest } from 'redux-saga/effects';
import handleSagaErrors from 'pages/commons/handel-saga-errors';

export function* handelGetDashboard(idclient: any): Generator<any, void, AxiosResponse<any, any>> {
  try {
    const clientid = getClientId();
    const response: any = yield call(GetDashboard, clientid);
    yield put(getDadhboardSuccess(response.data));
    yield call(handelGetColor, clientid);
    // yield call (handelGetNomonclatureFiltred,clientid,response.data.pageItems[0].page.settings)
  } catch (error: any) {
    yield put(getDadhboardFail(error.response.data.message));
  }
}

export function* handelGetWidgets(payload: any): Generator<any, void, AxiosResponse<any, any>> {
  const { settings, filter, id, endPoint, series, source, sectionIds, questionWithAnswerIds } = payload;
  const lastFilter = yield select((s: any) => s.Dashboard.lastUpdateFilter);
  const clientId = getClientId();
  try {
    const response: any = yield lastFilter &&
      call(GetWidget, lastFilter, settings, clientId, endPoint, series, source, sectionIds, questionWithAnswerIds);
    const res = response?.data;
  
    const data = { res, id };
    yield put(getWidgetDataSuccess(data));
  } catch (error: any) {
   
    if (error.response) {
      if (error.response.status >= 400 && error.response.status < 500) {

        yield put(getWidgetDataFail("Client Error: " + error.response.data.message,id));
      } else if (error.response.status >= 500 && error.response.status < 600) {
       
        yield put(getWidgetDataFail("Server Error: " + error.response.data.message,id));
      }
    } else {
      // Handle non-response errors (e.g., network issues)
      yield put(getWidgetDataFail("Network Error: " + error.message,id));
    }
  }
}

export function* handelGetCoachingSessionServiceValues(payload: any): Generator<any, void, AxiosResponse<any, any>> {
  const { settings, sources, sectionIds } = payload;
  const clientId = getClientId();
  try {
    const response: any = yield call(GetCaochingSectionCalls, settings, clientId, sources, sectionIds);
    const res = response?.data;
    yield put(getSCoachingectionsSuccess(res));
  } catch (error: any) {
    yield put(getSCoachingectionsFail(error.response.data.message));
  }
}

export function* handelGetColor(clientid: any): Generator<any, void, AxiosResponse<any, any>> {
  try {
    const response: any = yield call(getColorPalette, clientid);
    yield put(getColorsSuccess(response.data));
  } catch (error: any) {
    yield put(getColorsFail(error.response.data.message));
  }
}
export function* handelGetNomonclatureFiltred(settings: any): any {
  const clientid = getClientId();
  try {
    const response: any = yield call(nomonclatureFiltred, clientid, settings);
    yield put(getNomofiltredSuccess(response.data));
  } catch (error: any) {
    yield put(getNomofiltredFail(error.response.data.message));
  }
}
export function* handelGetSys_Last_Used(clientId: any): Generator<any, void, AxiosResponse<any, any>> {
  try {
    const response: any = yield call(getSystLastUsed, clientId);
    let profile: any = formatSysLastUsed(response.data);
    yield put(getLastfilterSuccess(profile));
  } catch (error: any) {
    yield put(getLastfilterFail(error.response.data.message));
  }
}
export function* handelGetQBT(): Generator<any, void, AxiosResponse<any, any>> {
  try {
    const response: any = yield call(getQbt);
    yield put(getQBTActionSuccess(response.data));
  } catch (error: any) {
    yield put(getQBTActionFail(error.response.data.message));
  }
}
export function* handelGetIconsKpi(payload:any): Generator<any, void, AxiosResponse<any, any>> {
const {id}=payload
  try {
    const response: any = yield call(getImgKpi,id);
    const res = yield response?.data;
    const data =response&& { res, id };
    yield put(getIconsActionSuccess(data&&data));
  } catch (error: any) {
    yield put(getIconsActionFail(error.response.data.message));
  }
}

export function* watchGetDahboard(): any {
  yield takeLatest(GET_DASHBOARD, handleSagaErrors(handelGetDashboard));
}

export function* watchAceRanking(): any {
  yield takeEvery(GET_WIDGET,handelGetWidgets );
}
export function* watchColor(): any {
  yield takeEvery(GET_COLOR, handelGetColor);
}
export function* watchNomFiltred(): any {
  yield takeEvery(GET_NOM_FILTRED, handleSagaErrors(handelGetNomonclatureFiltred, false));
}
export function* watchGetLastFilter(): any {
  yield takeLatest(GET_LAST_USED, handelGetSys_Last_Used);
}
export function* watchHandelGetCoachingSessionServiceValues(): any {
  yield takeEvery(GET_CAOCHING_SECTIONS, handelGetCoachingSessionServiceValues);
}
export function* watchHandelGetQBT(): any {
  yield takeEvery(GET_QBT, handelGetQBT);
}
export function* watchhandelGetIconsKpi(): any {
  yield takeEvery(GET_ICONS, handelGetIconsKpi);
}
export default function* DashboardSaga(): any {
  yield all([
    fork(watchGetDahboard),
    fork(watchAceRanking),
    fork(watchGetLastFilter),
    fork(watchNomFiltred),
    fork(watchHandelGetCoachingSessionServiceValues),
    fork(watchHandelGetQBT),
    fork(watchhandelGetIconsKpi),
  ]);
}
