/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const UPDATE_USER_SESSION = 'UPDATE_USER_SESSION';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const SET_SHOW_LP_USER = 'SET_SHOW_LP_USER';
export const GET_SHOW_LP_USER = 'GET_SHOW_LP_USER';
export const GET_LANDING_PAGE = 'GET_LANDING_PAGE';
export const GET_ACTIVE_COMPAIGN = 'GET_ACTIVE_COMPAIGN';
export const GET_ACTIVE_COMPAIGN_SUCCESS = 'GET_ACTIVE_COMPAIGN_SUCCESS';
export const RESET_STATES = 'RESET_STATES';

export const GET_COMPAIGN_WITH_PATH = 'GET_COMPAIGN_WITH_PATH';
export const GET_COMPAIGN_WITH_PATH_SUCCESS = 'GET_COMPAIGN_WITH_PATH_SUCCESS';

export const GET_LANDING_PAGE_SUCCES = 'GET_LANDING_PAGE_SUCCES';
export const GOTO_LANDING_PAGE = 'GO_TO_LANDING_PAGE';
export const GOTO_DASHBOARD = 'GO_TO_DASHBOARD';
export const DASHBOARD = 'dashboard';
export const LANDING_PAGE = 'landingPage';
export const SAVE_CONTACT = 'SAVE_CONTACT';
export const SAVE_CONTACT_SUCCESS = 'SAVE_CONTACT_SUCCESS';
export const SAVE_CONTACT_FAIL = 'SAVE_CONTACT_FAIL';
export const SSO_REDIRECT = 'SSO_REDIRECT';
export const REQUEST_CHECK_SSO_SESSION = 'REQUEST_CHECK_SSO_SESSION';
export const LOGIN_USER_FROM_SSO = 'LOGIN_USER_FROM_SSO';
export const SSO_REDIRECT_SUCCESS = 'SSO_REDIRECT_SUCCESS';
export const LOGIN_USER_BY_SSO = 'LOGIN_USER_BY_SSO';
export const GET_SESSION_ID = 'GET_SESSION_ID';
export const OLD_CONNECT_REDIRECT = 'OLD_CONNECT_REDIRECT';
export const REQUEST_MY_PROFILE_SUCCESS = 'REQUEST_MY_PROFILE_SUCCESS';
