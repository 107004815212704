import {
  CLEAR_TRANSLATION,
     GET_COMMENT_DATA,
     GET_GET_COMMENT_DATA_FAIL,
     GET_GET_COMMENT_DATA_SUCCESS,
     GET_TEXT_TRANSLATION,
     GET_TEXT_TRANSLATION_FAIL,
     GET_TEXT_TRANSLATION_SUCCESS,

} 
from "./type";

const INIT_STATE = {
loading: false,
isLoginError: false,
error: null,
translatedText: new Array,
commentData:new Array,
id:null

};

const TranslationComment = (state=INIT_STATE, action:any) => {
switch (action.type) {
       case GET_TEXT_TRANSLATION :
       return { ...state, loading: true,isLoginError: false, error: null,id:action.id};
       case GET_TEXT_TRANSLATION_SUCCESS :  
         const translationsforcomment=()=>{              
            let index = state.translatedText&&state.translatedText.findIndex((obj:any) => obj.id === action.payload.id);
            if (index >= 0) {
           state.translatedText[index] = action.payload;
            } else {
            state.translatedText.push(action.payload);
            }
            return state.translatedText
      }
       return { ...state, loading: false, isLoginError: false, error: null, translatedText:translationsforcomment()};
       case GET_TEXT_TRANSLATION_FAIL :
           return { ...state, loading: false, isLoginError: true, error: action.payload ,translatedText:null};
       case CLEAR_TRANSLATION :
        const updatedObjects = state.translatedText.filter((obj) => obj.id !== action.id);
            return { ...state, loading: false, isLoginError: true, error: action.payload ,translatedText:updatedObjects};
        case GET_COMMENT_DATA:
              return { ...state,};
      case GET_GET_COMMENT_DATA_SUCCESS:
          const acerankingdata=()=>{              
                let index = state.commentData.findIndex(obj => obj.id === action.payload.id);
                if (index >= 0) {
               state.commentData[index] = action.payload;
                } else {
                state.commentData.push(action.payload);
                }
                return state.commentData
          }
              return { ...state,commentData:acerankingdata(),loading: false, error: null, isLoginError: false, };  
      case GET_GET_COMMENT_DATA_FAIL:
              return { ...state, loading: false, isLoginError: true, error: action.payload }; 
   default:
       return { ...state };
}
};
export default TranslationComment;

