import React, { useContext } from 'react';
import { LabelsTranslationsContext } from './Context';

export const EmptyArray = (tab: any) => !Array.isArray(tab) || (Array.isArray(tab) && tab.length === 0);

export const replaceKeyTags = (key: any, tags: any) => {
  let x = key;
  Object.keys(tags)
    .map((item) => ({ [`{${item}}`]: item }))
    .forEach((el) => {
      x = x.replaceAll(Object.keys(el)[0], tags[Object.values(el)[0]]);
    });
  return x;
};
export const KeyTranslations = (sources: any, key: any, defaultMessage: any) => {
  const result = key && sources.find((el: any) => el.fullKey.toLowerCase() === key);
  return result ? result.defaultValue : defaultMessage || key;
};

const Translate = ({ id = 'generic.no.translation.available', ...props }:any) => {
  const LabelsTranslations = useContext(LabelsTranslationsContext);
  const key = id.replace(/\s+/g, '').toLowerCase();
  if (EmptyArray(LabelsTranslations)) return <></>;
  const value = KeyTranslations(LabelsTranslations, key, props.defaultMessage || key);
  if (Object.keys(props).length) {
    return <span data-labelkey={key}>{`${replaceKeyTags(value, props)}`}</span>;
  }
  return <span data-labelkey={key}>{`${value}`}</span>;
};
export default Translate;

export const T = (props: any) => {
  const id = props;
  const LabelsTranslations = useContext(LabelsTranslationsContext);
  const key = id.replace(/\s+/g, '').toLowerCase();
  if (EmptyArray(LabelsTranslations)) return <></>;
  const value = KeyTranslations(LabelsTranslations, key, props.defaultMessage || key);
  return value;
};

export function TranslateToString({ id = 'generic.no.translation.available', defaultMessage = null, ...values }) {
  const LabelsTranslations = useContext(LabelsTranslationsContext);
  const key = id.replace(/\s+/g, '').toLowerCase();
  if (EmptyArray(LabelsTranslations)) return key;

  const value = KeyTranslations(LabelsTranslations, key, defaultMessage);
  if (Object.keys(values).length) {
    return replaceKeyTags(value, values);
  }

  return value;
}
export function TranslateToStringWithLabels({ LabelsTranslations=[],id = 'generic.no.translation.available', defaultMessage = '', ...values }) {
  const key = id.replace(/\s+/g, '').toLowerCase();
  if (EmptyArray(LabelsTranslations)) return key;

  const value = KeyTranslations(LabelsTranslations, key, defaultMessage);
  if (Object.keys(values).length) {
    return replaceKeyTags(value, values);
  }

  return value;
}
