import {

     GET_TEXT_TRANSLATION_VERBATIM,
     GET_TEXT_TRANSLATION_FAIL_VERBATIM,
     GET_TEXT_TRANSLATION_SUCCESS_VERBATIM,
     CLEAR_TRANSLATION_VERBATIM,
     GET_VERBATIM_DATA_SUCCESS,
     GET_VERBATIM_DATA_FAIL,
     GET_VERBATIM_DATA,
     COLLAPSED_SIDE_BAR,

} 
from "./type";

const INIT_STATE = {
loading: false,
isLoginError: false,
error: null,
translatedText: new Array,
id:null,
virbatim:new Array,
collapsed:true
};

const TranslationVerbatim = (state=INIT_STATE, action:any) => {
switch (action.type) {
       case GET_TEXT_TRANSLATION_VERBATIM :
       return { ...state, loading: true,isLoginError: false, error: null,id:action.id};
       case GET_TEXT_TRANSLATION_SUCCESS_VERBATIM :  
         const translationsforcomment=()=>{              
            let index = state.translatedText&&state.translatedText.findIndex((obj:any) => (obj.id === action.payload.id));
            if (index >= 0) {
           state.translatedText[index] = action.payload;
            } else {
            state.translatedText.push(action.payload);
            }
            return state.translatedText
      }
       return { ...state, loading: false, isLoginError: false, error: null, translatedText:translationsforcomment(),id:null};
       case GET_TEXT_TRANSLATION_FAIL_VERBATIM :
           return { ...state, loading: false, isLoginError: true, error: action.payload ,translatedText:[],id:null};
      case CLEAR_TRANSLATION_VERBATIM :
            const updatedObjects = state.translatedText.filter((obj) => obj.id !== action.id);
            return { ...state, loading: false, isLoginError: true, error: action.payload ,translatedText:updatedObjects};
            case GET_VERBATIM_DATA:
               return { ...state,};
       case GET_VERBATIM_DATA_SUCCESS:
           const acerankingdata=()=>{              
                 let index = state.virbatim.findIndex(obj => obj.id === action.payload.id);
                 if (index >= 0) {
                state.virbatim[index] = action.payload;
                 } else {
                 state.virbatim.push(action.payload);
                 }
                 return state.virbatim
           }
               return { ...state,commentData:acerankingdata(),loading: false, error: null, isLoginError: false, };  
       case GET_VERBATIM_DATA_FAIL:
               return { ...state, loading: false, isLoginError: true, error: action.payload }; 
      case COLLAPSED_SIDE_BAR:
                return { ...state,collapsed:action.collapsed }; 
   default:
       return { ...state };
}
};
export default TranslationVerbatim;

