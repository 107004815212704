/* eslint-disable  */

import { Cookies } from 'react-cookie';
import { TOKEN_COOKIE_NAME, LANGUAGE_COOKIE_NAME } from '../../constants/cookie-names';
import jwt_decode from 'jwt-decode';

export const getLoggedInUser = () => {
  const cookies = new Cookies();
  const user = cookies.get(TOKEN_COOKIE_NAME);
  return user ? jwt_decode(user) : null;
};
export const getUserToken = (name = TOKEN_COOKIE_NAME) => {
  const cookies = new Cookies();
  const Token = cookies.get(name);
  return Token ? Token : null;
};
// export const getLangFromCookies = () => {
//   const cookies = new Cookies();
//   const lang = cookies.get(LANGUAGE_COOKIE_NAME);
//   return lang;
// };
