/* eslint-disable eqeqeq */
import React, { useRef } from 'react';
import { Typography, Input, Checkbox, Space } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { NoData, SearchIcon } from 'assets/images';
import { CheckAllSearchIcon } from 'assets/images';
import { CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons';
import ListLoading from './listLoading';
import { changeFilterData, requestFilterData } from 'redux/actions';
import { findBy, hasValues } from 'helpers/index';
import { LOCATION_TYPE, REQUEST_FILTER_DATA_TYPE, SELECT_STATUS } from '../types';
import { handleExpandTreeItem } from '../utils';
import Translate, { TranslateToString } from 'pages/commons/Translate';

const { Text } = Typography;

type Props = {
  data: any;
  listSelected: any;
  item: any;
  searchText: any;
  onSearch: (event: any) => void;
  onSelect: (node: any, level?: any) => void;
  onSelectAll: () => void;
  selectedPage: any;
  filterTemplateDefintion: any;
  filterTemplate: any;
  generalTemplateSettings: any;
  checkAllRef: React.MutableRefObject<boolean>;
};
const LocationFilter: React.FC<Props> = ({
  data,
  onSelect,
  onSelectAll,
  item,
  onSearch,
  searchText,
  selectedPage,
  filterTemplate,
  filterTemplateDefintion,
  generalTemplateSettings,
  checkAllRef,
}) => {
  const dispatch = useDispatch();
  const expandedNodeRef = useRef<string[]>([]);
  const isLoading = useSelector((state: any) => state.Filter.loadingFilters[item?.id]);
  const initialData: any[] = useSelector((state: any) => state.Filter.filtersData[item?.id]);
  const locationsOptions = item.options;
  const sources = selectedPage?.page?.settings?.sources;
  const initialLoading = isLoading?.status && isLoading?.loadingRequestType !== REQUEST_FILTER_DATA_TYPE.EXPAND_NODE;
  const isNodeLoading = (guid: string) =>
    isLoading?.status && isLoading?.loadingRequestType === REQUEST_FILTER_DATA_TYPE.EXPAND_NODE && expandedNodeRef.current?.at(-1) === guid;

  const onExpend = (node: any, ind: any) => {
    const isLastCategoryLevel = ind == locationsOptions.length - 1;
    const expandedResult = handleExpandTreeItem(data, node.expanded, node);
    dispatch(changeFilterData(item.id, expandedResult));

    if (isLastCategoryLevel && !node.expanded && !searchText && node?.additionalData?.totalStore !== -1) {
      const config = findBy(filterTemplateDefintion, 'id', item.id, 'config', null);
      if (config.endPoint) {
        dispatch(
          requestFilterData(
            item,
            filterTemplate,
            sources,
            REQUEST_FILTER_DATA_TYPE.EXPAND_NODE,
            {
              ...config,
              queryParams: {
                ...config.queryParams,
                onlyCategories: false,
                lowestLevelCategories: node?.additionalData?.lowestLevelCategories,
              },
            },
            {
              ...generalTemplateSettings,
              ...(hasValues(generalTemplateSettings.listFilters)
                ? { listFilters: generalTemplateSettings.listFilters.filter((f: any) => f.filterId != item.id) }
                : {
                    listFilters: [],
                  }),
              locationOldResults: initialData,
            }
          )
        );
      }
      expandedNodeRef.current = [...expandedNodeRef.current, node.guid];
    }
  };

  const RenderTreeItem = (list: any, ind: number, prevParent?: any) => {
    let index1 = ind + 1;

    return list.map((nodeTree: any, index: number) => {
      let newKey = prevParent != undefined ? `${prevParent}-${nodeTree.id}` : `${nodeTree.id}`;

      return (
        <div key={nodeTree.guid} style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
              width: '100%',
            }}>
            <Space size={10} style={{ width: '70%' }}>
              {nodeTree.parentId != 0 && (
                <div
                  style={{
                    height: 1,
                    width: 15,
                    border: '0.5px solid rgb(187, 97, 37)',
                    marginLeft: -5,
                  }}></div>
              )}
              <Checkbox
                indeterminate={nodeTree.status == SELECT_STATUS.PARTIAL}
                style={{ borderRadius: 25 }}
                onChange={(e) => {
                  onSelect(nodeTree, e.target.checked);
                }}
                checked={!!checkAllRef.current || nodeTree.status == SELECT_STATUS.SELECTED}
              />
              {index1 < locationsOptions.length && !!nodeTree?.additionalData?.totalStore && (
                <>
                  {nodeTree.expanded && (
                    <CaretDownOutlined
                      style={{ color: '#9DD3C9', fontSize: 12 }}
                      onClick={() => {
                        onExpend(nodeTree, index1);
                      }}
                    />
                  )}
                  {nodeTree.expanded || (
                    <CaretRightOutlined
                      style={{ color: '#9DD3C9', fontSize: 12 }}
                      onClick={() => {
                        onExpend(nodeTree, index1);
                      }}
                    />
                  )}
                </>
              )}
              <Text
                className="cxg-text"
                style={{
                  color: '#003C4C',
                  fontSize: 10,
                  fontWeight: 400,
                  flex: 1,
                  display: 'flex',
                }}>
                {nodeTree.resultItems}{' '}
              </Text>
            </Space>
            {nodeTree?.additionalData && index1 == locationsOptions.length - 1 && nodeTree?.additionalData?.totalStore >= 0 && (
              <div
                style={{
                  alignSelf: 'flex-end',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}>
                <span
                  className="cxg-text"
                  style={{
                    fontSize: 10,
                    fontWeight: 600,
                    color: 'rgb(187, 97, 37)',
                  }}>
                  <Translate id="generic.stores" value={nodeTree?.additionalData.totalStore} />
                </span>
              </div>
            )}
            {nodeTree?.additionalData && nodeTree?.resultType == LOCATION_TYPE.STORE && (
              <div
                style={{
                  width: 45,
                  alignSelf: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}>
                {filterTemplate?.displayTotalNumberOfSurveysStoreLevel && (
                  <span className="cxg-text" style={{ fontSize: 10, fontWeight: 400, color: '#9dd3c9' }}>
                    <Translate id="generic.surveys" value={nodeTree?.additionalData?.surveyNumber} />
                  </span>
                )}
              </div>
            )}
          </div>
          {isNodeLoading(nodeTree.guid) && <ListLoading size={'small'} />}
          {!isNodeLoading(nodeTree.guid) && nodeTree.expanded && hasValues(nodeTree.children) && (
            <div
              style={{
                display: 'flex',
                marginLeft: index1 == locationsOptions.length - 1 ? ind * 10 : nodeTree.parentId != 0 ? (ind + 1) * 10 : 0,
                borderLeft: '1px solid rgb(187, 97, 37)',
                paddingLeft: 5,
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
                // background:`rgba(100, 97, 37,${index1/1.2})`
              }}
              key={`${nodeTree.guid}`}>
              {RenderTreeItem(nodeTree.children, index1, newKey)}
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <Space direction="vertical" size={20} style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: 5,
        }}>
        <CheckAllSearchIcon style={{ width: 20, cursor: 'pointer' }} onClick={onSelectAll} />
        <Input
          className="filter-item__input-search"
          placeholder={TranslateToString({ id: 'generic.search' })}
          prefixCls="search"
          prefix={<SearchIcon />}
          style={{
            borderRadius: '25px',
            marginLeft: '10px',
            flex: 1,
            width: 240,
          }}
          value={searchText}
          onChange={onSearch}
        />
      </div>
      <div style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
        {initialLoading && <ListLoading size={'large'} />}
        {!initialLoading && hasValues(data) && <div style={{ paddingInline: 10 }}> {RenderTreeItem(data, 0)}</div>}
        {!initialLoading && !hasValues(data) && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
            <NoData />
            <div style={{ color: '#003C4c', fontFamily: 'FS Siena', fontSize: '12px', marginTop: '5px' }}>
              <Translate id="generic.nodataavailable" defaultMessage="No Data Available" />
            </div>
          </div>
        )}
      </div>
    </Space>
  );
};

export default LocationFilter;
