import { TranslateToString } from "pages/commons/Translate"

export const formatSysLastUsed =(data:any)=>{
let formattedData:any ={}

Object.entries(data).map(([key,value]:any[])=>{
   if(Array.isArray(value) && value.length>0 ||!Array.isArray(value)&& value!=null )  formattedData[key]=value 
  
})
//formattedData['source']=["CXE"]
return formattedData
}
export const opts:any={renderer:"svg",}
export const getDate=(timestamp:any,datePeriod:any,lastFilter:any)=>{
   const date = new Date(timestamp * 1000);
   const quarterNumber=datePeriod&&Number(datePeriod.charAt(1))

   const monthNames = [
    TranslateToString({id:"generic.months.jan"}), TranslateToString({id:"generic.months.feb"}),
    TranslateToString({id:"generic.months.mar"}), TranslateToString({id:"generic.months.apr"}),
    TranslateToString({id:"generic.months.may"}), TranslateToString({id:"generic.months.jun"}),
    TranslateToString({id:"generic.months.jul"}), TranslateToString({id:"generic.months.aug"}), 
    TranslateToString({id:"generic.months.sep"}), TranslateToString({id:"generic.months.oct"}), 
    TranslateToString({id:"generic.months.nov"}),TranslateToString({id:"generic.months.dec"})
   ];
  const month=Number (datePeriod.slice(datePeriod.indexOf('-') + 1))
  const monthName = monthNames[month-1];

const quarter=[
   TranslateToString({id:"generic.quarters.q1"}), TranslateToString({id:"generic.quarters.q2"}),
   TranslateToString({id:"generic.quarters.q3"}), TranslateToString({id:"generic.quarters.q4"}),
]

  if(lastFilter&&lastFilter?.period?.type===0||lastFilter&&lastFilter?.period?.type===2){
  return monthName+"-"+date.getFullYear()
  }
  if(lastFilter&&lastFilter?.period?.type===5){
  
   return quarter[quarterNumber-1]+"-"+date.getFullYear()
   }
 else if( lastFilter&&lastFilter?.period?.type===1)
{
  return datePeriod
}
else if(lastFilter&&lastFilter?.period?.type===3)
{
  return date.getDay() +" "+monthName
}
else return date.getFullYear()
  } 

  