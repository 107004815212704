export const HOSTNAMES = {
  LOCALHOST: 'localhost',
  QA: 'cxgconnect-qa-v2.azurewebsites.net',
  PREPROD: 'cxg-preprod.cxgconnect.com',
  PREPROD_FR: 'cxgconnect-preprod-frctl.azurewebsites.net',
  PROD: 'app.cxgconnect.com',
  PROD_2: 'app2.cxgconnect.com',
  PROD_FR: 'cxgconnect-app-frctl.azurewebsites.net',
  CHINA: 'app.cxgconnect.cn',
  CHINA_2: 'app2.cxgconnect.cn',
};

export const CXG_DOTNET_API = {
  QA: 'https://cxgconnectv3-api-qa.azurewebsites.net/',
  PREPROD: 'https://cxgconnect-api-demo.azurewebsites.net/',
  PREPROD_FR: 'https://cxgconnect-api-demo-frctl.azurewebsites.net/',
  PROD: 'https://cxapi.cxgconnect.com/',
  PROD_FR: 'https://cxgconnect-api-frctl.azurewebsites.net/',
  CHINA: 'https://cxgconnect-api.cxgconnect.cn/',
};

export const CXG_NODE_API = {
  QA: 'https://aol-migration-qa.azurewebsites.net/',
  PREPROD: 'https://aol-migration-demo.azurewebsites.net/',
  PREPROD_FR: 'https://aol-migration-demo-frctl.azurewebsites.net/',
  PROD: 'https://aol-migration-frctl.azurewebsites.net/',
  PROD_FR: 'https://aol-migration-frctl.azurewebsites.net/',
  CHINA: 'https://integ.cxgconnect.cn/',
};

export const HOSTNAMES_DOTNET_API_MAPPING = {
  [HOSTNAMES.LOCALHOST]: CXG_DOTNET_API.QA,
  [HOSTNAMES.QA]: CXG_DOTNET_API.QA,
  [HOSTNAMES.PREPROD]: CXG_DOTNET_API.PREPROD,
  [HOSTNAMES.PREPROD_FR]: CXG_DOTNET_API.PREPROD_FR,
  [HOSTNAMES.PROD]: CXG_DOTNET_API.PROD,
  [HOSTNAMES.PROD_2]: CXG_DOTNET_API.PROD,
  [HOSTNAMES.PROD_FR]: CXG_DOTNET_API.PROD_FR,
  [HOSTNAMES.CHINA]: CXG_DOTNET_API.CHINA,
  [HOSTNAMES.CHINA_2]: CXG_DOTNET_API.CHINA,
};

export const HOSTNAMES_NODE_API_MAPPING = {
  [HOSTNAMES.LOCALHOST]: CXG_NODE_API.QA,
  [HOSTNAMES.QA]: CXG_NODE_API.QA,
  [HOSTNAMES.PREPROD]: CXG_NODE_API.PREPROD,
  [HOSTNAMES.PREPROD_FR]: CXG_NODE_API.PREPROD_FR,
  [HOSTNAMES.PROD]: CXG_NODE_API.PROD,
  [HOSTNAMES.PROD_2]: CXG_NODE_API.PROD,
  [HOSTNAMES.PROD_FR]: CXG_NODE_API.PROD_FR,
  [HOSTNAMES.CHINA]: CXG_NODE_API.CHINA,
  [HOSTNAMES.CHINA_2]: CXG_NODE_API.CHINA,
};

export const GTM = {
  [HOSTNAMES.QA]: 'GTM-M8ZQC2J3',
  [HOSTNAMES.PROD]: 'GTM-M3CK2JMM',
};
export const CLARITY = {
  [HOSTNAMES.QA]: 'kpxs9dr9c6',
  [HOSTNAMES.PROD]: 'k5wabdcof1',
};

export const USER_TYPES = {
  ADMIN: "Admin",
  USER: "User",
  EVALUATOR: "Evaluator",
  CLIENT: "Client",
  CRM: "CRM",
};

export const SYS_LAST_USED = "SYS_LAST_USED";

export const CXG_COLORS_PALETTE = {
  WARM_BLACK: "#003C4C",
  RUDDY_BROWN: "#BB6125",
  PALE_ROBIN_EGG_BLUE: "#9DD3C9",
  AMERICAN_SILVER: "#D0CFCD",
  WHITE: "#FFFFFF",
};

export const PERIODS_PREFIX = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
  "Q1",
  "Q2",
  "Q3",
  "Q4",
];
export const allLanguages = [
  {
    id: 32,
    name: "Chinese (Hong Kong SAR China)",
    code: "zh-HK",
    flag: "HKG",
    showCXGConnect: true,
    showMobileApp: false,
  },

  {
    id: 29,
    name: "Korean",
    code: "ko",
    flag: "KOR",
    localizedName: "한국어",
    showCXGConnect: true,
    showMobileApp: true,
  },
  {
    id: 28,
    name: "Japanese",
    code: "ja",
    flag: "JPN",
    showCXGConnect: true,
    showMobileApp: true,
  },
  {
    id: 4,
    name: "English",
    code: "EN",
    flag: "GBR",
    localizedName: "English",
    showCXGConnect: true,
    showMobileApp: true,
  },
  {
    id: 19,
    name: "Simplified Chinese",
    code: "zh-CN",
    flag: "CHN",
    localizedName: "简体中文",
    showCXGConnect: true,
    showMobileApp: true,
  },
  {
    id: 18,
    name: "Traditional Chinese",
    code: "zh-TW",
    flag: "TWN",
    localizedName: "繁體中文",
    showCXGConnect: true,
    showMobileApp: true,
  },
  {
    id: 17,
    name: "Thai",
    code: "TH",
    flag: "THA",
    showCXGConnect: true,
    showMobileApp: true,
  },

  {
    id: 8,
    name: "Italiano",
    code: "IT",
    flag: "ITA",
    localizedName: "Italiano",
    showCXGConnect: true,
    showMobileApp: false,
  },
  {
    id: 7,
    name: "Français",
    code: "FR",
    flag: "FRA",
    localizedName: "Français",
    showCXGConnect: true,
    showMobileApp: false,
  },
  {
    id: 6,
    name: "Español",
    code: "ES",
    flag: "ESP",
    localizedName: "Español",
    showCXGConnect: false,
    showMobileApp: false,
  },

  {
    id: 3,
    name: "Deutsch",
    code: "DE",
    flag: "DEU",
    localizedName: "Deutsch",
    showCXGConnect: true,
    showMobileApp: false,
  },
];

export const keysToCheck = [
  "Ill-Mouther",
  "Definitely not buy",
  "Strongly Disagree",
  "Advisor Indifferent",
  "Very unsafe",
];
