/* eslint-disable eqeqeq */
import React, { useState, useRef, useMemo } from 'react';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import moment from 'moment';
import { convertToTimestamp } from '../../utils';

const { Text } = Typography;

type Props = {
  start: number;
  end: number;
  handleChange: (from: number | null, to: number | null) => void;
};

const YearRangePicker: React.FC<Props> = ({ start, end, handleChange }) => {
  const yearRef = useRef([0, 0]);
  const [currentYear, setCurrentYear] = useState(moment().format('YYYY'));

  const addYear = () => {
    if (moment(currentYear).format('YYYY') < moment().format('YYYY')) {
      let newYear: any = moment(currentYear).add(12, 'year');
      setCurrentYear(newYear);
    }
  };
  const removeYear = () => {
    let newYear: any = moment(currentYear).subtract(12, 'year');
    setCurrentYear(newYear);
  };
  const listData = useMemo(() => {
    return Array.from({ length: 12 }).map((_, i) => moment(currentYear).subtract(12 - i - 1, 'year'));
  }, [currentYear]);

  const onHandleChange = (value: any) => {
    const startValue = convertToTimestamp(moment(value).startOf('year'));
    const endValue = convertToTimestamp(moment(value).endOf('year'));
    if (yearRef.current?.[0] == 0 && yearRef.current?.[1] == 0) {
      handleChange(startValue, endValue);
      yearRef.current = [startValue, endValue];
    } else if (yearRef.current?.[0] == startValue || yearRef.current?.[1] == endValue) {
      handleChange(startValue, endValue);
      yearRef.current = [startValue, endValue];
    } else if (startValue > yearRef.current?.[0] && endValue < yearRef.current?.[1]) {
      handleChange(startValue, endValue);
      yearRef.current = [startValue, endValue];
    } else if (startValue == yearRef.current?.[0] && endValue == yearRef.current?.[1]) {
      handleChange(startValue, endValue);
      yearRef.current = [startValue, endValue];
    } else {
      const minPeriod = Math.min(startValue, start);
      const maxPeriod = Math.max(endValue, end);
      handleChange(minPeriod, maxPeriod);
      yearRef.current = [minPeriod, maxPeriod];
    }
  };

  const getButtonClassName = (year: any) => {
    const startTimestamp = convertToTimestamp(moment(year).startOf('year'));
    const endTimestamp = convertToTimestamp(moment(year).endOf('year'));
    if (startTimestamp == start && endTimestamp == end) {
      return 'single';
    }
    if (startTimestamp == start && endTimestamp < end) {
      return 'rangeStart';
    }
    if (startTimestamp > start && endTimestamp == end) {
      return 'rangeEnd';
    }
    if (startTimestamp > start && endTimestamp < end) {
      return 'selected-range';
    }
    return 'item';
  };

  const renderYearItem = (year: any) => {
    return (
      <Button key={year} onClick={() => onHandleChange(year)} className={getButtonClassName(year)}>
        {moment(year).format('YYYY')}
      </Button>
    );
  };

  return (
    <Space direction="vertical" size={20}>
      <Space size={48} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CaretLeftOutlined style={{ fontSize: 20, color: '#BB6125' }} onClick={removeYear} />
        <Text className={'cxg-text'} style={{ fontSize: 14, fontWeight: 'bold', color: '#003C4C', textAlign: 'center' }}>
          {moment(currentYear).format('YYYY')}
        </Text>
        <CaretRightOutlined
          style={{ fontSize: 20, color: moment(currentYear).format('YYYY') < moment().format('YYYY') ? '#BB6125' : 'gray' }}
          onClick={addYear}
        />
      </Space>
      <Space
        size={[0, 10]}
        className="yearly"
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
        {listData.map((year) => renderYearItem(year))}
      </Space>
    </Space>
  );
};

export default YearRangePicker;
