import {
  APPLY_GLOBAL_FILTER,
  INIT_GLOBAL_FILTER,
  GET_FILTER_TEMPLATE_SUCCESS,
  RESET_GLOBAL_FILTER,
  GET_TOTAL_SURVEY_NUMBER_SUCCESS,
  FilterModel,
  REQUEST_FILTER_DATA,
  REQUEST_FILTER_DATA_SUCCESS,
  ON_CHANGE_FILTER_MODEL,
  CHANGE_FILTER_DATA,
  INIT_FITLER_LOADING,
  APPLY_FILTER,
  CLEAR_GLOBAL_FILTER,
  SET_ACTIVE_FILTER,
  GET_HEADER_INFO_SUCCESS,
} from './types';

export const initGlobalFilter = (page: any, withRefreshDashboard: boolean = true) => ({
  type: INIT_GLOBAL_FILTER,
  payload: { page, withRefreshDashboard },
});

export const clearGlobalFilter = () => ({ type: CLEAR_GLOBAL_FILTER });

export const getFilterTemplateSuccess = (template: any, filterTemplateDefintion: any[], initialModel: any): any => ({
  type: GET_FILTER_TEMPLATE_SUCCESS,
  payload: template,
  filterTemplateDefintion,
  initialModel,
});

export const requestFilterData = (filter: FilterModel, template: any, sources: any, requestType: string, config?: any, body?: any) => ({
  type: REQUEST_FILTER_DATA,
  filter,
  template,
  sources,
  requestType,
  config,
  body,
});

export const requestFilterDataSuccess = (id: number, formatedData: any, initialData?: any, initialModel?: any) => ({
  type: REQUEST_FILTER_DATA_SUCCESS,
  id,
  formatedData,
  initialData,
  initialModel,
});

export const changeFilterData = (id: number, data: any) => ({
  type: CHANGE_FILTER_DATA,
  id,
  data,
});

export const onChangeFilterModel = (key: string, value: any, source?: any, rawDynamicFilterProperties?: any) => ({
  type: ON_CHANGE_FILTER_MODEL,
  key,
  value,
  source,
  rawDynamicFilterProperties,
});

export const initFiltersLoading = (target: number | string, value: boolean, loadingRequestType: string) => ({
  type: INIT_FITLER_LOADING,
  target,
  value,
  loadingRequestType,
});

export const onApplyFilter = (page: any) => ({
  type: APPLY_FILTER,
  payload: page,
});

export const resetGlobalFilter = () => ({
  type: RESET_GLOBAL_FILTER,
});

export const getTotalNumberOfSurveysSuccess = (payload: any[]) => ({
  type: GET_TOTAL_SURVEY_NUMBER_SUCCESS,
  payload,
});

export const getHeaderInfoSuccess = (payload: any[]) => ({
  type: GET_HEADER_INFO_SUCCESS,
  payload,
});

export const applyGlobalFilter = (callback: any) => ({
  type: APPLY_GLOBAL_FILTER,
  callback,
});

export const setActiveFilter = (payload: string | null) => ({
  type: SET_ACTIVE_FILTER,
  payload,
});
