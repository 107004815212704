/* eslint-disable  */

import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
// import handleSagaErrors from 'src/common/handle-saga-errors';
import {
  GetAgGridData,
 } from "../../../../apis/url";

import {
    GET_ALLQUESTION_DISTRIBUTION,
    
  
} from "./type";
import {
    requestAllQuestionDistributionDataSuccess,
  
 
} from "./action";
import { getClientId } from "helpers/authUtils";

function* handleRequestAgGridData({ payload }) {
  const { endPoint, widgetSetting, lastFilter, mergeDataHandler, parentSectionName, parentId, oldData, widgetId, expand } = payload;

   const clientId = getClientId();
  
 
  const response = yield call(
    GetAgGridData,
    widgetSetting,
    clientId,
    endPoint,
   undefined,
   undefined  ,
   undefined,
   undefined,
   lastFilter,
    mergeDataHandler,
    parentSectionName,
    parentId,
    oldData,
    undefined,
    expand?expand:'',
  );


     if (response.status === 200) {
       const data = response.data;
       if (oldData && parentId) {
         data.oldData = oldData;
         data.parentId = parentId;
       }

    yield put(
        requestAllQuestionDistributionDataSuccess(
            {
                data,
                widgetId
            }
         
       // pageParameters: queryParamsObject,
    )
  );
   }


 }
 
 





export default function* AllQuestionDistributionSaga() {
  yield takeEvery(GET_ALLQUESTION_DISTRIBUTION, handleRequestAgGridData);
  

}
