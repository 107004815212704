import { call, put } from 'redux-saga/effects';

import { addGenericNotificationFromError } from './notifications/action';
import { addDownloadingLoader, removeDownloadingLoader } from 'features/Loader/actions';
import { openGlobalNotification } from 'pages/components/notifications';
import { TranslateToStringWithLabels } from './Translate';
function handleSagaDownloadErrors(saga: any, showLoadingIndicator = true) {
  return function* callSaga(action: any) {
    try {

      let message='Your download is now in progress'
      if (action?.payload?.LabelsTranslations?.length>0)
       message = action?.payload?.filename + ' ' + TranslateToStringWithLabels({
        LabelsTranslations: action?.payload?.LabelsTranslations,
        id: `generic.dynamic.reports.inprogress`
        , defaultMessage: ': Download in progress!'
      }) + ' !';
      
      openGlobalNotification({ status: "info", message: message, placement: 'bottomRight' });

      if (showLoadingIndicator) {
        yield put(addDownloadingLoader(action.type, action?.payload?.type));
      }
      yield call(saga, action);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(error);
      }

      yield put(addGenericNotificationFromError(error));
    } finally {
      if (showLoadingIndicator) {
        // yield put(removeDownloadingLoader(action.type));
        yield put(removeDownloadingLoader(action.type, action?.payload?.type));

      }
    }
  };
}

export default handleSagaDownloadErrors;
