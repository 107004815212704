import { ActionTypes } from './types';

export const requestIOFSAccess = (payload:any) => ({
  type: ActionTypes.RequestIOFSAccess,
  payload,
});

export const requestIOFSAccessSuccess = (payload:any) => ({
  type: ActionTypes.RequestIOFSAccessSuccess,
  payload,
});

export const requestIOFSListData = (payload:any) => ({
  type: ActionTypes.RequestIOFSListData,
  payload,
});

export const requestIOFSListDataSuccess = (payload:any) => ({
  type: ActionTypes.RequestIOFSListDataSuccess,
  payload,
});
export const requestIofItemDetails = (payload:any) => ({
  type: ActionTypes.requestIofItemDetails,
  payload,
});
export const saveCurrentIofItem = (payload:any) => ({
  type: ActionTypes.SaveCurrentIofItem,
  payload,
});
export const requestFieldWork = (payload:any) => ({
  type: ActionTypes.requestFieldWork,
  payload,
});
export const requestFieldWorkSuccess = (payload:any) => ({
  type: ActionTypes.requestFieldWorkSuccess,
  payload,
});

export const requestIofInfosSuccess = (payload:any) => ({
  type: ActionTypes.requestIofInfosSuccess,
  payload,
});
export const requestIofInfos = (payload:any) => ({
  type: ActionTypes.requestIofInfos,
  payload,
});
// pos
export const requestIofPosesSuccess = (payload:any) => ({
  type: ActionTypes.requestIofPosSuccess,
  payload,
});
export const requestIofPoses = (payload:any) => ({
  type: ActionTypes.requestIofPos,
  payload,
});
// pos by  id
export const requestPosByIdSuccess = (payload:any) => ({
  type: ActionTypes.requestIofPosByIdSuccess,
  payload,
});
export const requestIofPosById = (payload:any) => ({
  type: ActionTypes.requestIofPosById,
  payload,
});

// eval
export const requestIofEvaluatorSuccess = (payload:any) => ({
  type: ActionTypes.requestIofEvaluatorSuccess,
  payload,
});
export const requestIofEvaluator = (payload:any) => ({
  type: ActionTypes.requestIofEvaluator,
  payload,
});
// analysis
export const requestIofAnalysisSuccess = (payload:any) => (
  {
    type: ActionTypes.requestIofAnalysisSuccess,
    payload,
  }
);
export const requestIofAnalysis = (payload:any) => ({
  type: ActionTypes.requestIofAnalysis,
  payload,
});
// pm&report
export const requestIofPmSuccess = (payload:any) => ({
  type: ActionTypes.requestIofPmSuccess,
  payload,
});
export const requestIofPm = (payload:any) => ({
  type: ActionTypes.requestIofPm,
  payload,
});
// scenareo
export const requestIofScenarioSuccess = (payload:any) => ({
  type: ActionTypes.requestIofScenarioSuccess,
  payload,
});
export const requestIofScenario = (payload:any) => ({
  type: ActionTypes.requestIofScenario,
  payload,
});

// scenario by  id
export const requestScenarioByIdSuccess = (payload:any) => ({
  type: ActionTypes.requestIofScenarioByIdSuccess,
  payload,
});
export const requestScenarioById = (payload:any) => ({
  type: ActionTypes.requestIofScenarioById,
  payload,
});

export const requestAttachmentSuccess = (payload:any) => ({
  type: ActionTypes.requestAttachmentSuccess,
  payload,
});
export const requestAttachment = (payload:any) => ({
  type: ActionTypes.requestAttachment,
  payload,
});

// pos attachments
export const requestPosAttachmentSuccess = (payload:any) => ({
  type: ActionTypes.requestPosAttachmentSuccess,
  payload,
});
export const requestPosAttachment = (payload:any) => ({
  type: ActionTypes.requestPosAttachment,
  payload,
});
// scenario attachments
export const requestScenarioAttachmentSuccess = (payload:any) => ({
  type: ActionTypes.requestScenarioAttachmentSuccess,
  payload,
});
export const requestScenarioAttachment = (payload:any) => (
  {
    type: ActionTypes.requestScenarioAttachment,
    payload,
  }
);
