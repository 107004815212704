export const DOWNLOAD_REPORTS_TYPE_1 = 'DOWNLOAD_REPORTS_TYPE_1';
export const DOWNLOAD_REPORTS_TYPE_2 = 'DOWNLOAD_REPORTS_TYPE_2';
export const DOWNLOAD_REPORTS_TYPE_3 = 'DOWNLOAD_REPORTS_TYPE_3';
export const DOWNLOAD_REPORTS_TYPE_4 = 'DOWNLOAD_REPORTS_TYPE_4';
export const DOWNLOAD_REPORTS_TYPE_5 = 'DOWNLOAD_REPORTS_TYPE_5';
export const DOWNLOAD_REPORTS_TYPE_6 = 'DOWNLOAD_REPORTS_TYPE_6';
export const DOWNLOAD_REPORTS_TYPE_7 = 'DOWNLOAD_REPORTS_TYPE_7';
export const DOWNLOAD_REPORTS_SUCCESS = 'DOWNLOAD_REPORTS_SUCCESS';
export const DOWNLOAD_REPORTS_FAIL = 'DOWNLOAD_REPORTS_FAIL';

