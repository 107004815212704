import { getLangFromCookies } from 'helpers/authUtils';
import {
  REQUEST_LABELS_TRANSLATIONS,
  GET_REPORTING_LANGUAGE_SUCCESS,
  REQUEST_APP_VERSION_SUCCESS,
  REQUEST_CONTACT_EMAIL_SUCCESS,
  SHOW_MESSAGE,
  CLOSE_MESSAGE,
  START_LOADING,
  STOP_LOADING,
  START_STORE_LOADING,
  STOP_STORE_LOADING,
  GET_REPORTING_PAGES,
  GET_REPORTING_PAGES_SUCCESS,
  INIT_NAVIGATION,
  CLEAN_REPORTING_PAGES,
  CHANGE_LANGUAGE_SUCCESS,
  INIT_CLIENTS_SUCCESS,
  GET_ALL_LANGUAGE_SUCCESS,
  GET_DYNAMIC_REPORTS_SUCCESS,
  REQUEST_USER_ROLES_SUCCESS,
} from './type';

const INIT_STATE: State = {
  languageId: -1,
  LabelsTranslations: [],
  reportingLanguage: [],
  flattenPages: [],
  appVersion: null,
  isOpenMessageNotif: false,
  message: '',
  status : '',
  contactList: [],
  loading: false,
  storeLoading: false,
  reportingPages: [],
  firstPagePath: '',
  firstDashboardId: '',
  keys: [],
  FirstPageCode: '',
  clients: [],
  sourcesTranslations: [],
  allLanguage: [],
  dynamicReports:{},
  dashboardStyle:""
};
type LayoutAction = { type: string; payload?: any };
type State = {
  languageId: number;
  LabelsTranslations: any;
  reportingLanguage: any;
  flattenPages: any;
  appVersion: any;
  message: string;
  isOpenMessageNotif: boolean;
  contactList: any;
  loading: boolean;
  storeLoading: boolean;
  reportingPages: any[];
  firstPagePath: string;
  firstDashboardId: string;
  keys: any[];
  FirstPageCode: any;
  clients: any[];
  sourcesTranslations: any[];
  allLanguage: any;
  status : string;
  dynamicReports:any;
  dashboardStyle:any
};

const Layout = (state: State = INIT_STATE, action: LayoutAction) => {
  switch (action.type) {
    case INIT_NAVIGATION:
      return {
        ...state,
        firstPagePath: action.payload.path,
        firstDashboardId: action.payload.id,
        keys: action.payload.keys,
        FirstPageCode: action.payload.FirstPageCode,
      };
    case CHANGE_LANGUAGE_SUCCESS:
      return {
        ...state,
        languageId: action.payload?.id,
      };
    case REQUEST_LABELS_TRANSLATIONS:
      return {
        ...state,
        LabelsTranslations: action.payload,
      };
    case GET_REPORTING_PAGES:
      return { ...state };
    case GET_REPORTING_PAGES_SUCCESS:
      return {
        ...state,
        reportingPages: action.payload.pageItems,
        flattenPages: action.payload.flattenPages,
        clientName: action.payload.clientName,
        sourcesTranslations: action.payload.sourcesTranslations,
        roles: action.payload?.rolesList,
        dashboardStyle:action.payload.dashboardStyle
      };
    case CLEAN_REPORTING_PAGES:
      return {
        ...state,
        reportingPages: [],
        flattenPages: [],
        dashboardStyle:''
      };
    case GET_REPORTING_LANGUAGE_SUCCESS:
      return {
        ...state,
        languageId: action.payload.selectedLanguage,
        reportingLanguage: action.payload.languageList,
      };
      case GET_DYNAMIC_REPORTS_SUCCESS:
        return {
          ...state,
          dynamicReports: action.payload,
        };
      case REQUEST_USER_ROLES_SUCCESS: {
          const {  userRoles, userRolesPageParameters } = action.payload;
          return {
            ...state,
            userRolesList: userRoles,
            userRolesPageParameters
          };
        }
    case GET_ALL_LANGUAGE_SUCCESS:
      return {
        ...state,
        allLanguage: action.payload,
      };
    case REQUEST_APP_VERSION_SUCCESS:
      return {
        ...state,
        appVersion: action.payload,
      };
    case REQUEST_CONTACT_EMAIL_SUCCESS:
      return {
        ...state,
        contactList: action.payload,
      };
    case SHOW_MESSAGE:
      let payload = action.payload;
      return {
        ...state,
        message: payload.message,
        isOpen: payload.isOpen,
        title: payload.title,
        type: action.type,
        status: payload.status,
      };
    case CLOSE_MESSAGE:
      return {
        ...state,
        message: '',
        isOpen: false,
        title: '',
        type: '',
        status : '',
      };
    case START_LOADING:
      return { ...state, loading: true };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case START_STORE_LOADING:
      return { ...state, storeLoading: true };
    case STOP_STORE_LOADING:
      return {
        ...state,
        storeLoading: false,
      };
    case INIT_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload,
      };
    default:
      return state;
  }
};
export default Layout;
