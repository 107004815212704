/* eslint-disable  */

import { call, put, select, takeLatest } from "redux-saga/effects";
// import handleSagaErrors from 'src/common/handle-saga-errors';
import { GetAgGridData, GetLocationRankingById } from "../../../../apis/url";

import {
  GET_LOCATION_RANKING_DATA,
  GET_LOCATION_RANKING_DATA_BY_CATEGORY_ID,
  GET_FORMATED_DATA,
} from "./type";
import {
  requestLocationRankingDataSuccess,
  requestLocationRankingDataByIdSuccess,
  requestLocationRankingDataByIdFailed,
  requestLoadingAggrid,
  requestFlattenData,
} from "./action";
import { getClientId } from "helpers/authUtils";
function* handleRequestAgGridData({ payload }) {
  const { endPoint, widgetSetting, lastFilter, mergeDataHandler ,expand} =
    payload.params;
  const clientId = getClientId();

  try {
    yield put(requestLoadingAggrid(true));

    const response = yield call(
      GetAgGridData,
      widgetSetting,
      clientId,
      endPoint,
      undefined,
      undefined,
      undefined,
      undefined,
      lastFilter,
      mergeDataHandler,
      undefined,
      undefined,
      undefined,
      undefined,
      expand?expand:'',

    );

    if (response.status === 200) {
      yield put(
        requestLocationRankingDataSuccess({
          locationRanking: response.data,
          firstCall: payload.firstCall,
          hasData:true,

        })
      );
    } else if(response.status === 204){
      yield put(
        requestLocationRankingDataSuccess({
          locationRanking: {"rows":[]},
          hasData:true,
          firstCall: payload.firstCall,
        })
      );
    }
  } catch (error) {}
}
function* handleRequestLocationDataById({ body, oldData }) {
  const { endPoint, widgetSetting, mergeDataHandler, lastFilter } = body;

  const clientId = getClientId();

  try {
    yield put(requestLoadingAggrid(true));

    const response = yield call(
      GetLocationRankingById,
      widgetSetting,
      clientId,
      endPoint,
      undefined,
      undefined,
      undefined,
      undefined,
      lastFilter,
      mergeDataHandler
    );

    if (response.status === 200) {
      yield put(
        requestLocationRankingDataByIdSuccess({
          locationRankingById: mergeDataHandler.callback(
            response?.data,
            mergeDataHandler.parentCategoryId,
            oldData
          ),
          oldData: oldData,
          // pageParameters: queryParamsObject,
        })
      );
      yield put(requestLoadingAggrid(false));
    }
    else {
      yield put(requestLocationRankingDataByIdFailed())
    }
  } catch (error) {
    //console.log(error)
  }
}
function* handleGetFormattedData({ Payload }) {
  yield put(
    requestFlattenData({
      formatedData: Payload,
    })
  );
}

export default function* AgGridSaga() {
  yield takeLatest(GET_LOCATION_RANKING_DATA, handleRequestAgGridData);
  yield takeLatest(
    GET_LOCATION_RANKING_DATA_BY_CATEGORY_ID,
    handleRequestLocationDataById
  );
  yield takeLatest(GET_FORMATED_DATA, handleGetFormattedData);
}
