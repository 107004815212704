/* eslint-disable max-len */
/* eslint-disable */

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { call, put, select, takeLatest, takeEvery, all } from 'redux-saga/effects';
import HeaderPdf from '../HeaderPdf';
import FooterPdf from '../FooterPdf';
import { exportToPdf } from 'apis/url';
import { PrintPdf, StyleWidget, StyleAllQuestion, StyleLocation } from './Type';
import handleSagaErrors from 'pages/commons/handel-saga-errors';
import { getClientId } from 'helpers/authUtils';
import DashboardName from '../DashboardName';
import { TranslateToString } from 'pages/commons/Translate';
import { getLangFromCookies } from 'helpers/authUtils';
import moment from 'moment';

export const openBlobDataLink = async (response, download = false, documentName = '') => {

  //  const file = await new Blob([response.data], {type: 'application/pdf'});
  const blobURL = await window.URL.createObjectURL(response.data);
  const link = document.createElement('a');

  link.style.display = 'none';
  link.href = blobURL;
  if (download) {
    link.setAttribute('download', documentName);
  }
  document.body.appendChild(link);
  link.click();

  setTimeout(() => {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobURL);
  }, 100);
};
const lang = getLangFromCookies();

const getTitel = (name, trans) => {
  const translation =
    trans &&
    trans.find((t) => t.languageId === lang);
  const element = translation?.text || name;

  if (!element) {
    return "";
  }
  if (typeof element === "string") {
    const doc = new DOMParser().parseFromString(element, "text/html");
    const serializedString = new XMLSerializer().serializeToString(
      doc.documentElement
    );
    const isHTMLString = serializedString.startsWith("<");
    if (isHTMLString) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = serializedString;
        return tempDiv.firstChild;
    } else {
      return serializedString;
    }
  } else {
    return element;
  }
};

const getContentString = (content) => {
  var textContent = content.textContent || content.innerText;

  return textContent
};
function* handlePrint({ elementId, name, generated, exportedby, exportdate, widget = { type: 'other', name: 'dashboard-export', translations: [] } }) {
  const wType = widget.type
  const wName = widget.name
  let pdfstyle = StyleWidget
  let pageSize = 'A2'
  let PdfFilename = 'dashboard-export'

  if (wType == "AllQuestionsAnswerDistribution" || wType == 'QuestionsInfluenceAdvocacy' || wType == 'QuestionsLeaderboard') {
    pdfstyle = StyleAllQuestion
    pageSize = 'A2'
  }
  else if (wType == 'LocationRanking') {
    pdfstyle = StyleLocation
    pageSize = 'A2'
  }
  const formattedDateTime= moment().format("YYYY-MM-DDTHH:mm:ss");

  if (widget?.translations?.length > 0) {
    const content = getTitel(wName, widget.translations)
    PdfFilename = getContentString(content)+formattedDateTime
  }
  const user = yield select((s) => s.Auth.user);
  // All the needed data for the export
  const clientId = getClientId();
  // Build all the HTML elements needed
  const header = ReactDOMServer.renderToStaticMarkup(
    // eslint-disable-next-line react/jsx-filename-extension
    <HeaderPdf name={name} />
  );
  const dashboardName = ReactDOMServer.renderToStaticMarkup(
    // eslint-disable-next-line react/jsx-filename-extension
    <DashboardName name={name} />
  );
  // eslint-disable-next-line react/jsx-filename-extension
  const footer = ReactDOMServer.renderToStaticMarkup(
    // eslint-disable-next-line react/jsx-filename-extension
    <FooterPdf user={user} generated={generated} exportedby={exportedby} exportdate={exportdate} />
  );
  const IsLandscape = elementId.current.clientHeight < 800 ? true : false;
  // PDF export payload

  const formData = new FormData();
  formData.append('headerHeight', 40);
  formData.append('footerHeight', 20);
  formData.append("BackgroundColor", "FFFFFF");
  // formData.append('IsLandscape', IsLandscape);
  formData.append('header', header);
  formData.append('footer', footer);
  formData.append('head', pdfstyle);
  formData.append('pageSize', pageSize);

  formData.append(
    'body',
    `
        <div>
          ${elementId.current.innerHTML}
        </div>
      `
  );

  const response = yield call(exportToPdf, clientId, formData);
  openBlobDataLink(response, true, `${PdfFilename}.pdf`);
}
export default function* reportingSaga() {
  yield takeLatest(PrintPdf, handleSagaErrors(handlePrint));
}