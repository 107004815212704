/* eslint-disable eqeqeq */
import React, { useState, useRef } from 'react';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import moment from 'moment';
import { convertToTimestamp } from '../../utils';
import './Calendar.scss';
import { tanslatePeriodPrefix } from 'helpers/index';
import Translate from 'pages/commons/Translate';

const { Text } = Typography;
type Props = { start: number; end: number; handleChange: (from: number | null, to: number | null) => void };

const Calendar: React.FC<Props> = ({ start, end, handleChange }) => {
  const yearRef = useRef([0, 0]);
  const [selectedDate, setSelectedDate] = useState(moment());

  const handlePrevMonth = () => {
    setSelectedDate(selectedDate.clone().subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setSelectedDate(selectedDate.clone().add(1, 'month'));
  };

  const renderDaysOfWeek = () => {
    const weekdays = moment.weekdaysShort();
    return weekdays.map((day, index) => (
      <div key={index} className="calendar-week-days__item">
        <Translate id={`generic.week.days.${day.toLowerCase()}`} defaultMessage={day} />
      </div>
    ));
  };

  const onHandleChange = (value: any) => {
    const startValue = convertToTimestamp(moment(value).startOf('day'));
    const endValue = convertToTimestamp(moment(value).startOf('day'));
    if (yearRef.current?.[0] == 0 && yearRef.current?.[1] == 0) {
      handleChange(startValue, endValue);
      yearRef.current = [startValue, endValue];
    } else if (yearRef.current?.[0] == startValue || yearRef.current?.[1] == endValue) {
      handleChange(startValue, endValue);
      yearRef.current = [startValue, endValue];
    } else if (startValue > yearRef.current?.[0] && endValue < yearRef.current?.[1]) {
      handleChange(startValue, endValue);
      yearRef.current = [startValue, endValue];
    } else if (startValue == yearRef.current?.[0] && endValue == yearRef.current?.[1]) {
      handleChange(startValue, endValue);
      yearRef.current = [startValue, endValue];
    } else {
      const minPeriod = Math.min(startValue, start);
      const maxPeriod = Math.max(endValue, end);
      handleChange(minPeriod, maxPeriod);
      yearRef.current = [minPeriod, maxPeriod];
    }
  };

  const getButtonClassName = (value: any) => {
    const startTimestamp = convertToTimestamp(moment(value).startOf('day'));
    const endTimestamp = convertToTimestamp(moment(value).endOf('day'));
    if (startTimestamp == start && endTimestamp == end) {
      return 'calendar-day single';
    }
    if (startTimestamp == start && endTimestamp < end) {
      return 'calendar-day rangeStart';
    }
    if (startTimestamp > start && endTimestamp == end) {
      return 'calendar-day rangeEnd';
    }
    if (startTimestamp > start && endTimestamp < end) {
      return 'calendar-day selected-range';
    }
    return 'calendar-day';
  };

  const renderDay = (value: any) => {
    return (
      <div onClick={() => onHandleChange(value)} key={value.format('YYYY-MM-DD')} className={getButtonClassName(value)}>
        {value.format('D')}
      </div>
    );
  };
  const renderCalendarDays = () => {
    const startOfMonth = selectedDate.clone().startOf('month');
    const endOfMonth = selectedDate.clone().endOf('month');
    const startDate1 = startOfMonth.clone().startOf('week');
    const endDate1 = endOfMonth.clone().endOf('week');
    const calendarDays = [];
    let currentDay = startDate1;
    while (currentDay.isSameOrBefore(endDate1)) {
      calendarDays.push(currentDay.clone());
      currentDay.add(1, 'day');
    }

    return calendarDays.map((day) => renderDay(day));
  };

  return (
    <Space direction="vertical" size={20}>
      <Space size={28} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CaretLeftOutlined style={{ fontSize: 20, color: '#BB6125' }} onClick={handlePrevMonth} />
        <Text className={'cxg-text'} style={{ fontSize: 14, fontWeight: 'bold', color: '#003C4C', textAlign: 'center' }}>
          {tanslatePeriodPrefix(moment(selectedDate).format('MMMM, YYYY'))}
        </Text>
        <CaretRightOutlined
          style={{
            fontSize: 20,
            color: moment(selectedDate).format('MM YYYY') < moment().format('MM YYYY') ? '#BB6125' : 'gray',
          }}
          onClick={handleNextMonth}
        />
      </Space>
      <div className="calendar-daily">
        <div className="calendar-body">
          <div className="calendar-week-days">{renderDaysOfWeek()}</div>
          <div className="calendar-days">{renderCalendarDays()}</div>
        </div>
      </div>
    </Space>
  );
};

export default Calendar;
